import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import Tabs from "../containers/Tabs";
import "./action-tab.css";
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";

function App({ currentState, setState }) {
   
    return (
        <div className="card" style={{margin:"0rem 0rem 1rem 0rem"}}>
            <div className="px-3">
                <div className="link-text">
                    <Nav className="d-flex justify-content-between align-items-center">
                        <NavItem
                            onClick={() => {
                                setState('view-employee')
                            }}

                            className={
                                currentState === 'view-employee'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }
                        >
                            Employee Detail
                        </NavItem>
                        <NavItem onClick={() => {
                            setState('salary-detail')
                        }}
                            className={
                                currentState === 'salary-detail'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>

                            Salary Detail
                        </NavItem>
                        <NavItem onClick={() => {
                            setState('allowances')
                        }}
                            className={
                                currentState === 'allowances'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>

                            Allowances
                        </NavItem>
                        <NavItem onClick={() => {
                            setState('deductions')
                        }}
                            className={
                                currentState === 'deductions'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>

                            Deductions
                        </NavItem>

                        <NavItem onClick={() => {
                            setState('payment-methods')
                        }}
                            className={
                                currentState === 'payment-methods'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>

                            Payment Methods
                        </NavItem>
                        <NavItem onClick={() => {
                            setState('payroll-details')
                        }}
                            className={
                                currentState === 'payroll-details'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>

                            Payroll Details
                        </NavItem>

                        <NavItem onClick={() => {
                            setState('employee-offboarding')
                        }}
                            className={
                                currentState === 'employee-offboarding'
                                    ? "tab-list-active"
                                    : "text-dark"
                            }>


                            Employee OffBoarding
                        </NavItem>

                    </Nav>
                </div>
            </div>
        </div>
    );
}

export default App;
