import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import { useHttp } from "../../../../hooks/useHttps";
import axios from "axios";
import config from "../../../../config";
import { notification, Spin, Select } from "antd";
import Tab from "../../workhub/tabs";
import { ThreeDots } from "react-loader-spinner";
export default function EmployeeInfo(props) {
  const [employeeInfo, setInfo] = useState({});
  const [pfa, setPfa] = useState("")
  const [rsaPin, setRsaPin] = useState("")
  const [loadUpdate, setLoadUpdate] = useState(false)
  const [taxState, setTaxState] = useState("")
  const [tinNumber, setTinNumber] = useState("")
  const [taxData, setTaxData] = useState([])
  const [pfaData, setPfaData] = useState([])
  const [loadEmployee, setLoadEmployee] = useState(false);
  const updateDetails = () => {
    setLoadUpdate(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/remittance-detail/${sessionStorage.getItem(
          "employeeID"
        )}`, {
        "pfaCode": getPfaCode(pfa),
        "pfaName": getpfaName(pfa),
        "pfaRsaPin": rsaPin,
        "taxState": taxState,
        "tin": tinNumber
      },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdate(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message)
          getEmployeeInfo()
        } else {
          Notification("error", "Error", res.data.message)
        }
      })
      .catch((err) => {
        setLoadUpdate(false);
        if (err) {
        }
      });
  };

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`)
      .then((res) => {
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/PFA/${country}`)
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;

          setPfaData(
            arr?.map((d) => ({
              value: d?.code,
              label: d?.value,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };



  const getpfaName = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.label) {
      return pfa?.label;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.label;
    }
  };

  const getPfaCode = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.value) {
      return pfa?.value;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.value;
    }
  };

  const getEmployeeInfo = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const Info = res.data.result;
          getPfa(Info?.country)
          getTaxStates(Info?.country)
          setInfo(Info);
          setRsaPin(Info?.pfaRsaPin)
          setPfa(Info.pfaName)
          setTaxState(Info?.taxState)
          setTinNumber(Info.tin)
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };



  useEffect(() => {
    getEmployeeInfo();
  }, []);

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <Layout>
      <Tab />
      {loadEmployee && (
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div className="text-center py-5">
              <Spin />
            </div>
          </div>
        </div>
      )}
      {!loadEmployee && (
        <div>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="profile-img text-center pt-3">
                <img alt="" width="150" style={{ borderRadius: "100%" }} />
              </div>
              <div class="card-header font-weight-bold">
                <b>Personal Informations</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo.firstName ? employeeInfo.firstName : ""
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo.lastName ? employeeInfo.lastName : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Other Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                          value={employeeInfo ? employeeInfo.otherName : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Marital Status
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.maritalStatus : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Gender
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.gender : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Contact Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={
                            employeeInfo ? employeeInfo.contactAddress : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo
                              ? employeeInfo?.dateOfBirth?.slice(0, 10)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Mobile
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.mobile : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Country
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.country : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.nextOfKin : ""}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin Phone Number
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo ? employeeInfo.nextOfKinPhone : ""
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo ? employeeInfo.nextOfKinAddress : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header pt-5 mt-3 font-weight-bold">
                <b>Work Informations</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.employeeCode : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Work Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.workEmail : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Department
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.department : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Department
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.position : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Level
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.level : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Type
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.employeeType : ""}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          PFA
                        </label>
                        <Select
                          showSearch
                          value={pfa}
                          style={{
                            width: "100%",
                            borderRadius: "10px !important",
                          }}
                          placeholder="Search to Select"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare((optionB?.label ?? "").toLowerCase())
                          }
                          onChange={(val) => {
                            if (val !== "Select") {
                              setPfa(val);

                            } else {
                              setPfa("");

                            }
                          }}
                          options={pfaData}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          RSA PIN
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={(e) => setRsaPin(e.target.value)}
                          value={rsaPin}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tax State
                        </label>

                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={taxState}
                          name="taxState"
                          onChange={(e) => setTaxState(e.target.value)}

                        >
                          <option selected value={"Select"}>
                            Select
                          </option>
                          {taxData.map((taxState) => {
                            return <option>{taxState.value}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          TAX IDENTIFICATION NUMBER
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setTinNumber(e.target.value)}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={tinNumber}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Hire Date
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        disabled
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          employeeInfo
                            ? employeeInfo?.hireDate?.slice(0, 10)
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <button
                      type="submit"
                      class="btn btn-primary mt-4"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={updateDetails}
                    >
                      {loadUpdate ? <div> <ThreeDots
                        visible={loadUpdate}
                        height="20"
                        width="40"
                        color="#fff"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      /></div> : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
