import React, { useState } from "react";
import PersonalInfo from "./personal-info";
import PaymentInfo from "./payment-info";
import AccountInfro from "./account-info";
import logo from "../../../img/salariopay-logo.png";
import { Tabs, Modal, Alert } from "antd";
import "./index.css";
import config from "../../../config";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Apple from "../../../assets/apple.svg"
import Android from "../../../assets/android.svg"
export default function AcceptOnboarding(props) {
  const [tabs, setTabs] = useState("1");
  const [bankLists, setBankLists] = useState([]);
  const [pfaData, setPfaData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [countryData, setCountryDatar] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [workEmail, setWorkEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [msgResponse, setmMsgResponse] = useState("");
  const [inviteRef, setInviteRef] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [info, setInfo] = useState({});
  const history = useHistory();
  const [preloadData, setPreloadDataPersonal] = useState({});
  const [preloadDataPayment, setPreloadDataPayment] = useState({});
  const items = [
    {
      key: "1",
      label: "Personal Information",
      children: "",
    },
    {
      key: "2",
      label: "Payment Information",
      children: "",
    },
    {
      key: "3",
      label: "Account Information",
      children: "",
    },
  ];

  const items2 = [
    {
      key: "1",
      label: "Personal Information",
      children: "",
    },

    {
      key: "3",
      label: "Account Information",
      children: "",
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const inviteRef = query.get("ref");
    setInviteRef(inviteRef);
    getOnboardingInfo(inviteRef);
  }, []);

  const gotBackDefault = () => {
    const data = sessionStorage?.getItem("personalInfo");
    const actualData = JSON?.parse(data);
    console.log(actualData);
    setTabs("1");
    setPreloadDataPersonal(actualData);
  };

  const gotBackDefaultPayment = () => {
    const dataPayment = sessionStorage?.getItem("paymentInfo");
    const actualDataPayment = JSON?.parse(dataPayment);
    setTabs("1");
    setPreloadDataPayment(actualDataPayment);
  };

  const getOnboardingInfo = (id) => {
    axios
      .get(`${config.baseUrl}/v1/onboarding-link/invite/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setInfo(res.data.result);
          setWorkEmail(res.data?.result?.workEmail);
          setPreloadDataPersonal({});
          setPreloadDataPayment({});
        } else {
          setmMsgResponse(res.data.message);
          setWorkEmail("");
          setShowModal(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  return (
    <div className=" pt-5">
      <div className="">
        <div className="f-padding-t d-flex justify-content-center align-items-center">
          <div class="card onboard-width">
            <div class="card-body">
              <div className="text-center pb-3">
                <img src={logo} width={200} />
              </div>
              <h5 class="card-title font-weight-bold">Get Started </h5>
              {info?.reviewStatus === "DECLINED" && (
                <div className="text-center font-weight-bold text-danger">
                  Decline Reason: {info?.reviewComment}M
                </div>
              )}
              <div className="show-web-onboard">
                <Tabs defaultActiveKey={tabs} items={info.saveFee ? items2 : items} activeKey={tabs} />{" "}
              </div>
              <div className="row">
                <div className="col-md-12">
                  {tabs === "1" && (
                    <PersonalInfo
                      setTabs={setTabs}
                      setBankLists={setBankLists}
                      setPfaData={setPfaData}
                      setTaxData={setTaxData}
                      setCountryDatar={setCountryDatar}
                      setFullData={setFullData}
                      preloadData={preloadData}
                      info={info}
                    />
                  )}
                  {tabs === "2" && !info?.saveFee && (
                    <PaymentInfo
                      setTabs={setTabs}
                      bankLists={bankLists}
                      pfaData={pfaData}
                      taxData={taxData}
                      countryData={countryData}
                      gotBackDefault={gotBackDefault}
                      preloadData={preloadDataPayment}
                      info={info}
                    />
                  )}
                  {tabs === "3" && (
                    <AccountInfro
                      setTabs={setTabs}
                      inviteRef={inviteRef}
                      pfaData={pfaData}
                      bankLists={bankLists}
                      gotBackDefaultPayment={gotBackDefaultPayment}
                      workEmail={workEmail}
                    />
                  )}
                </div>
              </div>
              <br />
              <div
                className="authent-reg"
                onClick={() => {
                  history?.push("/login");
                }}
              >
                <p className="text-center" style={{ cursor: "pointer" }}>
                  Already have an account?{" "}
                  <span className="" style={{ color: "#1fc157" }}>
                    Login
                  </span>
                </p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      <Modal title={""} visible={showModal} footer={false} maskClosable={false}>
        <Alert message={msgResponse} type="error" />
        <hr />
        <div className="d-flex justify-content-between align-items-center ">
          <a
            href="https://play.google.com/store/apps/details?id=com.mishtranzacts.salariopay&hl=en"
            target="_blank"
          >
            <div>
              <img src={Android} />
            </div>
          </a>

          <a
            href="https://apps.apple.com/us/app/salariopay-personal/id6738658996"
            target="_blank"
          >
            <div>
              <img src={Apple} />
            </div>
          </a>

        </div>
      </Modal>
    </div>
  );
}
