import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import { useHttp } from "../../../../hooks/useHttps";
import axios from "axios";
import config from "../../../../config";
import { useForm, Controller } from "react-hook-form";
import Location from "../../../../containers/Location/addressViewContact";
import dayjs from "dayjs";
import { ThreeDots } from "react-loader-spinner";
import Location2 from "../../../../containers/Location/addressViewEmployeeNextofKin";
import { notification, Spin, DatePicker } from "antd";
import moment from "moment";
const dateFormat = "YYYY/MM/DD";
export default function EmployeeInfo({generate}) {
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [dob, setDob] = useState("");
  const [firstname, setFirstName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [lastname, setLastName] = useState("");
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [pfa, setPfa] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");
  const [pfaData, setPfaData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [employeeType, setEmployeeType] = useState("");
  const [position, setPosition] = useState("");
  const [pfaRsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [nextOfKin, setNextOfkin] = useState("");
  const [nextOfKinPhone, setNextOfkinPhone] = useState("");
  const [level, setLevel] = useState("");
  const [nextOfKinAddress, setNextOfkinAddress] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const [loadDepartments, departmentDatas] = useHttp(
    `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const onChangeHireDate = (date, dateString) => {
    setHireDate(date);
  };

  const onChangeDob = (date, dateString) => {
    setDob(date);
  };

  const [loadCountries, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  const [loadPositions, positionDatas] = useHttp(
    `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const [loadEmployee, levelDatas] = useHttp(
    `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );
  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
      getPfa(e.target.value);
      getTaxStates(e.target.value);
    }
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/pfa/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPfaData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );

  useEffect(() => {
    getContactDetails();
  }, [generate]);

  const getContactDetails = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setInfo(contactDetails);
          setFirstName(contactDetails.firstName);
          setLastName(contactDetails.lastName);
          setOthername(contactDetails.otherName);
          setMaritalStatus(contactDetails.maritalStatus);
          setDob(contactDetails?.dateOfBirth?.slice(0, 10));
          setGender(contactDetails.gender);
          setCountry(contactDetails.country);
          setContactAddress(contactDetails.contactAddress);
          setMobile(contactDetails.mobile);
          setPersonalEmail(contactDetails?.personalEmail);
          setPfa(contactDetails.pfaCode);
          setPosition(contactDetails.position);
          setWorkEmail(contactDetails.workEmail);
          setHireDate(contactDetails.hireDate?.slice(0, 10));
          setDepartment(contactDetails.department);
          setEmployeeType(contactDetails.employeeType);
          setEmployeeCode(contactDetails.employeeCode);
          setRsaPin(contactDetails.pfaRsaPin);
          setTaxId(contactDetails.tin);
          setLevel(contactDetails.level);
          setNextOfkin(contactDetails.nextOfKin);
          setNextOfkinAddress(contactDetails.nextOfKinAddress);
          setNextOfkinPhone(contactDetails.nextOfKinPhone);
          setTaxState(contactDetails.taxState);
          getTaxStates(contactDetails.country);
          getPfa(contactDetails.country);
          setInvitationStatus(
            contactDetails?.inviteAccepted === false
              ? "INVITE_NOT_ACCEPTED"
              : "INVTIE_ACCEPTED"
          );
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNextOfkinPhone(truncatedValue);
          break;
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;

        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const getpfaName = (id) => {
    var pfa = pfaData?.find((x) => x.pfaCode === id);
    if (pfa?.pfaName) {
      return pfa?.pfaName;
    } else {
      var pfaChk = pfaData?.find((x) => x.pfaName === id);
      return pfaChk?.pfaName;
    }
  };

  const getPfaCode = (id) => {
    var pfa = pfaData?.find((x) => x.pfaCode === id);
    if (pfa?.pfaCode) {
      return pfa?.pfaCode;
    } else {
      var pfaChk = pfaData?.find((x) => x.pfaName === id);
      return pfaChk?.pfaCode;
    }
  };

  const convertISO = (originalDateString) => {
    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);
    // Set the time components to the desired values
    originalDate.setHours(16);
    originalDate.setMinutes(33);
    originalDate.setSeconds(49);
    originalDate.setMilliseconds(752);
    // Convert the Date object to ISO 8601 format with timezone offset
    const isoDateString = originalDate?.toISOString();
    return isoDateString;
  };

  const handleEmployeeUpdate = (type, e) => {
    e.preventDefault();
    console.log(hireDate)
    const workInfo = {
      pfaRsaPin: pfaRsaPin,
      tin: taxId,
      level,
      employeeType,
      hireDate: convertISO(hireDate),
      level,
      taxState: taxState,
      position: position,
      department: department,
      pfaCode: getPfaCode(pfa),
      pfaName: getpfaName(pfa),
      employerId: info.employerId,
    };
    const personalInfo = {
      contactAddress: contactAddress,
      country: country,
      employerId: info.employerId,
      firstName: firstname,
      gender: gender,
      lastName: lastname,
      maritalStatus: maritalStatus,
      mobile: mobile,
      personalEmail,
      nextOfKin: nextOfKin,
      dateOfBirth:convertISO(dob),
      nextOfKinAddress: nextOfKinAddress,
      nextOfKinPhone: nextOfKinPhone,
      otherName: othername,
    };
    if (type === "Work") {
      setLoad2(true);
    } else {
      setLoad1(true);
    }
    axios
      .put(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,

        type === "Work" ? workInfo : personalInfo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad1(false);
        setLoad2(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          setTimeout(() => {
            // window.location.reload(false)
          }, 2500);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad1(false);
        setLoad2(false);
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
  
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {loadEmployeeInfo && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {!loadEmployeeInfo && (
              <div>
                <div>
                  <div>
                    <div class="card-header ">
                      <div className="d-flex justify-content-between align-items-center">
                        Personal Informations
                        <span
                          className={
                            {
                              INVITE_NOT_ACCEPTED: "badge bg-danger",
                              INVTIE_ACCEPTED: "badge bg-success",
                            }[invitationStatus]
                          }
                        >
                          {invitationStatus
                            ? invitationStatus?.replace(/_+/g, " ")
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstname}
                                maxLength={30}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastname}
                                maxLength={30}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Other Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => setOthername(e.target.value)}
                                value={othername}
                                maxLength={30}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Personal Email
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) =>
                                  setPersonalEmail(e.target.value)
                                }
                                value={personalEmail}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Marital Status
                              </label>
                              <select
                                onChange={(e) =>
                                  setMaritalStatus(e.target.value)
                                }
                                class="form-select"
                                aria-label="Default select example"
                                value={maritalStatus}
                              >
                                <option selected>Select</option>
                                {maritalData.map((marital) => {
                                  return <option>{marital.value}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Gender
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                              >
                                <option selected>Select</option>
                                {genderData.map((gender) => {
                                  return <option>{gender.value}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label for="" class="">
                                  Contact Address{" "}
                                </label>
                                <div
                                  className="mb-2"
                                  style={{
                                    color: "#1fc157",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setMnaualAddress(!mmanualAddress);
                                    setContactAddress("");
                                  }}
                                >
                                  {mmanualAddress ? (
                                    <div
                                      style={{
                                        background: "#1FC157",
                                        color: "white",
                                        borderRadius: "7px",
                                        padding: "2px 8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Enter Address Automatically
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        background: "#1FC157",
                                        color: "white",
                                        borderRadius: "7px",
                                        padding: "2px 8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Enter Address Manually
                                    </div>
                                  )}
                                </div>
                              </div>
                              {mmanualAddress ? (
                                <div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    onChange={(e) =>
                                      setContactAddress(e.target.value)
                                    }
                                    value={contactAddress}
                                    maxLength={64}
                                  />
                                </div>
                              ) : (
                                <Location
                                  setPersonalAddress={setContactAddress}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  setCity={setCity}
                                  setState={setState}
                                  setLocationInfo={setLocationInfo}
                                  watch={watch}
                                  registerVal={"contactAddress"}
                                  setValue={setValue}
                                  reset={reset}
                                />
                              )}
                              {info?.contactAddress && (
                                <div
                                  className="font-weight-bold pt-1 b"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "13px",
                                  }}
                                >
                                  Current Address : {info?.contactAddress}
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Date of Birth
                              </label>

                              <Controller
                                name="dob"
                                control={control}
                                {...register("dob", {
                                  required: false,
                                })}
                                render={({ field }) => (
                                  <DatePicker
                                    onChange={onChangeDob}
                                    value={dayjs(dob, dateFormat)}
                                    style={{
                                      width: "100%",
                                      height: "3.3rem",
                                      borderRadius: "10px",
                                    }}
                                    placeholder="Start Date"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Mobile
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleChangeNumber.bind(
                                  this,
                                  "mobile",
                                  15
                                )}
                                value={mobile}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Country
                              </label>

                              <select
                                onChange={countryChange}
                                class="form-select"
                                aria-label="Default select example"
                                value={country}
                              >
                                <option selected>Select</option>
                                {countryDatas.map((element) => {
                                  return (
                                    <option value={element.code}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Next of Kin Name
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={(e) => setNextOfkin(e.target.value)}
                                value={nextOfKin}
                                maxLength={30}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Next of Kin Phone Number
                              </label>
                              <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleChangeNumber.bind(
                                  this,
                                  "nokPhone",
                                  15
                                )}
                                value={nextOfKinPhone}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label for="" class="">
                                  Next of Kin Address{" "}
                                </label>
                                <div
                                  className="mb-2"
                                  style={{
                                    color: "#1fc157",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setMnaualAddressNok(!mmanualAddressNok);
                                    setNextOfkinAddress("");
                                  }}
                                >
                                  {mmanualAddressNok ? (
                                    <div
                                      style={{
                                        background: "#1FC157",
                                        color: "white",
                                        borderRadius: "7px",
                                        padding: "2px 8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Enter Address Automatically
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        background: "#1FC157",
                                        color: "white",
                                        borderRadius: "7px",
                                        padding: "2px 8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Enter Address Manually
                                    </div>
                                  )}
                                </div>
                              </div>

                              {mmanualAddressNok ? (
                                <div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    onChange={(e) =>
                                      setNextOfkinAddress(e.target.value)
                                    }
                                    value={nextOfKinAddress}
                                    maxLength={64}
                                  />
                                </div>
                              ) : (
                                <Location2
                                  setPersonalAddress={setNextOfkinAddress}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  watch={watch}
                                  setCity={setCity}
                                  setState={setState}
                                  setLocationInfo={setLocationInfo}
                                  registerVal={"nokAddress"}
                                  setValue={setValue}
                                  reset={reset}
                                />
                              )}
                              {info?.nextOfKinAddress && (
                                <div
                                  className="font-weight-bold pt-1 b"
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "13px",
                                  }}
                                >
                                  Current Address : {info?.nextOfKinAddress}
                                </div>
                              )}
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3"></div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                            onClick={handleEmployeeUpdate.bind(
                              this,
                              "Personal"
                            )}
                          >
                            {load1 ? (
                              <ThreeDots
                                visible={load1}
                                height="20"
                                width="50"
                                color="#ffffff"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12">
                  <div class="card">
                    <div class="card-header pt-5 mt-3 font-weight-bold">
                      Work Informations
                    </div>
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Code
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                onChange={(e) =>
                                  setEmployeeCode(e.target.value)
                                }
                                aria-describedby="emailHelp"
                                disabled
                                maxLength={32}
                                value={employeeCode}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Work Email
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                disabled
                                value={workEmail}
                                onChange={(e) => setWorkEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Department
                              </label>
                              <select
                                onChange={(e) => setDepartment(e.target.value)}
                                class="form-select"
                                aria-label="Default select example"
                                value={department}
                              >
                                <option selected>Select</option>
                                {departmentDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Position
                              </label>
                              <select
                                onChange={(e) => setPosition(e.target.value)}
                                class="form-select"
                                aria-label="Default select example"
                                value={position}
                              >
                                <option selected>Select</option>
                                {positionDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {" "}
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Level
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                onChange={(e) => setLevel(e.target.value)}
                                value={level}
                              >
                                <option selected>Select</option>
                                {levelDatas.map((level) => {
                                  return (
                                    <option value={level.level}>
                                      {level.level}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Type
                              </label>
                              <select
                                onChange={(e) =>
                                  setEmployeeType(e.target.value)
                                }
                                class="form-select"
                                aria-label="Default select example"
                                value={employeeType}
                              >
                                <option selected>Select</option>
                                {employeeTypeData.map((type) => {
                                  return (
                                    <option value={type.value}>
                                      {type.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                PFA
                              </label>

                              <select
                                onChange={(e) => setPfa(e.target.value)}
                                class="form-select"
                                aria-label="Default select example"
                                value={pfa}
                              >
                                <option selected>Select</option>
                                {pfaData.map((element) => {
                                  return (
                                    <option value={element.pfaCode}>
                                      {" "}
                                      {element.pfaName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                RSA PIN
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleChangeNumber.bind(
                                  this,
                                  "pfaPin",
                                  15
                                )}
                                value={pfaRsaPin}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Tax State
                              </label>
                              <select
                                onChange={(e) => setTaxState(e.target.value)}
                                class="form-select"
                                aria-label="Default select example"
                                value={taxState}
                              >
                                <option selected>Select</option>
                                {taxData.map((taxState) => {
                                  return <option>{taxState.value}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Tax Identification Number
                              </label>
                              <input
                                onChange={handleChangeNumber.bind(
                                  this,
                                  "taxId",
                                  10
                                )}
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={taxId}
                              />
                            </div>
                          </div>

                          <div class="mb-3">
                            <div class="col-sm-12 col-md-6">
                              <div className="mb-3">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Hire Date
                                </label>

                                <Controller
                                  name="hiredate"
                                  control={control}
                                  {...register("hiredate", {
                                    required: false,
                                  })}
                                  render={({ field }) => (
                                    <DatePicker
                                      onChange={onChangeHireDate}
                                      value={dayjs(hireDate, dateFormat)}
                                      style={{
                                        width: "100%",
                                        height: "3.3rem",
                                        borderRadius: "10px",
                                      }}
                                      placeholder=""
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                            onClick={handleEmployeeUpdate.bind(this, "Work")}
                          >
                            {load2 ? (
                              <ThreeDots
                                visible={load2}
                                height="20"
                                width="50"
                                color="#ffffff"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            ) : (
                              "Update"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
  
  );
}
