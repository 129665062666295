import React, { useState } from "react";

const CustomDropdownInput = ({ options, data,onChange, placeholder = "Enter value..." }) => {

  const [dropdownValue, setDropdownValue] = useState(data?.type ?  data.type : "");
  const [inputValue, setInputValue] = useState(data?.value ?  data?.value : "");

  const handleDropdownChange = (event) => {
    const newDropdownValue = event.target.value;
    setDropdownValue(newDropdownValue);
    onChange(newDropdownValue, inputValue);
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    if(dropdownValue === "PERCENTAGE"){
      if (newInputValue === "" || (Number(newInputValue) <= 100 && Number(newInputValue) >= 0)) {
        setInputValue(newInputValue);
      }
    }
    else{
      setInputValue(newInputValue);
    }

    onChange(dropdownValue, newInputValue);
  };

  

  return (
    <div className="input-group">
      <select
        className="form-select"
        value={dropdownValue}
        onChange={handleDropdownChange}
      >
          <option>
           Select
         </option>
        {options.map((option) => (
         
          <option key={option.id} value={option.code}>
            {option.code?.replace("_"," ")}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="form-control"
        value={inputValue}
        onChange={handleInputChange}
        
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomDropdownInput;
