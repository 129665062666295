import React, { useRef, useState } from "react";
import Webcam from "react-webcam";

const SelfieCapture = ({
  onCapture,
  setOpenSelfieCapture,
  convertToBlob,
  uploadSelfie,
}) => {
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  const captureSelfie = (e) => {
    e.preventDefault();
    if (webcamRef.current) {
      const image = webcamRef.current.getScreenshot();
      console.log('this is the selfie')
      console.log(image)
      if (image) {
        setImageSrc(image);
        onCapture(image); // Pass the captured image to the parent component
      }
    }
  };

  return (
    <div>
      {!imageSrc ? (
        <div>
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={"100%"}
            />
          </div>

          <button
            onClick={captureSelfie}
            type="submit"
            className="btn btn-info m-b-xs mt-2"
            style={{ background: "#1FC157", color: "white" }}
          >
            Capture Selfie
          </button>
        </div>
      ) : (
        <div>
          <img src={imageSrc} alt="Captured Selfie" />
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{
                border: "1px dotted #1FC157",
                background: "white",
                color: "1FC157",
                height: "38px",
                borderRadius: "7px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setImageSrc(null);
              }}
            >
              Retake
            </button>
            {imageSrc && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenSelfieCapture(false);
                  uploadSelfie()
                }}
                type="submit"
                className="btn btn-info m-b-xs mt-2"
                style={{ background: "#1FC157", color: "white" }}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelfieCapture;
