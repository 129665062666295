import React, { useState, useEffect } from "react";
import Tabs from "./tab";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import CrudOptimizer from "../../../containers/UtilityCrudEmployer/Index";
import { NumberFormatter } from "../../../formatter/index";
import { useHistory } from "react-router-dom";
import CustomDropdownInput from "../employee_onboarding/employee_actions/custom-input-group";
import { notification, DatePicker, Modal, Spin, Checkbox, Select } from "antd";
import dayjs from "dayjs";
const dateFormat = "YYYY/MM/DD";
const role = sessionStorage.getItem("activeRole");
export default function EmployeeInfo(props) {
  const [loading, currencyDatas] = useHttp(
    `${config.baseUrl}/v1/wallets/employer/currencies/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );
  var paymentinfodata = sessionStorage.getItem("emp_personalInfo");
  var personalInfo = JSON?.parse(paymentinfodata);

  const [checkWorkOptions, setCheckWorkOptions] = useState(["Pension", "Tax"]);
  const [loadUpdate13thMonth, setLoadUpdate13thMonth] = useState(false);
  const [setUpCheck, setSetupCheck] = useState(false)
  const [checkWorkOptionsVal, setCheckWorkOptionsVal] = useState([]);
  const [selectedCheckGroup,setSelectedCheckGroup] = useState(false)
  const [monthlyNetSalaryVal, setMonthltyNetSalaryValue] = useState("");
  const [countryUser, setCountryUser] = useState("");

  const onChangeCheckbox = (checkedValues) => {
    setCheckWorkOptions(checkedValues);
    setSelectedCheckGroup(true)
  };

  const checkOptions = [
    {
      label: "Pension",
      value: "Pension",
    },

    {
      label: "Tax",
      value: "Tax",
    },
  ];

  const history = useHistory();
  const onboardingId = sessionStorage?.getItem("onboardID");
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [annualGrossery, setAnnualGrossery] = useState("");
  const [load1, setLoad1] = useState(false);
  const [paygroupData, setPaygroupData] = useState([]);
  const [loadCrudDatas, setCrudDatas] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [currency, setCurrency] = useState("");
  const [loadAcc, setLoadAcc] = useState(false);
  const [loadDec, setLoadDec] = useState(false);
  const [visible, setVisible] = useState(false);
  const [reimbursementTylesList, setAddReimbursement] = useState([{ id: 1, allowanceType: "", type: "", value: "" }])
  const [addTypeName, setAddTypeName] = useState("");
  const [lastname, setLastName] = useState("");
  const [employerInfo, setEmployerInfo] = useState({});
  const [reason, setReason] = useState("");
  const [positionDatas, setPositionData] = useState([]);
  const [departmentDatas, setDepartmentData] = useState([]);
  const [levelDatas, setEmployeeLevelData] = useState([]);
  const [bankLists, setBankLists] = useState([]);
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [paygroup, setPayGroup] = useState([]);
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [loadUpdateReimbursement, setLoadUpdateReimbursement] = useState(false);
  const [contactAddress, setContactAddress] = useState("");
  const [departmentCreate, setDepartmentCreate] = useState(false);
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [pfa, setPfa] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");
  const [pfaData, setPfaData] = useState([]);
  const [monthsWorked, setMonthsWorked] = useState("")
  const [percentage, setPercentage] = useState("")
  const [policy, setPolicy] = useState("")
  const [taxData, setTaxData] = useState([]);
  const [decModal, setOpenDecModal] = useState(false);
  const [employeeType, setEmployeeType] = useState("");
  const [position, setPosition] = useState("");
  const [payScale, setPayScale] = useState("");
  const [pfaRsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [nextOfKin, setNextOfkin] = useState("");
  const [nextOfKinPhone, setNextOfkinPhone] = useState("");
  const [level, setLevel] = useState("");
  const [nextOfKinAddress, setNextOfkinAddress] = useState("");
  const [payLevelDatas, setPayLevelData] = useState([]);
  const [payLevel, setPayLevel] = useState("");
  const [payScaleData, setPayScalesData] = useState([]);
  const query = new URLSearchParams(props.location.search);
  let inviteRef;

  const onChangeHireDate = (date, dateString) => {
    setHireDate(date);
    setValue("hireDate", date);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getPaygroup();
  }, [])

  const [loadingPolicies, thirteenMonthPoliciesData] = useHttp(
    `${config.baseUrl}/v1/options/13TH_MONTH_POLICY`,
    []
  );

  const [loadAllowances, allowancesTypes] = useHttp(
    `${config.baseUrl}/v1/options/ALLOWANCE_TYPE`,
    []
  );


  const handleThirteenMonth = (e) => {
    e.preventDefault();
    if (!monthsWorked && !percentage && !policy) {
      Notification("error", "Error", "Months Worked, Percentage, and Policy are required")
      return;
    }
    const data = {
      "thirteenMonth": {
        "monthsWorked": monthsWorked,
        "percentage": percentage,
        "policy": policy
      }
    };

    setLoadUpdate13thMonth(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-13th-month/${sessionStorage.getItem(
          "employeeID"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdate13thMonth(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdate13thMonth(false);
        if (err) {
        }
      });
  };



  const errorNote = () => {
    Notification("error", "Error", "Permission not allowed");
  };

  const handleInputChangeReimbursement = (id, field, value) => {
    setAddReimbursement((prevState) =>
      prevState.map((list) =>
        list.id === id ? { ...list, allowanceType: value } : list
      )
    );
  }




  const [loadingValues, reimbursementValues] = useHttp(
    `${config.baseUrl}/v1/options/VALUE_TYPE`,
    []
  );

  useEffect(() => {
    inviteRef = query.get("ref");
    if (inviteRef) {
      sessionStorage?.setItem("onboardID", inviteRef);
    }
  }, []);

  useEffect(() => {
    if (loadCrudDatas) {
    }
  }, [loadCrudDatas]);

  const getPosition = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );

  const getLevelsData = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setEmployeeLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getDepartment = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getDepartment();
    getPayLevel();
    getPosition();
    getLevelsData();
  }, []);

  const loadAllCrus = () => {
    getDepartment();
    getPosition();
    getLevelsData();
  };

  useEffect(() => {
    getContactDetails();
    getContactDetailsEmployer();
  }, []);

  const getContactDetailsEmployer = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/onboarding-link/invite/${onboardingId ? onboardingId : inviteRef
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setInfo(contactDetails);
          getBanksByCountry(contactDetails?.country);
          getPfa(contactDetails?.country);
          setEmployeeCode(contactDetails?.employeeCode);
          setPosition(contactDetails?.position);
          setLevel(contactDetails?.level);
          setDepartment(contactDetails?.department);
          setPayGroup(contactDetails?.payGroup ? contactDetails?.payGroup : [])
          setCountryUser(contactDetails?.country);
          setCurrency(contactDetails?.salaryCurrency);
          setPayScale(contactDetails?.payScale)
          setPayLevel(contactDetails?.payLevel)
          setEmployeeType(contactDetails?.employeeType);
          setHireDate(contactDetails?.hireDate);
          setValue("annualGrossery", contactDetails?.annualGrossSalary);
          if (contactDetails?.computePension) {
            checkWorkOptionsVal.push("Pension");
          }
          if (contactDetails?.computeTax) {
            checkWorkOptionsVal.push("Tax");
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  const getPayLevel = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-levels/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaygScales = (id) => {
    setPayLevel(id);
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-scales/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&payLevelId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayScalesData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNextOfkinPhone(truncatedValue);
          break;
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;

        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`)
      .then((res) => {
        if (res.data.status) {
          setBankLists(
            res.data?.result?.map((d) => ({
              value: d.bankCode,
              label: d.bankName,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPfa = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/PFA/${country}`)
      .then((res) => {
        if (res.data.status) {
          const arr = res.data.result;

          setPfaData(
            arr?.map((d) => ({
              value: d?.code,
              label: d?.value,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getBankName = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.label) {
      return bank?.label;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.label;
    }
  };

  const getBankCode = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.value) {
      return bank?.value;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.value;
    }
  };

  const getpfaName = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.label) {
      return pfa?.label;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.label;
    }
  };

  const getPfaCode = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.value) {
      return pfa?.value;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.value;
    }
  };

  const handleTypeCase = (data) => {
    setLoadAcc(true);
    var paymentinfodata = sessionStorage.getItem("emp_paymentlInfo");
    var personalinfodata = sessionStorage.getItem("emp_personalInfo");
    var personalInfo = JSON?.parse(personalinfodata);
    var paymentInfo = JSON?.parse(paymentinfodata);

    axios
      .post(
        `${config.baseUrl
        }/v1/onboarding-link/${onboardingId}/approve-invite?employerId=${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          annualGrossSalary: data?.annualGrossery,
          department: data?.department,
          employeeType: data?.employeeType,
          hireDate: data?.hireDate,
          payLevelId: payLevel === "Select" ? "" : payLevel,
          payScaleId: payScale === "Select" ? "" : payScale,
          level: data?.level,
          payGroup: paygroup,
          position: data?.position,
          salaryCurrency: data?.currency,
          workEmail: info?.workEmail,
          phoneNumber: personalInfo?.mobile,
          "thirteenMonth": {
            "monthsWorked": monthsWorked,
            "percentage": percentage,
            "policy": policy
          },
          reimbursements: reimbursementTylesList?.length > 0 ?
            formatReimbursements(reimbursementTylesList) : {},
          firstName: personalInfo?.fname,
          otherName: personalInfo?.otherName,
          lastName: personalInfo?.lname,
          gender: personalInfo?.gender,
          maritalStatus: personalInfo?.maritalStatus,
          computePension: selectedCheckGroup ? checkWorkOptions?.includes("Pension") : false,
          computeTax: selectedCheckGroup ? checkWorkOptions?.includes("Tax") : false,
          dateOfBirth: personalInfo?.dob,
          contactAddress: personalInfo?.address,
          country: personalInfo?.country,
          personalEmail: personalInfo?.personalEmail,

          nextOfKin: personalInfo?.nextOfKin,
          nextOfKinPhone: personalInfo?.nokPhone,
          nextOfKinAddress: personalInfo?.nextOfKinAddress,
          pfaCode:
            paymentInfo?.pfaName === "Select"
              ? ""
              : getPfaCode(paymentInfo?.pfaName),
          pfaName:
            paymentInfo?.pfaName === "Select"
              ? ""
              : getpfaName(paymentInfo?.pfaName),
          pfaRsaPin: paymentInfo?.pfaRsaPin,
          tin: paymentInfo?.tin,
          taxState: paymentInfo?.taxState,
          paymentOption: paymentInfo?.paymentOption,
          paymentCountry: paymentInfo?.paymentCountry,
          paymentCurrency: paymentInfo?.currency,
          institutionCode: getBankCode(paymentInfo?.bankName),
          institutionName: getBankName(paymentInfo?.bankName),
          institutionBranch: "",
          accountIdentifier: paymentInfo?.accountNo,
          accountName: paymentInfo?.accountName,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadAcc(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            history.push("/employee_onboarding");
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadAcc(false);
        if (err) {
        }
      });
  };

  const currencyOptions = currencyDatas.map((currency) => {
    return (
      <option value={currency.currency} key={currency.code}>
        {currency.currency}
      </option>
    );
  });

  const onDecline = () => {
    if (!reason) {
      Notification("error", "Error", "reason to decline is required");
      return;
    }
    setLoadDec(true);
    axios
      .post(
        `${config.baseUrl
        }/v1/onboarding-link/decline-invite?employerId=${sessionStorage?.getItem(
          "employer_id"
        )}&onboardingLinkId=${onboardingId}&reviewComment=${reason}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadDec(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            history.push("/self-onboarding");
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadDec(false);
        if (err) {
        }
      });
  };

  const getPaygroup = (country) => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(res.data.result?.map((item) => ({
            value: item?.id,
            label: item?.groupName
          })));
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addMoreReimbursement = () => {
    const newId = reimbursementTylesList.length > 0
      ? reimbursementTylesList[reimbursementTylesList.length - 1].id + 1
      : 1;
    setAddReimbursement([...reimbursementTylesList, { id: newId, allowanceType: "", type: "", value: "" }]);
  }

  const handleReimbursement = (e) => {
    e.preventDefault();
    setLoadUpdateReimbursement(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-reimbursement/${sessionStorage.getItem(
          "employeeID"
        )}`,
        reimbursementTylesList?.length > 0 ?
          formatReimbursements(reimbursementTylesList) : {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdateReimbursement(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateReimbursement(false);
        if (err) {
        }
      });
  };

  const formatReimbursements = (allowances) => {
    return allowances.reduce((acc, item) => {
      if (item.allowanceType && item.value) {
        acc[item.allowanceType] = {
          type: item.type || "PERCENTAGE", // Default type if not provided
          value: parseFloat(item.value),
        };
      }
      return acc;
    }, {});
  };

  const paygroupOptions = paygroupData.map((paygroup) => {
    return (
      <option value={paygroup.code} key={paygroup.id}>
        {paygroup.groupName}
      </option>
    );
  });

  const handleChangeReimbursement = (id, dropdownVal, value) => {
    setAddReimbursement((prevState) =>
      prevState.map((list) =>
        list.id === id ? { ...list, type: dropdownVal, value: value } : list
      )
    );
  };

  const handleRemovereimbursement = (id) => {
    const updatedProperties = reimbursementTylesList.filter((prorate) => prorate.id !== id);
    setAddReimbursement(updatedProperties);
  };



  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const handleEmployeeUpdate = (type, e) => {
    e.preventDefault();
    const workInfo = {
      pfaCode: pfa,
      pfaRsaPin: info.pfaRsaPin,
      tin: taxId,
      level,
      employeeType,
      level,
      taxState: taxState,
      position: position,
      department: department,
      employerId: info.employerId,
    };
    const personalInfo = {
      contactAddress: contactAddress,
      country: country,
      employerId: info.employerId,
      firstName: firstname,
      gender: gender,
      lastName: lastname,
      maritalStatus: maritalStatus,
      mobile: mobile,
      nextOfKin: nextOfKin,
      nextOfKinAddress: nextOfKinAddress,
      nextOfKinPhone: nextOfKinPhone,
      otherName: othername,
    };
    if (type === "Work") {
      setLoad2(true);
    } else {
      setLoad1(true);
    }
    axios
      .put(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,

        type === "Work" ? workInfo : personalInfo,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad1(false);
        setLoad2(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          setTimeout(() => {
            // window.location.reload(false)
          }, 2500);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad1(false);
        setLoad2(false);
        if (err) {
        }
      });
  };

  const closeModal = () => {
    setVisible(false);
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getMonthlyNetSalary = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/employees/pay-structure?annualGrossSalary=${annualGrossery}&computeNHF=false&computePension=${selectedCheckGroup ? checkWorkOptions?.includes(
          "Pension"
        ) : false}&computeTax=${selectedCheckGroup ? checkWorkOptions?.includes("Tax") : false}&country=${personalInfo?.country
        }&currency=${currency}&employeeType=${employeeType}&employerId=${sessionStorage?.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMonthltyNetSalaryValue(res.data.result.monthlyNetSalary);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (annualGrossery) {
      getMonthlyNetSalary();
    }
  }, [annualGrossery, checkWorkOptions, currency, employeeType]);

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {loadEmployeeInfo && (
              <div className="text-center py-5 ">
                <Spin />
              </div>
            )}
            {!loadEmployeeInfo && (
              <div>
                <div className="col-sm-12 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <form>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Work Email
                              </label>
                              <input
                                type="email"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                disabled
                                value={info?.workEmail}
                              />
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Department
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Department");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Department</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("department", {
                                  required: false,
                                  onChange: (e) =>
                                    setDepartment(e.target?.value),
                                  value: department,
                                })}
                                value={department}
                              >
                                <option selected>Select</option>
                                {departmentDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.department && (
                                <span className="validate-error">
                                  {errors.department.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Type{" "}
                                <span className="text-danger ">* </span>
                              </label>
                              <select
                                class="form-select"
                                name="employeeType"
                                aria-label="Default select example"
                                // onChange={(e) => setEmployeeType(e.target.value)}
                                {...register("employeeType", {
                                  required: "Employee Type is required!",
                                  onChange: (e) => {
                                    setEmployeeType(e.target.value);
                                    setPayGroup([]);
                                  },
                                  value: employeeType,
                                })}
                                value={employeeType}
                              >
                                <option selected>Select</option>
                                {employeeTypeData.map((employeeType) => {
                                  return (
                                    <option selected>
                                      {employeeType.value}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.employeeType && (
                                <span className="validate-error">
                                  {errors.employeeType.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Position
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Position");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Position</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("position", {
                                  required: false,
                                  onChange: (e) => setPosition(e.target?.value),
                                  value: position,
                                })}
                                value={position}
                              >
                                <option selected>Select</option>
                                {positionDatas.map((element) => {
                                  return (
                                    <option value={element.name}>
                                      {" "}
                                      {element.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.position && (
                                <span className="validate-error">
                                  {errors.position.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Pay Level
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      history?.push("/manage_paylevel");
                                    }}
                                  >
                                    <b> Manage Pay Level</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                name="level"
                                aria-label="Default select example"
                                // onChange={(e) => setLevel(e.target.value)}
                                {...register("paylevel", {
                                  required: false,
                                  onChange: (e) =>
                                    getPaygScales(e?.target?.value),
                                  value: payLevel,
                                })}
                                value={payLevel}
                              >
                                <option selected>Select</option>
                                {payLevelDatas.map((level) => {
                                  return (
                                    <option value={level.id}>
                                      {level.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.paylevel && (
                                <span className="validate-error">
                                  {errors.paylevel.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Pay Scale
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      history?.push("/manage_payscale");
                                    }}
                                  >
                                    <b> Manage Pay Scale</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                name="payScale"
                                aria-label="Default select example"
                                // onChange={(e) => setLevel(e.target.value)}
                                {...register("payScale", {
                                  required: false,
                                  onChange: (e) =>
                                    setPayScale(e?.target?.value),
                                  value: payScale,
                                })}
                                value={payScale}
                              >
                                <option selected>Select</option>
                                {payScaleData.map((level) => {
                                  return (
                                    <option value={level.id}>
                                      {level.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.payScale && (
                                <span className="validate-error">
                                  {errors.payScale.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label for="exampleInputEmail1" class="form-label">
                                  Pay Group
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      history?.push("/paygroup");
                                    }}
                                  >
                                    <b> Manage Pay Group</b>
                                  </span>
                                </div>
                              </div>

                              <Select
                                mode="multiple"
                                value={paygroup}
                                style={{
                                  width: "100%",
                                  marginRight: "1rem",
                                }}
                                placeholder=""
                                onChange={(val) => {
                                  setPayGroup(val);
                                }}
                                options={paygroupData}
                              />
                            </div>
                          </div>
                          {/* <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Employee Level
                                </label>
                                <div>
                                  <span
                                    type="submit"
                                    className="font-weight-bold xx-btn"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      setAddTypeName("Employee Level");
                                      setVisible(true);
                                    }}
                                  >
                                    <b> Manage Employee Level</b>
                                  </span>
                                </div>
                              </div>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={level}
                                {...register("level", {
                                  required: false,
                                  onChange: (e) => setLevel(e.target?.value),
                                  value: level,
                                })}
                              >
                                <option selected>Select</option>
                                {levelDatas.map((level) => {
                                  return (
                                    <option value={level.level}>
                                      {level.level}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.level && (
                                <span className="validate-error">
                                  {errors.level.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Employee Type{" "}
                                <span className="text-danger ">* </span>
                              </label>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                value={employeeType}
                                {...register("employeeType", {
                                  required: "Employee Type is required!",
                                  onChange: (e) => {
                                    setEmployeeType(e.target?.value);
                                    if (e.target?.value !== "FULL-TIME") {
                                      setPayGroup("");
                                    }
                                  },
                                  value: employeeType,
                                })}
                              >
                                <option selected>Select</option>
                                {employeeTypeData.map((type) => {
                                  return (
                                    <option value={type.value}>
                                      {type.value}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.employeeType && (
                                <span className="validate-error">
                                  {errors.employeeType.message}
                                </span>
                              )}
                            </div>
                          </div> */}

                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Hire Date{" "}
                                <span className="text-danger ">* </span>
                              </label>{" "}
                              <Controller
                                name="hireDate"
                                control={control}
                                {...register("hireDate", {
                                  required: "Hire Date is required!",
                                })}
                                render={({ field }) => (
                                  <DatePicker
                                    onChange={onChangeHireDate}
                                    style={{ height: "3rem", width: "100%" }}
                                    value={
                                      hireDate
                                        ? dayjs(hireDate, dateFormat)
                                        : ""
                                    }
                                    disabledDate={(current) =>
                                      current.isAfter(moment())
                                    }
                                  />
                                )}
                              />
                              {errors.hireDate && (
                                <span className="validate-error">
                                  {errors.hireDate.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">

                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Salary Currency{" "}
                                <span className="text-danger ">* </span>
                              </label>

                              <select
                                class="form-select"
                                aria-label="Default select example"
                                {...register("currency", {
                                  required: "Salary Currency is required!",
                                  onChange: (e) => setCurrency(e.target?.value),
                                  value: currency,
                                })}
                                value={currency}
                              >
                                <option selected>Select</option>
                                {currencyOptions}
                              </select>
                              {errors.currency && (
                                <span className="validate-error">
                                  {errors.currency.message}
                                </span>
                              )}
                            </div>
                          </div>
                          {currency && hireDate && employeeType && (
                            <div class="col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Annual Gross Salary{" "}
                                  <span className="text-danger ">* </span>
                                </label>
                                <Controller
                                  name="annualGrossery"
                                  control={control}
                                  {...register("annualGrossery", {
                                    required:
                                      "Annual Gross Salary is required!",
                                    onChange: (e) =>
                                      setAnnualGrossery(e.target.value),
                                    value: annualGrossery,
                                  })}
                                  render={({ field }) => (
                                    <NumberFormatter
                                      {...field}
                                      label="Annual Gross Salary"
                                      name="amount"
                                      error={errors.annualGrossery?.message}
                                      prefix={"₦"}
                                    />
                                  )}
                                />
                                {monthlyNetSalaryVal && (
                                  <div
                                    className="text-right d-flex justify-content-end"
                                    style={{
                                      color: "#1fc157",
                                      textAlign: "right",
                                    }}
                                  >
                                    Monthly Net Salary- {currency}{" "}
                                    {monthlyNetSalaryVal ? (
                                      <span
                                        style={{
                                          color: "#1fc157",
                                          textAlign: "right",
                                        }}
                                      >
                                        {Intl.NumberFormat("en-US").format(
                                          monthlyNetSalaryVal
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          color: "#1fc157",
                                          textAlign: "right",
                                        }}
                                      >
                                        0
                                      </span>
                                    )}
                                  </div>
                                )}

                                {errors.annualGrossery && (
                                  <span className="validate-error">
                                    {errors.annualGrossery.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div class="col-sm-12 col-md-6">
                            <div className="pt-2 mb-3">
                              <div>
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Statutory Deductions
                                </label>
                              </div>
                              <div>
                                <Checkbox.Group
                                  options={checkOptions}
                                  defaultValue={[]}
                                  onChange={onChangeCheckbox}
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div className="pt-2 mb-3">
                              <div>
                                <label
                                  for="exampleInputEmail1"
                                  class="form-label"
                                >
                                  Do you want to set up Thirteen month and Reimbursement?
                                </label>
                              </div>
                              <div class="form-check container-fluid pb-4">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={setUpCheck} onChange={(e) => setSetupCheck(e.target.checked)} />

                              </div>
                            </div>
                          </div>
                        </div>

                        {setUpCheck &&
                          <div>
                            <hr />
                            <div className="row pb-2">
                              <div className="" style={{ fontWeight: '600' }}>Thirteenth Month Pay </div>
                            </div>


                            <div className="row">
                              <div className="col-sm-12 col-md-4">
                                <div class="mb-3 ">
                                  <div>
                                    <label for="exampleInputEmail1" class="form-label">
                                      Policy
                                    </label>
                                  </div>
                                  <div class="">
                                    <select
                                      class="form-select"
                                      name="level"
                                      aria-label="Default select example"
                                      onChange={(e) => setPolicy(e.target.value)}
                                      value={policy}

                                    >
                                      <option selected>Select</option>
                                      {thirteenMonthPoliciesData.map((d) => {
                                        return (
                                          <option value={d.code}>{d.code?.replace(/_+/g, " ")}</option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-4">
                                <div class="mb-3">
                                  <div>
                                    <label for="exampleInputEmail1" class="form-label">
                                      Months Worked
                                    </label>
                                  </div>
                                  <div class="">
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="exampleInputEmail1"
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        // Optionally, you can also enforce the max value in your change handler
                                        if (newValue === "" || (Number(newValue) <= 12 && Number(newValue) >= 0)) {
                                          setMonthsWorked(newValue);
                                        }
                                      }}
                                      aria-describedby="emailHelp"
                                      value={monthsWorked}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-4">
                                <div class="mb-3 ">
                                  <label for="exampleInputEmail1" class="form-label">
                                    Percentage(%)
                                  </label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      // Optionally, you can also enforce the max value in your change handler
                                      if (newValue === "" || (Number(newValue) <= 100 && Number(newValue) >= 0)) {
                                        setPercentage(newValue);
                                      }
                                    }}
                                    aria-describedby="emailHelp"
                                    max={100}
                                    value={percentage}
                                  />
                                </div>
                              </div>
                            </div>

                            <hr />
                            <div className="">
                              <div className="d-flex justify-content-between align-items-center pb-2">
                                <div className="" style={{ fontWeight: '600' }}>Manage Reimbursement</div>
                                <div
                                  type="submit"
                                  class="btn btn-primary"
                                  style={{
                                    background: "#1FC157",
                                    marginTop: "",
                                    color: "white",
                                  }}
                                  onClick={addMoreReimbursement}
                                >
                                  Add More <i className="fa fa-plus-circle"></i>
                                </div>
                              </div>
                              <div className="px-2">
                                {reimbursementTylesList?.length > 0 &&
                                  <div>
                                    {reimbursementTylesList?.map((d) => {
                                      return (
                                        <div className="row">
                                          <div className="col-sm-12 col-md-6">
                                            <div>
                                              <label for="exampleInputEmail1" class="form-label">
                                                Allowance Type
                                              </label>
                                            </div>
                                            <div class="mb-3 mt-1">
                                              <select
                                                class="form-select"
                                                name="level"
                                                aria-label="Default select example"
                                                onChange={(e) =>
                                                  handleInputChangeReimbursement(d.id, "allowanceType", e.target.value)
                                                }
                                                value={d?.allowanceType}
                                              >
                                                <option selected>Select</option>
                                                {allowancesTypes.map((d) => {
                                                  return (
                                                    <option value={d.code}>{d.code?.replace("_", " ")}</option>
                                                  );
                                                })}
                                              </select>
                                            </div>
                                          </div>
                                          <div class="col-sm-12 col-md-6 position-relative">
                                            <div class="mb-3 mt-1">
                                              <label for="exampleInputEmail1" class="form-label">
                                                Reimbursement Type
                                              </label>
                                              <CustomDropdownInput
                                                options={reimbursementValues}
                                                data={d}
                                                onChange={handleChangeReimbursement.bind(this, d?.id)}

                                              />
                                            </div>
                                            <div className="close-reimbursement-x" onClick={handleRemovereimbursement.bind(this, d?.id)}>
                                              <div className="d-flex justify-content-end align-items-center"><i className="fa fa-times-circle  text-danger fa-2x"></i></div>
                                            </div>
                                          </div>



                                        </div>
                                      )
                                    })}
                                  </div>}



                              </div>






                            </div>
                          </div>
                        }
                        <br />
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              style={{
                                background: "#1FC157",
                                marginTop: "1rem",
                                color: "white",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push("/employee-payment-info");
                              }}
                            >
                              Back
                            </button>
                          </div>
                          {info?.reviewStatus !== "APPROVED" && (
                            <div className="d-flex justify-content-between align-items-center">
                              <button
                                type="submit"
                                class="btn btn-primary mr-3"
                                style={{
                                  background: "#1FC157",
                                  marginTop: "1rem",
                                  marginRight: "1.2rem",
                                  color: "white",
                                }}
                                onClick={handleSubmit(handleTypeCase)}
                              >
                                {loadAcc ? (
                                  <ThreeDots
                                    visible={loadAcc}
                                    height="20"
                                    width="50"
                                    color="#ffffff"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                  />
                                ) : (
                                  "Approve"
                                )}
                              </button>
                              <button
                                type="submit"
                                class="btn btn-primary mr-2"
                                style={{
                                  background: "red",
                                  marginTop: "1rem",
                                  color: "white",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOpenDecModal(true);
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CrudOptimizer
        name={addTypeName}
        visible={visible}
        setVisible={setVisible}
        closeModal={closeModal}
        setCrudDatas={setCrudDatas}
        loadAllCrus={loadAllCrus}
      />
      <Modal
        title={"Decline Invite"}
        visible={decModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setOpenDecModal(false);
          setReason("");
        }}
      >
        <div class="">
          <div className="text-center">{loadDec && <Spin />}</div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Decline Reason
            </label>
            <input
              type="text"
              class="form-control"
              rows="20"
              id="exampleInputEmail1"
              style={{ height: "100px" }}
              aria-describedby="emailHelp"
              onChange={(e) => {
                setReason(e.target?.value);
              }}
              value={reason}
            ></input>
            <br />
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  marginTop: "1rem",
                  color: "white",
                }}
                onClick={onDecline}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
