import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../../hooks/useHttps";
import config from "../../../../config";
import { Modal, Drawer, notification, Spin } from "antd";
import { Pagination, Button } from "antd";
export default function EmployeeInfo({generate}) {
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [deductionStatus, setDeductionStatus] = useState("");
  const [deductionDatas, setDeductionDatas] = useState([]);
  const [noDeductions, setNoDeductionDatas] = useState(false);
  const [loadDeductions, setLoadDeductionDatas] = useState(false);
  const [deductionsInfo, setDeductionsInfo] = useState([]);

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setDeductionStatus(record.status);
    setVisibleDraw(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPaged = (queryString) => {
    setLoadDeductionDatas(true);
    axios
      .get(`${config.baseUrl}/v1/deductions/employee?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setDeductionDatas(res.data.result);
          setLoadDeductionDatas(false);
          setNoDeductionDatas(false);
        } else {
          setNoDeductionDatas(true);
          setLoadDeductionDatas(false);
          setDeductionDatas([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employeeId=${sessionStorage.getItem(
      "employeeID"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getDeductions = () => {
    setLoadDeductionDatas(true)
    axios
      .get(
        `${
          config.baseUrl
        }/v1/deductions/employee?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoDeductionDatas(false);
            setLoadDeductionDatas(false);
            setDeductionDatas(res.data.result);
            setDeductionsInfo(res.data);
          } else {
            setNoDeductionDatas(true);
            setLoadDeductionDatas(false);
            setDeductionDatas([]);
          }
        } else {
          setNoDeductionDatas(true);
          setLoadDeductionDatas(false);
          setDeductionDatas([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this deduction information ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteDeduction();
      },
      onCancel() {},
    });
  };

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this deduction information ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusDeduction();
      },
      onCancel() {},
    });
  };

  const deleteDeduction = () => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/deductions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getDeductions();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const statusDeduction = () => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/deductions/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getDeductions();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  useEffect(() => {
    getDeductions();
  }, [generate]);

  var totalmonthlyDeduction = deductionDatas.reduce(
    (n, { monthlyDeduction }) => n + (monthlyDeduction),
    0
  );

  var totalyearlyDeduction = deductionDatas.reduce(
    (n, { yearlyDeduction }) => n + (yearlyDeduction),
    0
  );

  return (
    <div>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">Deductions</div>
            <div class="card-body">
              {!loadDeductions && deductionDatas?.length > 0 &&
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Deduction Type</th>
                      <th scope="col">Monthly Deductions</th>
                      <th scope="col">Annual Deductions</th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deductionDatas.map((data) => {
                      return (
                        <tr>
                          <td>
                            {data.deductionType
                              ? data.deductionType.replace(/_+/g, " ")
                              : ""}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.monthlyDeduction
                              ? Intl.NumberFormat("en-US").format(
                                  data.monthlyDeduction
                                )
                              : 0}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.yearlyDeduction
                              ? Intl.NumberFormat("en-US").format(
                                  data.yearlyDeduction
                                )
                              : 0}
                          </td>
                          <td>
                            {data.payStructure?.id ?
                              
                                data?.payStructure.payValue
                                
                              : 0}%
                          </td>
                          <td>
                            {data.createdAt ? data.createdAt.slice(0, 10) : ""}
                          </td>
                          <td>
                            <span
                              className={
                                {
                                  ACTIVE: "badge bg-success",
                                  INACTIVE: "badge bg-danger",
                                }[data.status ? "ACTIVE" : "INACTIVE"]
                              }
                            >
                              {data.status ? "ACTIVE" : "INACTIVE"}
                            </span>
                          </td>{" "}
                          <td>
                            <button
                              type="submit"
                              onClick={showDrawer.bind(this, data)}
                              class="btn btn-primary"
                              style={{
                                background: "#1FC157",
                                color: "white",
                              }}
                            >
                              Action
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td>
                        {deductionDatas?.length > 0 && (
                          <h5 style={{ fontWeight: "bold" }}>
                            NGN{" "}
                            {totalmonthlyDeduction
                              ? totalmonthlyDeduction
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : 0}
                          </h5>
                        )}
                      </td>
                      <td>
                        {deductionDatas?.length > 0 && (
                          <h5 style={{ fontWeight: "bold" }}>
                            NGN{" "}
                            {totalyearlyDeduction
                              ? totalyearlyDeduction
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : 0}
                          </h5>
                        )}
                      </td>

                      <td></td>

                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center pt-5 pb-5">
                  <div className="text-center pagination-part pt-5">
                    <Pagination
                      current={page}
                      total={totalItems}
                      defaultPageSize={10}
                      onChange={pagination}
                      itemRender={itemRender}
                    />
                  </div>
              
              </div>
              </div>}

              {loadDeductions && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
              {noDeductions &&  !loadDeductions && (
                <p className="text-center pt-5 pb-5">No Deductions Yet.</p>
              )}

            
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {deductionStatus ? "DISABLE" : "ENABLE"}
        </div>

        <hr />
      </Drawer>
    </div>
  );
}
