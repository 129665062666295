import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import config from "../../../../config";
import { Modal, notification, Spin, Checkbox, Select } from "antd";
import { useHttp } from "../../../../hooks/useHttps";
import { NumberFormatter } from "../../../../formatter/index";
import CustomDropdownInput from "./custom-input-group";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
export default function EmployeeInfo({ generate,getEmployee }) {
  const [load, setLoad] = useState(false);
  const [paygroup, setPaygroup] = useState([]);
  const [openUpdate, setUpdate] = useState(false);
  const [contactDetails, setContactDetails] = useState({});
  const [paygroupData, setPaygroupData] = useState([]);
  const [annualGrossSalary, setAnnualGrossSalary] = useState("");
  const [monthlyGossSalary, setMonthlyGrossSalary] = useState("");
  const [prorateType, setProrateType] = useState("");
  const [salaryDetails, setSalaryDetails] = useState({});
  const [prorateValue, setProrateValue] = useState("");
  const [monthsWorked, setMonthsWorked] = useState("")
  const [percentage, setPercentage] = useState("")
  const [policy, setPolicy] = useState("")
  const [monthlyReimbursement, setMonthlyRembursement] = useState(0)
  const [loadUpdate13thMonth, setLoadUpdate13thMonth] = useState(false);
  const [loadUpdateReimbursement, setLoadUpdateReimbursement] = useState(false);
  const [loadProrates, setLoadUpdateProrates] = useState(false);
  const [totalReimbursement, setTotalReimbursement] = useState(0)
  const [thirteenMonthPay, setThirteenMonthPay] = useState(0)
  const [annualReimbursement, setAnnualRembursement] = useState(0)
  const [thirteenMonthTax, setThirteenMonthTax] = useState(0)
  const [thirteenMonthPension, setThirteenMonthPension] = useState(0)


  const [netSalary, setNetSalary] = useState("");
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [payLevelDatas, setPayLevelData] = useState([]);
  const [payLevel, setPayLevel] = useState("");
  const [payScaleData, setPayScalesData] = useState([]);
  const [monthlyNetSalaryVal, setMonthltyNetSalaryValue] = useState("");
  const [bonusAmount, setBonusAmount] = useState("");
  const [reimbursementTylesList, setAddReimbursement] = useState([{ id: 1, allowanceType: "", type: "", value: "" }])
  const [payScale, setPayScale] = useState("");
  const [monthlyNetSalary, setMonthlyNetSalary] = useState("");
  const [bonus, setBonus] = useState("");
  const role = sessionStorage.getItem("activeRole");

  const [prorateAddList, setAddProratesList] = useState([
    { id: 1, prorateName: "", prorateType: "NONE", prorateValue: "" }
  ]);

  const [checkWorkOptionsVal, setCheckOptionVal] = useState([]);
  const [loadingProrate, prorateDataType] = useHttp(
    `${config.baseUrl}/v1/options/PRORATE_TYPES`,
    []
  );

  const [loadingValues, reimbursementValues] = useHttp(
    `${config.baseUrl}/v1/options/VALUE_TYPE`,
    []
  );

  const [loadingPolicies, thirteenMonthPoliciesData] = useHttp(
    `${config.baseUrl}/v1/options/13TH_MONTH_POLICY`,
    []
  );

  const [loadAllowances, allowancesTypes] = useHttp(
    `${config.baseUrl}/v1/options/ALLOWANCE_TYPE`,
    []
  );

  const [checkWorkOptions, setCheckWorkOptions] = useState([]);

  const onChangeCheckbox = (checkedValues) => {
    setCheckWorkOptions(checkedValues);
  };

  const checkOptions = [
    {
      label: "Pension",
      value: "Pension",
    },

    {
      label: "Tax",
      value: "Tax",
    },
  ];

  const options = [
    { value: "SELECT", label: "Select Type " },
    { value: "REMIBURSEMENT_ADD", label: "REIMBURSEMENT ADD" },
    { value: "REMIBURSEMENT_FLAT", label: "REMIBURSEMENT FLAT" },
  ];


  const allowanceTypeReimbursementChange = (id, e) => {
    console.log('function got here')
    console.log(id, e)

  }
  const handleChangeReimbursement = (id, dropdownVal, value) => {
    setAddReimbursement((prevState) =>
      prevState.map((list) =>
        list.id === id ? { ...list, type: dropdownVal, value: value } : list
      )
    );
  };

  function convertReimbursements(reimbursements) {
    var newArr = Object.entries(reimbursements).map(([key, data], index) => ({
      id: index + 1,
      allowanceType: key,
      type: data.type,
      value: data.value
    }));
    setAddReimbursement(newArr)
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const updateSalaryDetail = (e) => {
    e.preventDefault();
    setUpdate(true);
  };
  const closeUpdate = () => {
    setUpdate(false);
    setPaygroup("");
    setAnnualGrossSalary("");
  };
  useEffect(() => {
    getContactDetails();
    getPaygroup();
    getPayLevel();
  }, [generate]);

  const getPayLevel = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-levels/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayLevelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPayScales = (id) => {
    setPayLevel(id);
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-scales/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&payLevelId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPayScalesData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaygroup = (country) => {
    axios
      .get(
        `${config.baseUrl
        }/v1/pay-groups/filtered?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPaygroupData(
            res.data.result?.map((item) => ({
              value: item?.id,
              label: item?.groupName,
            }))
          );
        } else {
        }
      })

      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleInputChange = (id, field, newValue) => {
    setAddProratesList((prevState) =>
      prevState.map((prorateList) =>
        prorateList.id === id ? { ...prorateList, [field]: newValue } : prorateList
      )
    );
  };

  const handleInputChangeReimbursement = (id, field, value) => {
    setAddReimbursement((prevState) =>
      prevState.map((list) =>
        list.id === id ? { ...list, allowanceType: value } : list
      )
    );
  }

  console.log(reimbursementTylesList)


  const getContactDetails = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setAnnualGrossSalary(contactDetails?.annualGrossSalary);
          setMonthlyNetSalary(contactDetails?.monthlyNetSalary);
          setPaygroup(contactDetails?.payGroupId ? contactDetails?.payGroupId : []);
          setProrateType(contactDetails?.proRateType);
          setPayScale(contactDetails?.payScale)
          setPayLevel(contactDetails?.payLevel)
          setProrateValue(contactDetails?.proRateValue);
          setSalaryDetails(contactDetails);
          setTotalReimbursement(contactDetails?.totalReimbursement)
          setMonthlyRembursement(contactDetails.monthlyReimbursement)
          setAnnualRembursement(contactDetails.annualReimbursement)
          setThirteenMonthPay(contactDetails?.thirteenMonth?.thirteenMonthPay)
          setMonthlyGrossSalary(contactDetails?.monthlyGrossSalary);
          setThirteenMonthTax(contactDetails?.thirteenMonth?.thirteenMonthTax)
          setThirteenMonthPension(contactDetails?.thirteenMonth?.thirteenMonthPension)

          if (contactDetails?.prorations?.length > 0) {

            setAddProratesList(contactDetails?.prorations?.map((d) => ({
              prorateType: d?.type,
              prorateValue: d?.value,
              prorateName: d?.name,
              id: Math.random().toString(36).substr(2, 8)
            })))
          }
          else {
            setAddProratesList([
              { id: 1, prorateName: "", prorateType: "NONE", prorateValue: "" }
            ])
          }
          if (contactDetails?.thirteenMonth) {
            setMonthsWorked(contactDetails?.thirteenMonth?.monthsWorked);
            setPolicy(contactDetails?.thirteenMonth?.policy)
            setPercentage(contactDetails?.thirteenMonth?.percentage)
          }
          else {
            setMonthsWorked("12");
            setPolicy("")
            setPercentage("12")
          }
          if (contactDetails?.reimbursements) {
            convertReimbursements(contactDetails?.reimbursements)
          }
          else {
            setAddReimbursement([{ id: 1, allowanceType: "", type: "", value: "" }])
          }
          setBonus(contactDetails?.bonusAmount);
          setContactDetails(contactDetails);
          if (contactDetails?.computePension) {
            checkWorkOptions.push("Pension");
            checkWorkOptionsVal.push("Pension");
          }
          if (contactDetails?.computeTax) {
            checkWorkOptionsVal.push("Tax");
            checkWorkOptions?.push("Tax");
          }
        } else {
          setAnnualGrossSalary(0);
          setMonthlyGrossSalary(0);
          setMonthlyNetSalary(0);
          setPaygroup("");
          setProrateType("");
          setProrateValue("");
          getPaygroup("");
          setBonus("");
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const formatReimbursements = (allowances) => {
    return {
      reimbursements: allowances.reduce((acc, item) => {
        if (item.allowanceType && item.value) {
          acc[item.allowanceType] = {
            type: item.type || "PERCENTAGE", // Default type if not provided
            value: parseFloat(item.value),
          };
        }
        return acc;
      }, {}),
    };
  };

  const formatNumber = (num) => {
    return "₦" + new Intl.NumberFormat().format(num);
  };

  const saveProrate = (e) => {
    e.preventDefault()
    console.log(prorateAddList)
  }

  const errorNote = () => {
    Notification("error", "Error", "Permission not allowed");
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const addMoreProrate = () => {
    const newId = prorateAddList.length > 0
      ? prorateAddList[prorateAddList.length - 1].id + 1
      : 1;
    setAddProratesList([...prorateAddList, { id: newId, prorateName: "", prorateType: "NONE", prorateValue: "" }]);

  }

  const addMoreReimbursement = () => {
    const newId = reimbursementTylesList.length > 0
      ? reimbursementTylesList[reimbursementTylesList.length - 1].id + 1
      : 1;
    setAddReimbursement([...reimbursementTylesList, { id: newId, allowanceType: "", type: "", value: "" }]);
  }

  const handleThirteenMonth = (e) => {
    e.preventDefault();
    if (!monthsWorked && !percentage && !policy) {
      Notification("error", "Error", "Months Worked, Percentage, and Policy are required")
      return;
    }
    const data = {
      "thirteenMonth": {
        "monthsWorked": monthsWorked,
        "percentage": percentage,
        "policy": policy
      }
    };
    setLoadUpdate13thMonth(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-13th-month/${sessionStorage.getItem(
          "employeeID"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdate13thMonth(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          getEmployee()

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdate13thMonth(false);
        if (err) {
        }
      });
  };

  const handleProrates = (e) => {
    e.preventDefault();
    const data = prorateAddList?.map((d) => {
      return {
        name: d?.prorateName,
        type: d?.prorateType,
        value: d?.prorateValue
      }
    })

    const actualData = {
      prorations: prorateAddList?.length > 0 ? data : []
    }
    setLoadUpdateProrates(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-proration/${sessionStorage.getItem(
          "employeeID"
        )}`,
        actualData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdateProrates(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          getEmployee()

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateProrates(false);
        if (err) {
        }
      });
  };

  const handleReimbursement = (e) => {
    e.preventDefault();
    setLoadUpdateReimbursement(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-reimbursement/${sessionStorage.getItem(
          "employeeID"
        )}`,
        reimbursementTylesList?.length > 0 ?
          formatReimbursements(reimbursementTylesList) : {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdateReimbursement(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          getEmployee()

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdateReimbursement(false);
        if (err) {
        }
      });
  };

  const handleSalaryUpdate = (e) => {
    e.preventDefault();
    const data = {
      annualGrossSalary,
      payGroupId: paygroup,
      computePension: checkWorkOptions?.includes("Pension"),
      computeTax: checkWorkOptions?.includes("Tax"),
      payLevelId: payLevel === "Select" ? "" : payLevel,
      payScaleId: payScale === "Select" ? "" : payScale,
    };
    setLoadUpdate(true);
    axios
      .put(
        `${config.baseUrl}/v1/employees/manage-salary/${sessionStorage.getItem(
          "employeeID"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadUpdate(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          getEmployee()

        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadUpdate(false);
        if (err) {
        }
      });
  };

  const getMonthlyNetSalary = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/employees/pay-structure?annualGrossSalary=${annualGrossSalary}&computeNHF=false&computePension=${checkWorkOptions?.includes(
          "Pension"
        )}&computeTax=${checkWorkOptions?.includes("Tax")}&country=${contactDetails?.country
        }&currency=${contactDetails?.salaryCurrency}&employeeType=${contactDetails?.employeeType
        }&employerId=${sessionStorage?.getItem("employer_id")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMonthltyNetSalaryValue(res.data.result.monthlyNetSalary);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleRemove = (id) => {
    console.log(id)
    const updatedProperties = prorateAddList.filter((prorate) => prorate.id !== id);
    setAddProratesList(updatedProperties);
  };

  const handleRemovereimbursement = (id) => {
    const updatedProperties = reimbursementTylesList.filter((prorate) => prorate.id !== id);
    setAddReimbursement(updatedProperties);
  };

  useEffect(() => {
    if (annualGrossSalary) {
      getMonthlyNetSalary();
    }
  }, [annualGrossSalary, checkWorkOptions]);

  const paygroupOptions = paygroupData.map((paygroup) => {
    return <option value={paygroup.id}>{paygroup.groupName}</option>;
  });




  return (
    <div className="">
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">

            <div class="card-body">
              {load && (
                <div className="text-center">
                  <Spin />
                </div>
              )}



              {!load && (
                <form>
                  <div className="row">
                    <div className="" style={{ fontWeight: '600', marginTop: '-1rem' }}>Manage Salary </div>
                  </div>

                  <div className="row mt-3">

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        {monthlyNetSalaryVal !== 0 ? (
                          <label for="exampleInputEmail1" class="form-label">
                            Annual Gross Salary{" "}
                            <span style={{ color: "#1fc157" }}>
                              (Monthly Net Salary -{" "}
                              {contactDetails?.salaryCurrency}{" "}
                              {Intl.NumberFormat("en-US").format(
                                monthlyNetSalaryVal
                              )}
                              )
                            </span>
                          </label>
                        ) : (
                          <label for="exampleInputEmail1" class="form-label">
                            Annual Gross Salary
                          </label>
                        )}

                        <Controller
                          name="annualGrossery"
                          control={control}
                          {...register("annualGrossery", {
                            required: false,
                            onChange: (e) =>
                              setAnnualGrossSalary(e.target.value),
                            value: annualGrossSalary,
                          })}
                          render={({ field }) => (
                            <NumberFormatter
                              {...field}
                              label=""
                              value={annualGrossSalary}
                              name="annualGrossery"
                              error={errors.annualGrossery?.message}
                              prefix={"₦"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div>
                        <label for="exampleInputEmail1" class="form-label">
                          Pay Group
                        </label>
                      </div>
                      <div class="mb-3 mt-1">
                        <Select
                          mode="multiple"
                          value={paygroup}
                          style={{
                            width: "100%",
                            marginRight: "1rem",
                          }}
                          placeholder=""
                          onChange={(val) => {
                            setPaygroup(val);
                          }}
                          options={paygroupData ? paygroupData : []}
                        />
                      </div>
                    </div>

                  </div>

                  <div className="row">

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="exampleInputEmail1" class="form-label">
                            Pay Level
                          </label>
                        </div>
                        <select
                          class="form-select"
                          name="level"
                          aria-label="Default select example"
                          onChange={(e) => getPayScales(e.target.value)}
                          value={payLevel}
                        >
                          <option selected>Select</option>
                          {payLevelDatas.map((level) => {
                            return (
                              <option value={level.id}>{level.name}</option>
                            );
                          })}
                        </select>
                        {errors.paylevel && (
                          <span className="validate-error">
                            {errors.paylevel.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="exampleInputEmail1" class="form-label">
                            Pay Scale
                          </label>
                        </div>
                        <select
                          class="form-select"
                          name="payScale"
                          aria-label="Default select example"
                          onChange={(e) => setPayScale(e.target.value)}
                          value={payScale}
                        >
                          <option selected>Select</option>
                          {payScaleData.map((scale) => {
                            return (
                              <option value={scale.id}>{scale.name}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6">
                      <div className="pt-2 mb-3">
                        <div>
                          <label for="exampleInputEmail1" class="form-label">
                            Statutory Deductions
                          </label>
                        </div>
                        <div>
                          <Checkbox.Group
                            options={checkOptions}
                            defaultValue={checkWorkOptionsVal}
                            onChange={onChangeCheckbox}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Monthly Gross Salary
                        </label>
                        <h4 className="font-weight-bold">{monthlyGossSalary ? formatNumber(monthlyGossSalary) : 0}</h4>

                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Monthly Net Salary
                        </label>
                        <h4 className="font-weight-bold">{monthlyNetSalary ? formatNumber(monthlyNetSalary) : 0
                        }</h4>


                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      background: "#1FC157",
                      marginTop: "1rem",
                      color: "white",
                    }}
                    onClick={
                      role === "HUMAN_RESOURCES"
                        ? errorNote
                        : handleSalaryUpdate
                    }
                  >
                    {loadUpdate ? (
                      <ThreeDots
                        visible={loadUpdate}
                        height="20"
                        width="50"
                        color="#ffffff"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                  <hr />
                  <div className="mt-1">
                    <div className="row">
                      <div className="" style={{ fontWeight: '600' }}>Manage 13th Month</div>

                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-12 col-md-4">
                        <div class="mb-3 ">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Policy
                            </label>
                          </div>
                          <div class="">
                            <select
                              class="form-select"
                              name="level"
                              aria-label="Default select example"
                              onChange={(e) => setPolicy(e.target.value)}
                              value={policy}

                            >
                              <option selected>Select</option>
                              {thirteenMonthPoliciesData.map((d) => {
                                return (
                                  <option value={d.code}>{d.code?.replace(/_+/g, " ")}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-4">
                        <div class="mb-3">
                          <div>
                            <label for="exampleInputEmail1" class="form-label">
                              Months Worked
                            </label>
                          </div>
                          <div class="">
                            <input
                              type="number"
                              class="form-control"
                              id="exampleInputEmail1"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                // Optionally, you can also enforce the max value in your change handler
                                if (newValue === "" || (Number(newValue) <= 12 && Number(newValue) >= 0)) {
                                  setMonthsWorked(newValue);
                                }
                              }}
                              aria-describedby="emailHelp"
                              value={monthsWorked}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div class="mb-3 ">
                          <label for="exampleInputEmail1" class="form-label">
                            Percentage(%)
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            id="exampleInputEmail1"
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Optionally, you can also enforce the max value in your change handler
                              if (newValue === "" || (Number(newValue) <= 100 && Number(newValue) >= 0)) {
                                setPercentage(newValue);
                              }
                            }}
                            aria-describedby="emailHelp"
                            max={100}
                            value={percentage}
                          />
                        </div>
                      </div>



                    </div>
                    <br />
                    <div className="row">

                      <div class="col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                             13TH Month Pay
                          </label>
                          <h4 className="font-weight-bold">{thirteenMonthPay ? formatNumber(thirteenMonthPay) : 0}</h4>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                             13TH Month Tax
                          </label>
                          <h4 className="font-weight-bold">{thirteenMonthTax ? formatNumber(thirteenMonthTax) : 0}</h4>

                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                             13TH Month Pension
                          </label>
                          <h4 className="font-weight-bold">{thirteenMonthPension ? formatNumber(thirteenMonthPension) : 0}</h4>

                        </div>
                      </div>

                    </div>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{
                        background: "#1FC157",
                        marginTop: "1rem",
                        color: "white",
                      }}
                      onClick={
                        role === "HUMAN_RESOURCES"
                          ? errorNote
                          : handleThirteenMonth
                      }
                    >
                      {loadUpdate13thMonth ? (
                        <ThreeDots
                          visible={loadUpdate13thMonth}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "Save Changes"
                      )}
                    </button>
                  </div>
                  <hr />
                  <div className="mt-1">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <div className="" style={{ fontWeight: '600' }}>Manage Reimbursement</div>
                      <div
                        type="submit"
                        class="btn btn-primary"
                        style={{
                          background: "#1FC157",
                          marginTop: "",
                          color: "white",
                        }}
                        onClick={addMoreReimbursement}
                      >
                        Add More <i className="fa fa-plus-circle"></i>
                      </div>
                    </div>
                    <div className="px-2">
                      {reimbursementTylesList?.length > 0 &&
                        <div>
                          {reimbursementTylesList?.map((d) => {
                            return (
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div>
                                    <label for="exampleInputEmail1" class="form-label">
                                      Allowance Type
                                    </label>
                                  </div>
                                  <div class="mb-3 mt-1">
                                    <select
                                      class="form-select"
                                      name="level"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        handleInputChangeReimbursement(d.id, "allowanceType", e.target.value)
                                      }
                                      value={d?.allowanceType}
                                    >
                                      <option selected>Select</option>
                                      {allowancesTypes.map((d) => {
                                        return (
                                          <option value={d.code}>{d.code?.replace("_", " ")}</option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-6 position-relative">
                                  <div class="mb-3 mt-1">
                                    <label for="exampleInputEmail1" class="form-label">
                                      Reimbursement Type
                                    </label>
                                    <CustomDropdownInput
                                      options={reimbursementValues}
                                      data={d}
                                      onChange={handleChangeReimbursement.bind(this, d?.id)}

                                    />
                                  </div>
                                  <div className="close-reimbursement-x" onClick={handleRemovereimbursement.bind(this, d?.id)}>
                                    <div className="d-flex justify-content-end align-items-center"><i className="fa fa-times-circle  text-danger fa-2x"></i></div>
                                  </div>
                                </div>



                              </div>
                            )
                          })}
                        </div>}
                      {reimbursementTylesList?.length === 0 &&
                        <div className="text-center py-3">No Reimbursements</div>}

                      <br />
                      {reimbursementTylesList?.length > 0 &&
                        <div className="row">

                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label">
                                Monthly Reimbursement
                              </label>
                              <h4 className="font-weight-bold">{monthlyReimbursement ? formatNumber(monthlyReimbursement) : 0}</h4>

                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label for="exampleInputEmail1" class="form-label">
                                Annual Reimbursement
                              </label>
                              <h4 className="font-weight-bold">{annualReimbursement ? formatNumber(annualReimbursement) : 0}</h4>

                            </div>
                          </div>

                        </div>}

                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{
                          background: "#1FC157",
                          marginTop: "1rem",
                          color: "white",
                        }}
                        onClick={
                          role === "HUMAN_RESOURCES"
                            ? errorNote
                            : handleReimbursement
                        }
                      >
                        {loadUpdateReimbursement ? (
                          <ThreeDots
                            visible={loadUpdateReimbursement}
                            height="20"
                            width="50"
                            color="#ffffff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Save Changes"
                        )}
                      </button>

                    </div>
                  </div>
                  <hr />
                  <div className=" mt-1">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="" style={{ fontWeight: 'bold' }}> Manage Proration</div>
                      <div>
                        <div
                          type="submit"
                          class="btn btn-primary"
                          style={{
                            background: "#1FC157",
                            marginTop: "",
                            color: "white",
                          }}
                          onClick={addMoreProrate}
                        >
                          Add More <i className="fa fa-plus-circle"></i>
                        </div>
                      </div>
                    </div>
                    {prorateAddList?.length > 0 &&
                      <div>
                        {prorateAddList?.map((d) => {
                          return (
                            <div className="px-2 mt-3">
                              <div className="row ">
                                <div class="col-sm-12 col-md-4">
                                  <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">
                                      Prorate Name
                                    </label>

                                    <div>
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        onChange={(e) =>
                                          handleInputChange(d.id, "prorateName", e.target.value)
                                        }
                                        value={d?.prorateName}
                                        aria-describedby="emailHelp"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 col-md-4 ">
                                  <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">
                                      Prorate Type
                                    </label>
                                    <select
                                      class="form-select"
                                      aria-label="Default select example"
                                      onChange={(e) =>
                                        handleInputChange(d.id, "prorateType", e.target.value)
                                      }
                                      value={d?.prorateType}
                                    >
                                      {prorateDataType.map((type) => {
                                        return (
                                          <option value={type.code}>
                                            {type.value
                                              ? type.value.replace(/_+/g, " ")
                                              : ""}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                {d.prorateType === "NONE" && (
                                  <div class="col-sm-12 col-md-4 position-relative">
                                    <div class="mb-3">
                                      <label for="exampleInputEmail1" class="form-label">
                                        Prorate Value
                                      </label>

                                      <div>
                                        <input
                                          type="text"
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          value="NOT APPLICABLE"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="close-prorate-x" onClick={handleRemove.bind(this, d?.id)}>
                                      <div className="d-flex justify-content-end align-items-center"><i className="fa fa-times-circle  text-danger fa-2x"></i></div>
                                    </div>
                                  </div>
                                )}
                                {d.prorateType !== "NONE" && (
                                  <div class="col-sm-12 col-md-4 position-relative">
                                    <div class="mb-3">
                                      <label for="exampleInputEmail1" class="form-label">
                                        {d?.prorateType
                                          ?.replace("PRORATE_BY", "")
                                          ?.replace(/_+/g, " ")}
                                      </label>
                                      {d?.prorateType?.includes("AMOUNT") ? (
                                        <Controller
                                          name="prorateValue"
                                          control={control}
                                          {...register("prorateValue", {
                                            required: false,
                                            onChange: (e) =>
                                              handleInputChange(d.id, "prorateValue", e.target.value),

                                            value: prorateValue,
                                          })}
                                          render={({ field }) => (
                                            <NumberFormatter
                                              {...field}
                                              label=""
                                              name="prorateValue"
                                              value={d?.prorateValue}
                                              error={errors.prorateValue?.message}
                                              prefix={"₦"}
                                            />
                                          )}
                                        />
                                      ) : (
                                        <div>
                                          <input
                                            type="text"
                                            onChange={(e) =>
                                              handleInputChange(d.id, "prorateValue", e.target.value)
                                            }
                                            class="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            value={d?.prorateValue}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div className="close-prorate-x" onClick={handleRemove.bind(this, d?.id)}>
                                      <div className="d-flex justify-content-end align-items-center"><i className="fa fa-times-circle  text-danger fa-2x"></i></div>
                                    </div>
                                  </div>
                                )}


                              </div>
                            </div>
                          )
                        })}
                      </div>}

                    {prorateAddList?.length === 0 &&
                      <div className="text-center py-3">No Prorations</div>}


                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      background: "#1FC157",
                      marginTop: "1rem",
                      color: "white",
                    }}
                    onClick={
                      role === "HUMAN_RESOURCES"
                        ? errorNote
                        : handleProrates
                    }

                  >
                    {loadProrates ? (
                      <ThreeDots
                        visible={loadProrates}
                        height="20"
                        width="50"
                        color="#ffffff"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Update Salary Detail"}
        visible={openUpdate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeUpdate}
      >
        <div className="container">
          {load && (
            <div className="text-center">
              {" "}
              <Spin />
            </div>
          )}
          <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Annual Gross Salary
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setAnnualGrossSalary(e.target.value)}
                aria-describedby="emailHelp"
                value={annualGrossSalary}
              />
            </div>
            <div class="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setPaygroup(e.target.value)}
                value={paygroup}
              >
                <option selected>Select Paygroup</option>
                {paygroupOptions}
              </select>
            </div>
            <div class="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => setProrateType(e.target.value)}
                value={prorateType}
              >
                <option selected>Select Prorate Type</option>
                {prorateDataType.map((type) => {
                  return (
                    <option value={type.code}>
                      {type.value ? type.value.replace("_", " ") : ""}
                    </option>
                  );
                })}
              </select>
            </div>
            {prorateType && (
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  {prorateType === "FLAT_AMOUNT"
                    ? "Prorate Amount"
                    : "Prorate Percentage (%)"}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  onChange={(e) => setProrateValue(e.target.value)}
                  aria-describedby="emailHelp"
                  value={prorateValue}
                />
              </div>
            )}
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Salary Bonus
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                onChange={(e) => setBonus(e.target.value)}
                aria-describedby="emailHelp"
                value={bonus}
              />
            </div>

            <div className="d-flex justify-content-end align-items-between">
              <button
                type="submit"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  marginTop: "1rem",
                  color: "white",
                }}
                onClick={saveProrate}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}
