import React, { useEffect, useState } from "react";
import menu from "../constants/navmenus";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useHistory } from "react-router-dom";
export default function Sidebar({ employeeChecklist }) {
  const location = useLocation();
  var userRole = sessionStorage.getItem("activeRole");
  const history = useHistory();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Logout",
      description: msg,
    });
  };

  const handleForceKyc = () => {
    Notification(
      "error",
      "Error",
      "Page is unavailable until your personal identity have been verified."
    );
  }



  return (
    <div>
      <div class="page-sidebar" style={{ overflowY: "auto" }}>
          <ul class="list-unstyled accordion-menu">
            {menu?.map((men) => {
              return (
                <>
                  <li
                    key={men.id}
                    class={men.path === location.pathname && `active-page`}
                    style={{ whiteSpace: "nowrap" }}>
                    <Link to={men.path}>
                      <img
                        src={men.icon}
                        alt=""
                        style={{ paddingRight: "1rem" }}
                        width="34"
                        className="home"
                      />{" "}
                      {men.name}{" "}
                    </Link>{" "}
                  </li>
                </>
              );
            })}
          </ul>
      </div>{" "}
    </div>
  );
}
