import React from "react";
import {Link} from "react-router-dom"
const userRoles = sessionStorage.getItem("activeRole");
export default function DropDown(props) {
  return (
    <>
      {userRoles !== "HUMAN_RESOURCE" && (
        <a class="dropdown-item" href="/company_information">
          <i data-feather="user"></i>Company Information
        </a>
      )}

      {userRoles?.length > 1 && userRoles === "HUMAN_RESOURCE" && (
        <a
          href={`/choose-profile?switch=true&currentRole=${userRoles}`}
          class="dropdown-item"
        >
          <i data-feather="user"></i>Switch Profile
        </a>
      )}

      <Link to="/account-beneficiaries" class="dropdown-item" h>
        <i data-feather="user"></i>Beneficiaries
      </Link>

      <Link to="/billing-methods" class="dropdown-item" h>
        <i data-feather="user"></i>Billing Methods
      </Link>

      {userRoles?.length > 1 && userRoles === "ACCOUNTANT" && (
        <a
          href={`/choose-profile?switch=true&currentRole=${userRoles}`}
          class="dropdown-item"
        >
          <i data-feather="user"></i>Switch Profile
        </a>
      )}

      {/* <a class='dropdown-item' href='/subscription'>
        <i data-feather='inbox'></i>Subscriptions
      </a> */}
      {userRoles !== "HUMAN_RESOURCE" && (
        <a class="dropdown-item" href="/accounts">
          <i data-feather="edit"></i>Accounts
        </a>
      )}
      <a class="dropdown-item" href="/manage-position">
        <i data-feather="edit"></i>Manage Positions
      </a>
      <a class="dropdown-item" href="/manage-department">
        <i data-feather="edit"></i>Manage Departments
      </a>
      <a class="dropdown-item" href="/paygroup">
        <i data-feather="edit"></i>Manage Pay Groups
      </a>
      <a class="dropdown-item" href="/manage_paylevel">
        <i data-feather="edit"></i>Manage Pay Levels
      </a>
      <a class="dropdown-item" href="/manage_payscale">
        <i data-feather="edit"></i>Manage Pay Scales
      </a>
    
     
      <a class="dropdown-item" href="/manage_paystructure">
        <i data-feather="edit"></i>Manage Pay Structures
      </a>
    
      
   

      <div class="dropdown-divider"></div>

      <a class="dropdown-item" href="/security">
        <i data-feather="unlock"></i>Security
      </a>
      <a class="dropdown-item" onClick={props.logout}>
        <i data-feather="log-out"></i>Logout
      </a>
    </>
  );
}
