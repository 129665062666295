import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Modal,
  notification,
  Pagination,
  Select,
} from "antd";
import axios from "axios";
import config from "../../../config";
const { Option } = Select;
export default function Employers() {
  const [adminData, setAdminData] = useState([]);
  const [adminCreate, setAdminCreate] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [GroupStatus, setGroupStatus] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [employerCountries, setEmployerCountries] = useState([]);
  const [countryList, setCountryLists] = useState("");
  const [countryChoices, setCountryChoices] = useState("");
  const [country, setCountry] = useState("");

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    sessionStorage.setItem("paygroup_id", record.id);
    setGroupName(record.groupName);
    setGroupStatus(record.status);
    setVisibleDraw(true);
    setCountry(record.country);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const closeAdd = () => {
    setAdminCreate(false);

    setGroupName("");
  };
  const deletePaygroup = () => {
    axios
      .delete(`${config.baseUrl}/v1/pay-groups/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setStatusMsg(res.data.message);
          getPaygroups(countryChoices[0].code);
          setVisibleDraw(false);
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2500);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function handleChange(country) {
    setSelectedCountry(country);
    const selectedCountry = employerCountries.find(
      (countryInfo) => countryInfo.code === country
    );
    setCountryChoices([
      {
        name: selectedCountry.name,
        code: selectedCountry.code,
      },
    ]);
    getPaygroups(country);
  }

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Do you want to delete this Paygroup?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePaygroup();
      },
      onCancel() {
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Do you want to change the status of this pay group?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
      },
    });
  };

  const updatePaystructureswitch = () => {
    setUpdateSwitch(true);
    setAdminCreate(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/pay-groups/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPaygroups(countryChoices[0].code);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getPaygroups(countryChoices[0].code);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createAdmin = () => {
    setAdminCreate(true);
    setUpdateSwitch(false);
    setGroupName("");
    setCountry("");
  };

  const columns = [
    {
      key: "groupName",
      text: "Group Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    
    {
      key: "date",
      text: "Date",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            <span
              className={
                {
                  ACTIVE: "badge bg-success",
                  COMPLETED: "badge bg-success",
                  SUCCESS: "badge bg-success",
                  FAILED: "badge bg-danger",
                  IDLE: "badge bg-info",
                  PENDING: "badge bg-warning",
                  PROCESSING: "badge bg-warning",
                  INACTIVE: "badge bg-danger",
                  Enabled: "badge bg-success",
                  Disabled: "badge bg-danger",
                }[record.status]
              }
            >
              {record.status}
            </span>
          </Fragment>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
    },
  ];
  const getPaygroups = (country) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-groups/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const adminData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setAdminData(
            adminData.map((data) => ({
              id: data.id,
              groupName: data.groupName,
              country: data.country,
              date: data.createdAt ? data.createdAt.slice(0, 10) : "",
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addPaygroup = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      groupName,
    };
    if (data.groupName) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pay-groups`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setGroupName("");
            Notification("success", "Success", res.data.message);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getPaygroups(countryChoices[0].code);
              setAdminCreate(false);
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const editPaygroup = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      groupName,
    };
    if (data.groupName) {
      setLoad(true);
      axios
        .put(`${config.baseUrl}/v1/pay-groups/${rowId}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setGroupName("");
            Notification("success", "Success", res.data.message);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getPaygroups(countryChoices[0].code);
              setAdminCreate(false);
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  useEffect(() => {
    getPaygroups();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getCountriesInfo = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const countryInfo = res.data.result.countries;
          setCountryChoices(countryInfo);
          setEmployerCountries(countryInfo);
          setCountryLists(countryInfo);
          getPaygroups(countryInfo[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${
      page - 1
    }&size=${pageSize}employerId=${sessionStorage.getItem("employer_id")}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pay-groups/paged?`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const adminData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setAdminData(
            adminData.map((data) => ({
              id: data.id,
              groupName: data.groupName,
              country: data.country,
              date: data.createdAt ? data.createdAt.slice(0, 10) : "",
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">
                    Manage Pay Groups{" "}
                   
                  </h5>
                </div>
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createAdmin}
                >
                  Add Pay Group
                </button>{" "}
              </div>

              <div className="">
                <div className="text-center"></div>
                <div style={{ overflowX: "auto" }} className="pb-4">
                  <ReactDatatable
                    config={configTable}
                    records={adminData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
                <div className="text-center pagination-part pt-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
      
        <hr />
        <div
          style={{ color: "#1FC157", cursor: "pointer" }}
          onClick={updatePaystructureswitch}
        >
          Update
        </div>

        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete
        </div>

        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={changeStatusConfirm}
        >
          {GroupStatus !== "Enabled" ? "Enable" : "Disable"}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add PayGroup" : "Update PayGroup"}
        visible={adminCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setGroupName(e.target.value)}
                    value={groupName}
                  />
                  <label for="floatingInput">Group Name</label>
                </div>
              </div>
           
            </div>
          ) : (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setGroupName(e.target.value)}
                    value={groupName}
                  />
                  <label for="floatingInput">Group Name</label>
                </div>
              </div>
             
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addPaygroup : editPaygroup}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
