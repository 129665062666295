import React, { useState, useEffect } from "react";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { notification, Spin, Modal } from "antd";
import { Alert } from "antd";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Apple from "../../../assets/apple.svg"
import Android from "../../../assets/android.svg"
import { useHistory } from "react-router-dom";
export default function EmployeeInfo({
  setTabs,
  inviteRef,
  pfaData,
  bankLists,
  gotBackDefaultPayment,
  preloadData,
  workEmail,
}) {
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [load1, setLoad1] = useState(false);
  const [msgResponse, setMsgResponse] = useState("");
  const [load2, setLoad2] = useState(false);
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [firstname, setFirstName] = useState("");
  const history = useHistory();
  const [lastname, setLastName] = useState("");
  const [loadEmployeeInfo, setLoadEmployee] = useState(false);
  const [othername, setOthername] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mmanualAddress, setMnaualAddress] = useState("");
  const [mmanualAddressNok, setMnaualAddressNok] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [pfa, setPfa] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [locationinfo, setLocationInfo] = useState("");
  const [invitationStatus, setInvitationStatus] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [department, setDepartment] = useState("");
  const [taxState, setTaxState] = useState("");
  const [taxData, setTaxData] = useState([]);
  const [employeeType, setEmployeeType] = useState("");
  const [position, setPosition] = useState("");
  const [pfaRsaPin, setRsaPin] = useState("");
  const [taxId, setTaxId] = useState("");
  const [nextOfKin, setNextOfkin] = useState("");
  const [nextOfKinPhone, setNextOfkinPhone] = useState("");
  const [level, setLevel] = useState("");
  const [nextOfKinAddress, setNextOfkinAddress] = useState("");
  const [loadToken, setLoadToken] = useState(false);

  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
      getTaxStates(e.target.value);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const actualPassword = watch("password");

  const getTaxStates = (country) => {
    axios
      .get(`${config.baseUrl}/v1/options/TAX_STATES/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTaxData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  const [loadingEmployeeType, employeeTypeData] = useHttp(
    `${config.baseUrl}/v1/options/EMPLOYEE_TYPE`,
    []
  );

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "mobile":
          setMobile(truncatedValue);
          break;
        case "nokPhone":
          setNextOfkinPhone(truncatedValue);
          break;
        case "pfaPin":
          setRsaPin(truncatedValue);
          break;

        case "taxId":
          setTaxId(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const getOtp = (e) => {
    e.preventDefault();
    setLoadToken(true);
    axios
      .post(
        `${config.baseUrl}/v1/onboarding-link/send-otp?onboardingLinkId=${inviteRef}`
      )
      .then((res) => {
        setLoadToken(false);
        if (res.data.status) {
          openNotificationWithIcon("success", res.data.message);
        } else {
          openNotificationWithIcon("error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setLoadToken(false);
          openNotificationWithIcon("error", "Something went wrong, Try Again");
        }
      });
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Login",
      description: msg,
    });
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const getBankName = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.label) {
      return bank?.label;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.label;
    }
  };

  const getBankCode = (id) => {
    var bank = bankLists?.find((x) => x.value === id);
    if (bank?.value) {
      return bank?.value;
    } else {
      var bankCheck = bankLists?.find((x) => x.label === id);
      return bankCheck?.value;
    }
  };

  const getpfaName = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.label) {
      return pfa?.label;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.label;
    }
  };

  const getPfaCode = (id) => {
    var pfa = pfaData?.find((x) => x.value === id);
    if (pfa?.value) {
      return pfa?.value;
    } else {
      var pfaChk = pfaData?.find((x) => x.label === id);
      return pfaChk?.value;
    }
  };

  const login = (data) => {
    var paymentinfodata = sessionStorage.getItem("paymentInfo");
    var personalinfodata = sessionStorage.getItem("personalInfo");
    var personalInfo = JSON?.parse(personalinfodata);
    var paymentInfo = JSON?.parse(paymentinfodata);

    const datar = {
      accountIdentifier: paymentInfo?.accountNum,
      accountName: paymentInfo?.accountName,
      confirmPassword: data?.cpassword,
      contactAddress: personalInfo?.address,
      country: personalInfo?.country,
      dateOfBirth: personalInfo?.dob,
      personalEmail: personalInfo?.personalEmail,
      firstName: personalInfo?.firstName,
      gender: personalInfo?.gender,
      institutionBranch: "",
      institutionCode: getBankCode(paymentInfo?.bankCode),
      institutionName: getBankName(paymentInfo?.bankCode),
      lastName: personalInfo?.lastName,
      maritalStatus:
        personalInfo?.maritalStatus === "Select"
          ? ""
          : personalInfo?.maritalStatus,
      nextOfKin: personalInfo?.nok,
      nextOfKinAddress: personalInfo?.addressKin?.label
        ? personalInfo?.addressKin?.label
        : personalInfo?.nokA,
      nextOfKinPhone: personalInfo?.nokPhone,
      otherName: personalInfo?.otherName,
      otp: data?.token,
      password: data?.password,
      paymentCountry: paymentInfo?.paymentCountry,
      paymentCurrency: paymentInfo?.currencyCode,
      paymentOption: paymentInfo?.paymentFunc,
      pfaCode: paymentInfo?.pfaCode ?
        paymentInfo?.pfaCode === "Select" ? "" : getPfaCode(paymentInfo?.pfa) : "",
      pfaName: paymentInfo?.pfaName ?
        paymentInfo.pfaName === "Select" ? "" : getpfaName(paymentInfo?.pfa) : "",
      pfaRsaPin: paymentInfo?.pfaPin,
      phoneNumber: personalInfo?.mobile,
      tin: paymentInfo?.taxid,
      taxState: paymentInfo?.taxState === "Select" ? "" : paymentInfo?.taxState,
    };
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/onboarding-link/accept-invite?onboardingLinkId=${inviteRef}`,
        datar,
        {}
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setOpenSuccessModal(true);
          setMsgResponse(res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <>
      <form>
        <div className="text-center">{loadToken && <Spin />}</div>
        <Alert
          description={
            "The email and password provided below will allow you to log in to the SalarioPay self-service mobile app, where you can access your payslip, receive payments, pay bills, and more."
          }
          type="success"
        />
        <br />
        <div className="row">
          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Work Email
              </label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                value={workEmail}
                disabled
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div className="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Password
              </label>
              <div
                className=" d-block w-100 d-flex align-items-center justify-content-between border"
                style={{ borderRadius: "10px" }}
              >
                <div
                  className=""
                  style={{
                    width: "90%",
                  }}
                >
                  <input
                    type={!eye ? "password" : "text"}
                    class="form-control"
                    maxLength="64"
                    style={{ border: 0 }}
                    {...register("password", {
                      required: "Password  is required!",
                      pattern: {
                        value:
                          /^^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/i,
                        message:
                          "Invalid Password Format, must contain 1 Capital letters, number, and special character",
                      },
                    })}
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-center h-100 pointer"
                  onClick={() => seteye(!eye)}
                  style={{
                    width: "10%",
                  }}
                >
                  {!eye ? <BsEye /> : <BsEyeSlash />}
                </div>
              </div>

              {errors.password && (
                <span className="validate-error">
                  {errors.password.message}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div class="col-sm-12 col-md-6">
            <div className="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Confirm Password
              </label>
              <div
                className=" d-block w-100 d-flex align-items-center justify-content-between border"
                style={{ borderRadius: "10px" }}
              >
                <div
                  className=""
                  style={{
                    width: "90%",
                  }}
                >
                  <input
                    type={!eye2 ? "password" : "text"}
                    class="form-control"
                    maxLength="64"
                    style={{ border: 0 }}
                    {...register("cpassword", {
                      required: "Confirm Password  is required!",
                      validate: (value) =>
                        value === actualPassword ||
                        "The passwords do not match",
                    })}
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-center h-100 pointer"
                  onClick={() => seteye2(!eye2)}
                  style={{
                    width: "10%",
                  }}
                >
                  {!eye2 ? <BsEye /> : <BsEyeSlash />}
                </div>
              </div>
              {errors.cpassword && (
                <span className="validate-error">
                  {errors.cpassword.message}
                </span>
              )}
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <label for="exampleInputEmail1" class="form-label">
                  OTP
                </label>
                <div>
                  <div>
                    <span
                      type="submit"
                      className="font-weight-bold xx-btn"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={getOtp}
                    >
                      <b>GET OTP </b>
                    </span>
                  </div>
                </div>
              </div>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                {...register("token", {
                  required: "OTP is required!",
                })}
              />
              {errors.token && (
                <span className="validate-error">{errors.token.message}</span>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <button
            type="submit"
            class="btn btn-primary"
            style={{
              background: "#1FC157",
              marginTop: "1rem",
              color: "white",
            }}
            onClick={() => {
              gotBackDefaultPayment();
              setTabs("2");
            }}
          >
            Previous
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            style={{
              background: "#1FC157",
              marginTop: "1rem",
              color: "white",
            }}
            onClick={handleSubmit(login)}
          >
            {load ? (
              <ThreeDots
                visible={load}
                height="20"
                width="50"
                color="#ffffff"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>

      <Modal
        title={""}
        visible={openSuccessModal}
        footer={false}
        maskClosable={false}
      >


        <Alert message={msgResponse} type="success" />
        <hr />
        <div className="d-flex justify-content-between align-items-center ">
          <a
            href="https://play.google.com/store/apps/details?id=com.mishtranzacts.salariopay&hl=en"
            target="_blank"
          >
            <div>
              <img src={Android} />
            </div>
          </a>

          <a
            href="https://apps.apple.com/us/app/salariopay-personal/id6738658996"
            target="_blank"
          >
            <div>
              <img src={Apple} />
            </div>
          </a>

        </div>
      </Modal>
    </>
  );
}
