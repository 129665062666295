import React, { useState, useEffect } from "react";
import Tabs from "../tab";
import Layout from "../../containers/Layout";
import { useHttp } from "../../hooks/useHttps";
import profileDefault from "../../img/profile-image.png";
import axios from "axios";
import { Spin, notification, Select } from "antd";
import config from "../../config";
const { Option } = Select;
export default function CompanyInfo(props) {
  const [loading, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  //contact details
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [position, setPosition] = useState("");

  const [otherName, setOthertName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [dob, setDob] = useState("");
  const [identityType, setIdentityType] = useState("");
  const [personalIdentity, setPersonalIdentity] = useState("");
  const [pvcState, setPVCState] = useState("");
  const [residentialAddress, setAddress] = useState("");
  const [residentialCity, setCity] = useState("");
  const [residentialPostalCode, setPostal] = useState("");

  //business info
  const [countries, setCountries] = useState([]);
  const [penComCode, setPenComCode] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [bisName, setBisName] = useState("");
  const [sector, setSector] = useState("");
  const [size, setSize] = useState("");
  const [businessIdentityType, setBusinessIdentityType] = useState("");
  const [businessIdentity, setBusinessIdentity] = useState("");
  const [website, setWebsite] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [email, setEmail] = useState("");
  const [residentialState, setState] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [ownersip, setOwnership] = useState("");
  const [rcnum, setRcNum] = useState("");
  const [taxid, setTaxID] = useState("");
  const [nhf, setNhf] = useState("");
  const [contactInformation, setContactInfo] = useState([]);
  const [fiscalMonth, setFiscalMonth] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessPostalCode, setBusinessPostal] = useState("");

  //logo
  const [logo, setLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState(null);

  //cac && tin
  const [cacDoc, setCacDoc] = useState("");
  const [tinDoc, setTinDoc] = useState("");

  //loaders
  const [loadBusiness, setLoadBusiness] = useState(false);
  const [loadUpload, setLoadUpload] = useState(false);

  useEffect(() => {
    getContactDetails();
  }, []);

  const countryLists = [];
  countries.map((country) => {
    countryLists.push(country.name);
  });

  const countrySelect = [];
  countryDatas.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });

  const handleChangeCountries = (value) => {
    setCountryOptions(value);
  };

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactInformation = res.data.result;
          setContactInfo(contactInformation);
          setPhoneNumber(contactInformation.phoneNumber);
          setFirstName(contactInformation.firstName);
          setLastName(contactInformation.lastName);
          setTitle(contactInformation.title);
          setPenComCode(contactInformation.penComCode);
          setBusinessAddress(contactInformation.businessAddress);
          setPosition(contactInformation.position);
          setBisName(contactInformation.businessName);
          setSector(contactInformation.industry);
          setPostal(contactInformation?.postalCode)
          setAddress(contactInformation?.contactAddress)
          setSize(contactInformation.size);
          setCountries(contactInformation.countries);
          setWebsite(contactInformation.website);
          setIdentityType(
            contactInformation.personalKycType
              ? contactInformation.personalKycType.replace("_", " ")
              : ""
          );
          setSupportEmail(contactInformation.supportEmail);
          setEmail(contactInformation.email);
          setFiscalMonth(contactInformation.fiscalMonth);
          setBusinessCity(contactInformation?.businessCity)
          setPersonalIdentity(contactInformation.personalIdentity);
          setOthertName(contactInformation.middleName);
          setMaritalStatus(contactInformation.maritalStatus);
          setBusinessState(contactInformation?.businessState)
          setBusinessPostal(contactInformation?.businessPostalCode)
          setDob(contactInformation.dateOfBirth);
          setState(contactInformation.contactState);
          setPostal(contactInformation?.contactPostalCode)
          setCity(contactInformation.contactCity);
          setGender(contactInformation.gender);
          setRegisterType(contactInformation.registerType);
          setOwnership(contactInformation.ownership);
          setRcNum(contactInformation.rcNumber);
          setBusinessIdentityType(
            contactInformation.businessKycType
              ? contactInformation.businessKycType.replace("_", " ")
              : ""
          );
          setBusinessIdentity(contactInformation.businessIdentity);
          setTaxID(contactInformation.taxId);
          setNhf(contactInformation.nhfNumber);
          setDisplayLogo(contactInformation.logoUrl);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handlecontactInformationUpdate = (e) => {
    e.preventDefault();
    const data = {
      dateOfBirth: dob,
      gender,
      maritalStatus,
      phoneNumber,
      position,
      gender,
      maritalStatus,
      middleName: otherName,
      title,
      userId: props.userID,
      website: null,
    };
    axios
      .put(
        `${config.baseUrl}/v1/employers/contact-detail/${sessionStorage.getItem(
          "employer_id"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactInformation = res.data.result;
          setFirstName(contactInformation.firstName);
          setLastName(contactInformation.lastName);
          getContactDetails();
          setTitle(contactInformation.title);
          setPosition(contactInformation.position);
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => { });
  };

  const handleBusinessDetailsUpdate = (e) => {
    e.preventDefault();
    const data = {
      "dateOfBirth": "string",
      "fiscalMonth": fiscalMonth,
      "gender": gender,
      "maritalStatus": maritalStatus,
      "middleName": otherName,
      "nhfNumber": nhf,
      "ownership": ownersip,
      "penComCode": penComCode,
      "phoneNumber": phoneNumber,
      "position": position,
      "registerType": registerType,
      "size": size,
      "supportEmail": supportEmail,
      "taxId": taxid,
      "title": title,
      "website": website
    };
    setLoadBusiness(true);
    axios
      .put(
        `${config.baseUrl
        }/v1/employers/${sessionStorage.getItem('employer_id')}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadBusiness(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          const contactInformation = res.data.result;
          setBisName(contactInformation.businessName);
          setSector(contactInformation.industry);
          setSize(contactInformation.size);
          setWebsite(contactInformation.website);
          setSupportEmail(contactInformation.supportEmail);
          setEmail(contactInformation.email);
          setFiscalMonth(contactInformation.fiscalMonth);
          setState(contactInformation.state);
          getContactDetails();
          setCity(contactInformation.city);
          setRegisterType(contactInformation.registerType);
          setOwnership(contactInformation.ownersip);
          setRcNum(contactInformation.rcnum);
          setTaxID(contactInformation.taxid);
          setNhf(contactInformation.nhf);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadBusiness(false);
      });
  };

  const handleFileUpdate = (e) => {
    e.preventDefault();
    if (logo) {
      let data = new FormData();
      data.append("file", logo);
      setLoadUpload(true);
      axios
        .put(
          `${config.baseUrl}/v1/employers/logo/${sessionStorage.getItem(
            "employer_id"
          )}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadUpload(false);
          if (res.data.status) {
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);

            Notification("success", "Success", res.data.message);
            getContactDetails();
            setDisplayLogo(res.data.result.logoUrl);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadUpload(false);
        });
    } else {
      Notification("error", "Error", "Company Logo is required to be uploaded");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
    } else {
      setLogo(null);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <Layout>
      <Tabs />
      {contactInformation.length !== 0 ? (
        <div>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="text-center pt-3">
                <img
                  src={displayLogo ? displayLogo : profileDefault}
                  alt=""
                  width="100"
                />
              </div>
              <div class="card-header font-weight-bold">
                <b>Personal Details</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tilte
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setTitle(e.target.value)}
                          disabled
                          value={title}
                        >
                          <option selected>Select</option>
                          <option>Dr</option>
                          <option>Miss</option>
                          <option>Mr</option>
                          <option>Mrs</option>
                          <option>Ms</option>
                          <option>Prof</option>
                          <option>Rev</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Position
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPosition(e.target.value)}
                          value={position}
                          disabled
                        >
                          <option selected>Select</option>
                          <option>Bursar</option>
                          <option>Chief Administrative Officer</option>
                          <option>Chief Executive Officer</option>
                          <option>Chief Financial Officer</option>
                          <option>Chief Information Officer</option>
                          <option>Chief Operating Officer</option>
                          <option> Chief Risk Officer</option>
                          <option> Chief Technology Officer</option>
                          <option> Chief of Staff Director</option>
                          <option> General Manager</option>
                          <option> Managing Director</option>
                          <option> President</option>
                          <option> Registrar</option>
                          <option> Secretary</option>
                          <option> Vice Chancellor</option>
                          <option> Vice President</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={firstName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={lastName}
                          disabled
                          onChange={(e) => setLastName(e.target.value)}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Other Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setOthertName(e.target.value)}
                          disabled
                          value={otherName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInpdutEmail1" class="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                          value={email}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={dob}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Gender
                        </label>

                        <div>
                          <select
                            class="form-select"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            aria-label="Default select example"
                            disabled
                          >
                            <option selected>Select</option>
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {identityType && personalIdentity && (
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Identity Type
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={identityType}
                            disabled
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {identityType}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={personalIdentity}
                            disabled
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          disabled
                          value={phoneNumber}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Marital Status
                        </label>

                        <div>
                          <select
                            class="form-select"
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            aria-label="Default select example"
                            disabled
                            value={maritalStatus}
                          >
                            <option selected>Select</option>
                            <option>Single</option>
                            <option>Married</option>
                            <option>Divorced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {identityType === "VIN" && (
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            State of PVC Enrollment
                          </label>

                          <div>
                            <select
                              class="form-select"
                              onChange={(e) => setPVCState(e.target.value)}
                              aria-label="Default select example"
                              disabled
                              value={pvcState}
                            >
                              <option selected>Select</option>
                              {config.stateLists.map((state) => {
                                return <option>{state}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={residentialAddress}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential City
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={residentialCity}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential State
                        </label>

                        <div>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            value={residentialState}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential Postal Code
                        </label>

                        <div>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            value={residentialPostalCode}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={handlecontactInformationUpdate}
                  >
                    {loadBusiness ? (
                      <div class="spinner-border text-white" role="status">
                        <span class="sr-only">Updating...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Bussiness Details</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={(e) => setBisName(e.target.value)}
                          value={bisName}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Industry
                        </label>
                        <select
                          class="form-select"
                          disabled
                          aria-label="Default select example"
                          value={sector}
                          onChange={(e) => setSector(e.target.value)}
                        >
                          <option selected>Select</option>
                          <option>Agriculture</option>
                          <option>Commerce</option>
                          <option>Education</option>
                          <option> Financial Services</option>
                          <option>Gaming</option>
                          <option>Hospitality</option>
                          <option> Health </option>
                          <option> Leisure & Entertainment</option>
                          <option> Logistics</option>
                          <option> Non-profits</option>
                          <option> Travel</option>
                          <option> Utilities</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Size
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                          disabled
                        >
                          <option selected>Select</option>
                          <option>1 - 30 employees</option>
                          <option>31 - 100 employee</option>
                          <option>101 - 300 employee</option>
                          <option> 300 - 1000 employee</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          onChange={(e) => setWebsite(e.target.value)}
                          value={website}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Support Email
                        </label>
                        <input
                          type="email"
                          disabled
                          value={supportEmail}
                          class="form-control"
                          onChange={(e) => setSupportEmail(e.target.value)}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Start of fiscal year
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={fiscalMonth}
                          onChange={(e) => setFiscalMonth(e.target.value)}
                        >
                          <option selected>Select Month</option>
                          {config.fiscalMonths.map((month) => {
                            return <option>{month}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Register Type
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={registerType}
                          disabled
                          onChange={(e) => setRegisterType(e.target.value)}
                        >
                          <option selected>Select</option>
                          <option>Incorporated Trustees</option>
                          <option>Incorporated Company</option>
                          <option>Business Name Registration</option>
                          <option> Free Zone Entity</option>
                          <option> Government Entity</option>
                          <option> Private Entity</option>
                          <option> Cooperative Society</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Ownership
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={ownersip}
                          disabled
                          onChange={(e) => setOwnership(e.target.value)}
                        >
                          <option selected>Select</option>
                          <option>Privately Held(2017 - 2020)</option>
                          <option>Public (2017 - 2020)</option>
                          <option>
                            {" "}
                            Privately Held(December 2020 to date)
                          </option>
                          <option> Public (December 2020 to date)</option>
                          <option> Privately Held (Pre 2017)</option>
                          <option> Public (Pre 2017)</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {businessIdentityType && businessIdentity && (
                    <div className="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Business Identity Type
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={businessIdentityType}
                            disabled={
                              contactInformation.businessKycType ? true : false
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {businessIdentityType}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={businessIdentity}
                            disabled={
                              contactInformation.businessIdentity ? true : false
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tax ID
                        </label>
                        <input
                          type="text"
                          value={taxid}
                          class="form-control"
                          id="exampleInputEmail1"
                          onChange={(e) => setTaxID(e.target.value)}
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          NHF Number
                        </label>
                        <input
                          type="text"
                          value={nhf}
                          onChange={(e) => setNhf(e.target.value)}
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      {countries.length > 0 && (
                        <div class="mb-3">
                          <label class="form-label">Countries</label>
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select"
                            onChange={handleChangeCountries}
                            defaultValue={countryLists}
                          >
                            {countrySelect}
                          </Select>
                        </div>
                      )}
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Pencom Code
                        </label>
                        <input
                          type="text"
                          value={penComCode}
                         
                          class="form-control"
                          id="exampleInputEmail1"
                          onChange={(e) => setPenComCode(e.target.value)}
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessAddress}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business City
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessCity}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business State
                        </label>

                        <div>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            value={businessState}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Postal Code
                        </label>

                        <div>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            value={businessPostalCode}
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={handleBusinessDetailsUpdate}
                  >
                    {loadBusiness ? (
                      <div class="spinner-border text-white" role="status">
                        <span class="sr-only">Updating...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Company Logo</b>
              </div>
              <div class="card-body">
                <form>
                  <div class="mb-3">
                    <label for="formFileSm" class="form-label">
                      Upload Company Logo
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSm"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </div>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={handleFileUpdate}
                  >
                    {loadUpload ? (
                      <div class="spinner-border text-white" role="status">
                        <span class="sr-only">Updating...</span>
                      </div>
                    ) : (
                      "Upload Logo"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>

          {/* {!contactInformation.cacUrl && (
            <div className="col-sm-12 col-md-12">
              <div class="card">
                <div class="card-header font-weight-bold">
                  <b>Corporate Affairs Comission Certificate (CAC)</b>
                </div>
                <div class="card-body">
                  <form>
                    <div class="mb-3">
                      <label for="formFileSm" class="form-label">
                        Upload CAC Document
                      </label>
                      <input
                        class="form-control form-control-sm"
                        id="formFileSm"
                        type="file"
                        onChange={handleFileChangeCac}
                      />
                    </div>

                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={handleFileUpdateCac}
                    >
                      {loadUploadCac ? (
                        <div class="spinner-border text-white" role="status">
                          <span class="sr-only">Updating...</span>
                        </div>
                      ) : (
                        "Upload CAC"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

          {!contactInformation.tinUrl && (
            <div className="col-sm-12 col-md-12">
              <div class="card">
                <div class="card-header font-weight-bold">
                  <b>Tax Identification Number Certificate</b>
                </div>
                <div class="card-body">
                  <form>
                    <div class="mb-3">
                      <label for="formFileSm" class="form-label">
                        Upload Document
                      </label>
                      <input
                        class="form-control form-control-sm"
                        id="formFileSm"
                        type="file"
                        onChange={handleFileChangeTin}
                      />
                    </div>

                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={handleFileUpdateTin}
                    >
                      {loadUploadTin ? (
                        <div class="spinner-border text-white" role="status">
                          <span class="sr-only">Updating...</span>
                        </div>
                      ) : (
                        "Upload "
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )} */}
        </div>
      ) : (
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
