import React, { useState, useEffect } from "react";
import Tabs from "./action-tabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../../hooks/useHttps";
import config from "../../../../config";
import { Modal, Drawer, notification, Spin, Select } from "antd";
import { Pagination, Button } from "antd";
import EmployeeDetails from "./view_employee"
import SalaryDetails from "./salary_detail"
import Allowances from "./allowance"
import Deductions from "./decution"
import SearchInput from "./search-component"
import PaymentMethods from "./payment-method"
import PayrollDetails from "./payroll_details"
import { Link } from "react-router-dom"
import EmployeeOffBoarding from "./employee_off_onboarding"
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ThreeDots } from "react-loader-spinner";

export default function EmployeeInfo(props) {
    const [LIST, setList] = useState([])
    const [page, setPage] = useState(1)
    const [totalItems, setTotalitems] = useState("")
    const [statusFilter, setStatusFilter] = useState("active")
    const [totalElements, setTotalElements] = useState(0)
    const history = useHistory()
    const [loadingFilter, setLoadingFilter] = useState(false)
    const defaultEmployee = props?.location?.state?.id ? props?.location?.state?.id : ""
    const currentMode = props?.location?.state?.type ? props?.location?.state?.type : ""
    const currentPage = props?.location?.state?.page ? props?.location?.state?.page : 1;

    const [employeeList, setEmployeeList] = useState([])
    const [load, setLoad] = useState(false)
    const [generate, setGenerate] = useState(false)
    console.log(employeeList)
    const location = useLocation();
    const [currentState, setCurrentState] = useState(currentMode)


    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>;
        }
        if (type === 'next') {
            return <a>Next</a>;
        }
        return originalElement;
    };


    const pagination = (page, pageSize) => {
        setPage(page);
        const queryString = `/paged?page=${page - 1}&size=10&employerId=${sessionStorage.getItem(
            "employer_id"
        )}&status=${statusFilter}`;
        getEmployeePaged(queryString);
    };


    const getEmployeePaged = (query) => {
        setLoad(true)
        axios
            .get(
                `${config.baseUrl
                }/v1/employees${query}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setLoad(false)
                const employeDatas = res.data.result;
                setList(employeDatas)
                setTotalitems(res.data.totalPages * 10);
                setTotalElements(res.data.totalElements)
                setEmployeeList(employeDatas)
                setGenerate(defaultEmployee)

            })
            .catch((err) => {
                setLoad(false)
                if (err) {
                }
            });
    };

    const getEmployee = () => {
        setLoad(true)
        setPage(currentPage)
        axios
            .get(
                `${config.baseUrl
                }/v1/employees/paged?page=${currentPage - 1}&size=10&employerId=${sessionStorage.getItem(
                    "employer_id"
                )}&status=${statusFilter}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setLoad(false)
                const employeDatas = res.data.result;
                setList(employeDatas)
                setTotalElements(res.data.totalElements)
                setTotalitems(res.data.totalPages * 10);
                setEmployeeList(employeDatas)
                setGenerate(defaultEmployee)

            })
            .catch((err) => {
                setLoad(false)
                if (err) {
                }
            });
    };
    const renderSwitch = (param) => {
        switch (currentState) {
            case 'view-employee':
                return <EmployeeDetails generate={generate} getEmployee={getEmployee} />;
            case 'allowances':
                return <Allowances generate={generate} getEmployee={getEmployee} />;
            case 'deductions':
                return <Deductions generate={generate} getEmployee={getEmployee} />;
            case 'salary-detail':
                return <SalaryDetails generate={generate} getEmployee={getEmployee} />;
            case 'payment-methods':
                return <PaymentMethods generate={generate} getEmployee={getEmployee} />;
            case 'payroll-details':
                return <PayrollDetails generate={generate} getEmployee={getEmployee} />;
            case 'employee-offboarding':
                return <EmployeeOffBoarding generate={generate} getEmployee={getEmployee} />;
            default:
                return <EmployeeDetails generate={generate} getEmployee={getEmployee} />;
        }
    }

    const handleEmployeeFilter = (e) => {
        var searchTerm = e.target.value;
        if (searchTerm) {
            searchEmployee(searchTerm)
        }



    }

    const getUpperCaseInitials = (fullName) => {
        const names = fullName.trim().split(" ");
        if (names.length < 2) return "";

        const firstInitial = names[0][0].toUpperCase();
        const lastInitial = names[names.length - 1][0].toUpperCase();

        return firstInitial + lastInitial;
    }

    const searchEmployee = (val) => {
        setLoadingFilter(true)
        axios
            .get(
                `${config.baseUrl
                }/v1/employees/paged?page=0&size=10&employerId=${sessionStorage.getItem(
                    "employer_id"
                )}&search=${val}`,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    },
                }
            )
            .then((res) => {
                setLoadingFilter(false)
                const employeDatas = res.data.result;
                setList(employeDatas)
                setTotalitems(res.data.totalPages * 10);
                setTotalElements(res.data.totalElements)
                setEmployeeList(employeDatas)
                setGenerate(defaultEmployee)


            })
    }


    useEffect(() => {
        getEmployee()
    }, [statusFilter])




    const selectEmployee = (record) => {
        sessionStorage.setItem("employeeID", record);
        setGenerate(record)
    }



    return (
        <Layout>
            <div className="row position-relative">
                <div className="col-md-3 " >
                    <div className="card py-4 employee-list-sidebar" style={{ overflowY: 'auto' }}>
                        {!load &&
                            <div className="">
                                <div className="px-3 ">
                                    <div className="pb-3">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div style={{ cursor: 'pointer' }} onClick={() => history?.push('/employee_onboarding')}><i className="fa fa-chevron-left"></i> Back</div>
                                            <div>
                                                <Select
                                                    defaultValue="Status "
                                                    value={statusFilter}
                                                    style={{
                                                        width: 100,
                                                    }}
                                                    onChange={(val) => {
                                                        setStatusFilter(val);
                                                    }}
                                                    options={[
                                                        {
                                                            value: "active",
                                                            label: "Active ",
                                                        },
                                                        {
                                                            value: "inactive",
                                                            label: "Inactive ",
                                                        },
                                                        {
                                                            value: "",
                                                            label: "All ",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div>
                                            <input
                                                type="text"
                                                onChange={handleEmployeeFilter}
                                                className="form-control w-100"
                                                id="exampleInputEmail1"
                                                placeholder="Search Employee"
                                                aria-describedby="emailHelp"
                                            />
                                        </div>
                                        {loadingFilter &&
                                            <div className="position-relative">
                                                <div className="search-loader-split">
                                                    <ThreeDots
                                                        visible={loadingFilter}
                                                        height="20"
                                                        width="40"
                                                        color="#1fc157"
                                                        radius="9"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                    />
                                                </div>
                                            </div>}
                                    </div>
                                    {employeeList?.length > 0 ?
                                        <div className=""   >


                                            {employeeList?.map((d) => {
                                                return (
                                                    <div className="py-3 w-100" style={{ background: d?.id === generate ? '#F5F5F5' : '', padding: d?.id === generate ? '5px 10px' : "5px 10px", borderRadius: d?.id === generate ? "6px" : 0 }}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <div className="d-flex just align-items-center" style={{ cursor: 'pointer' }} onClick={selectEmployee.bind(this, d?.id)}>
                                                                    <div className="name-circle mr-2">{getUpperCaseInitials(d?.firstName + " " + d?.lastName)}</div>
                                                                    <div className="pl-2">
                                                                        <div>
                                                                            {d?.firstName?.toUpperCase() + " " + d?.lastName?.toUpperCase()}
                                                                        </div>
                                                                        <div className="">
                                                                            <div style={{ fontSize: '12px' }}>
                                                                                {d?.employeeCode}
                                                                            </div>

                                                                        </div>
                                                                        <div style={{ fontSize: '12px' }}>
                                                                            {d?.employeeType}
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginLeft: 'auto' }} className="pt-4" >
                                                                <div style={{ fontSize: '12px', marginLeft: 'auto' }}>
                                                                    {d.monthlyNetSalary
                                                                        ? d.salaryCurrency +
                                                                        " " +
                                                                        Intl.NumberFormat("en-US").format(d.monthlyNetSalary)
                                                                        : ""}
                                                                </div>
                                                                <div style={{ fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }} className="text-right">
                                                                    <span
                                                                        className={
                                                                            {
                                                                                ACTIVE: "badge bg-success",
                                                                                INACTIVE: "badge bg-danger",

                                                                            }[d.status ? "ACTIVE" : "INACTIVE"]
                                                                        }
                                                                    >
                                                                        {d.status ? "ACTIVE" : "INACTIVE"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                )
                                            })}
                                            <br />

                                            {employeeList?.length < 10 &&
                                                <div className="position-absolute" style={{ bottom: '50px' }}>
                                                    <div className="px-3 d-flex justify-content-center align-items-center" >
                                                        <Pagination current={page} size={'small'} total={totalItems} onChange={pagination} align={'center'} itemRender={itemRender} showSizeChanger={false} showTotal={false} showTitle={false} />
                                                    </div>
                                                </div>}

                                            {employeeList?.length > 9 &&
                                                <div >
                                                    <div className="px-3 d-flex justify-content-center align-items-center" >
                                                        <Pagination current={page} size={'small'} total={totalItems} onChange={pagination} align={'center'} itemRender={itemRender} showSizeChanger={false} showTotal={false} showTitle={false} />
                                                    </div>
                                                </div>}

                                        </div> :

                                        <div>
                                            <br />
                                            <div>No Employees Available
                                            </div>
                                        </div>}


                                </div>
                                <br />

                            </div>}
                        {load &&
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <Spin />
                            </div>}
                    </div>

                </div>
                <div className="col-md-9">
                    <Tabs currentState={currentState} setState={setCurrentState} />
                    {renderSwitch()}


                </div>
            </div>

        </Layout>
    );
}
