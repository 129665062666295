import React, { useState, useEffect, useRef } from "react";
import Layout from "../../containers/Layout";
import Employer from "./employer";
import Employee from "./employee";
import SuperAdmin from "./superadmin";
import Vendor from "./vendor";
import SelfieCapture from "../../camera/index";
import { useHistory } from "react-router-dom";
import Location from "../../containers/Location/index";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Cookies from "js-cookie"
import {
  Modal,
  Checkbox,
  Alert,
  Button,
  Spin,
  DatePicker,
  notification,
} from "antd";
import axios from "axios";
import config from "../../config";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import OtpInput from "react-otp-input";
import { useHttp } from "../../hooks/useHttps";
const role = sessionStorage.getItem("activeRole");
sessionStorage.getItem("activeRole");
export default function Dashboard() {
  const [loadingCountries, employeecountryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const [locationInfo, setLocationInfo] = useState({});
  const [loadUploadSelfie, setUploadSelfie] = useState(false);
  const locationB = window.location.pathname.replace("/", "");
  const searchName = window.location.search;
  const modalRef = useRef(null);
  const [pinModal, setPinModal] = useState(false);
  const [selfieFormData, setSelfieFormData] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [residentialState, setResidentialState] = useState("");
  const [openSelfieCapture, setOpenSelfieCapture] = useState(false);
  const [registeredBusiness, setRegisteredBusiness] = useState(false)
  const [residentialAddress, setResidentialAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("")
  const [residentialCity, setResidentialCity] = useState("");
  const [residentialPostalCode, setResidentialPostalCode] = useState("");
  const [radioYes, setRadioYes] = useState("Yes");
  const [radioNo, setRadioNo] = useState(false);
  const [residentialAddressManual, setResidentialManaulAddress] =
    useState(false);
  const [buusinesslAddressManual, setBusinesssManaulAddress] = useState(false);
  const [businessCity, setBusinessCity] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [defaultPay, setDefaultPay] = useState(true);
  const [selfie, setSelfie] = useState(null);
  const [businessState, setBusinessState] = useState("");
  const [businessPostal, setBusinessPostalCode] = useState("");
  const [loadUploadFront, setUploadFront] = useState(false);
  const [loadUploadBack, setUploadBack] = useState(false);
  const [loadUploadCert, setUploadCert] = useState(false);
  const [loadUploadUtility, setUploadUtility] = useState(false);
  const history = useHistory();

  const defaultChange = (e) => {
    if (e.target?.checked) {
      setDefaultPay(true);
    } else {
      setDefaultPay(false);
    }
  };

  var urlParams = new URLSearchParams(window.location.search);
  const typeMode = urlParams.get("mode");

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [frontLogo, setGovtFront] = useState("");
  const [backLogo, setBackLogo] = useState("");
  const [certificateDoc, setCertDoc] = useState("");
  const [utilityDoc, setutilityDoc] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const [pin, setPin] = useState("");

  const [kycMode, setKycMode] = useState("");
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [userChecklists, setUserChecklist] = useState({});
  const [openPersonalKyc, setOpenPersonalKyc] = useState(false);
  const [fiscalMonth, setFiscalMonth] = useState("");
  const [employerChecklist, setEmployerChecklist] = useState(false);
  const [employeeChecklist, setEmployeeChecklist] = useState(false);
  const [vendorChecklist, setVendorChecklist] = useState(false);
  const [load, setLoad] = useState(false);
  const [fill, setFill] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [businessIdentity, setBusinessIdentity] = useState("");
  const [country, setCountry] = useState("");
  const [countryBusiness, setCountryBusiness] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [otherName, setOthertName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [dob, setDob] = useState("");
  const [dobReg, setDobReg] = useState("");
  const [businessIdentityDetails, setBusinessIdentityDetails] = useState("");
  const [identityType, setIdentityType] = useState([
    {
      name: "Bank Verification Number (BVN)",
      type: "BVN",
    },
    {
      name: "National Identification Number (NIN)",
      type: "NIN",
    },
  ]);
  let defaultVerificationList = [];
  const [businessdefaultVerificationList, setBusinessDefaultVerificationList] =
    useState([]);
  const [businessIdentityType, setBusinessIdentityType] = useState([]);
  const [confirmationResult, setConfirmationResult] = useState({});
  const [lookUpKycModal, setOpenLookUpKycModal] = useState(false);
  const [selectedIdentityType, setSelectedIdentity] = useState("");
  const [selectedBusinessdentityType, setSelectedBusinessIdentityType] =
    useState("");

  const [businessName, setBusinessName] = useState("");
  const [industry, setIndustry] = useState("");
  const [size, setSize] = useState("");
  const [website, setWebsite] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [ownership, setOwnership] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [steps, setSteps] = useState("personal");
  const cancelEmployerSetup = () => {
    setEmployerChecklist(false);
  };

  const [loadingGender, genderData] = useHttp(
    `${config.baseUrl}/v1/options/GENDER`,
    []
  );

  const [loadingMarital, maritalData] = useHttp(
    `${config.baseUrl}/v1/options/MARITAL_STATUS`,
    []
  );

  const closeLookupModal = () => {
    setOpenLookUpKycModal(false);
  };

  const convertToBlob = (base64, contentType = 'application/octet-stream', sliceSize = 512) => {
    // Remove data URI prefix if present
    const cleanBase64 = base64.includes(',') ? base64.split(',')[1] : base64;

    try {
      const byteCharacters = atob(cleanBase64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      // Ensure `type` is correctly set
      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      console.error("Error decoding base64:", error);
      return null;
    }
  };

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLocationInfo({
          city: "",
          longitude: "",
          clearAddress: "",
          lga: "",
          state: "",
          accuracy: "",
          postalcode: "",
        });
        setBusinessPostalCode("");
        setBusinessCity("");
        setBusinessAddress("");
        setBusinessState("");
        if (res.data.status) {
          const contactDetails = res.data.result;
          setContactDetails(contactDetails);

          setSelectedIdentity("");
          setBusinessIdentity("");
          setBusinessIdentityDetails("");
          setSelectedBusinessIdentityType("");
          setValue("fname", contactDetails?.firstName);
          setValue("lname", contactDetails?.lastName);
          setValue("dob", contactDetails?.dateOfBirth);
          setValue("gender", contactDetails?.gender);
          setValue("identity1Personal1", contactDetails?.lastName);
          setValue("identity1Personal2", contactDetails?.lastName);
          setValue("maritalStatus", contactDetails?.maritalStatus);
          setValue("myposition", contactDetails?.position);
          setValue("mytitle", contactDetails?.title);
          setValue("residentialCity", contactDetails?.residentialCity);
          setValue("residentialCountry", contactDetails?.residentialCountry);
          setValue("residentialState", contactDetails?.residentialState);
          setValue("residentialState", contactDetails?.residentialState);
          setValue("fileFront", contactDetails?.govtIdFrontUrl);
          setValue(
            "residentialPostalCode",
            contactDetails?.residentialPostalCode
          );
          getPersonalKyc(contactDetails.kycDetails?.kycCountry);
          getBusinessKyc(contactDetails.kycDetails?.kycCountry);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const getPersonalKyc = (country) => {
    setCountry(country);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/personalKycTypes?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setIdentityType(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getBusinessKyc = (e) => {
    setCountryBusiness(e.target?.value);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/businessKycTypes?country=${e?.target?.value}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setBusinessIdentityType(res.data.result);
        } else {
          setBusinessIdentityType([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const uploadSelfie = () => {
    setUploadSelfie(true);

    // Create URL encoded form data
    const formData = new URLSearchParams();
    formData.append("base64", selfie);

    axios
      .put(
        `${config.baseUrl}/v1/users/upload-base64-document/SELFIE_PHOTO`,
        formData, // Pass the encoded form data
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setUploadSelfie(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setUploadSelfie(false);
        setSelfie(null)
        console.error("Upload error:", err);
        Notification("error", "Error", "An error occurred while uploading.");
      });
  };
  const onChange = (date, dateString) => {
    setDob(dateString);
    setValue("dob", date);
    setValue("dobPersonal", date);
  };

  const onChangeDateOfReg = (date, dateString) => {
    setDobReg(dateString);
  };

  const cancelEmployeeSetup = () => {
    setEmployeeChecklist(false);
  };

  const cancelSetKyc = () => {
    setKycModal(false);
  };

  const cancelVendorSetup = () => {
    setVendorChecklist(false);
  };

  useEffect(() => {
    if (lookUpKycModal) {
      setKycModal(false);
    }
  }, [lookUpKycModal]);

  useEffect(() => {
    if (steps === "business") {
      window.scrollTo(0, 0);
    }
  }, [steps]);

  useEffect(() => {
    getChecklists();
    getCounties();
    if (role === "EMPLOYER") {
      getContactDetails();
      setValue("titlePersonal", "value");
      setValue("gendePersonal", "value");
      setValue("maritalPersonal", "value");
      setValue("personalGovtFront", "value");
      setValue("personalUtility", "value");
      setValue("identity1", "value");
      setValue("country", "value");
      setValue("identity2", "value");
      setValue("personalAddress", "");
      setValue("personalCity", "");
      setValue("personalState", "");
      setValue("personalPostalCode", "");
      setValue("dobPersonal", "value");
      setValue("personalPostalCode", "value");
      setValue("personalCity", "value");
      setValue("personalAddress", "value");
      setValue("personalState", "value");
      setValue("firstNamepersonal", "value");
      setValue("lastNamepersonal", "value");


    } else {
      getUserInfo();
      setValue("fname", "value");
      setValue("lname", "value");
      setValue("dob", "value");
      setValue("gender", "value");
      setValue("identity1Personal1", "value");
      setValue("support", "salariopay@gmail.com");
      setValue("identity1Personal2", "value");
      setValue("maritalStatus", "value");
      setValue("myposition", "value");
      setValue("mytitle", "value");
      setValue("residentialCity", "value");
      setValue("residentialCountry", "value");
      setValue("residentialState", "value");
      setValue("residentialState", "value");
      setValue("fileFront", "value");
      setValue("residentialPostalCode", "value");
      setValue("residentialAddress", "value");

      setValue("businessCity", "value");
      setValue("businessCountry", "value");
      setValue("dob", "value");
      setValue("website", "value");
      setValue("registerType", "value");
      setValue("size", "value");
      setValue("ownership", "value");
      setValue("industry", "value");
      setValue("fiscalMonth", "value");
      setValue("certDoc", "value");
      setValue("firstName", "value");
      setValue("businessName", "value");
      setValue("businessPostal", "value");
      setValue("businessState", "value");
      setValue("businessIdentityType", "value");
    }
  }, [role]);

  const cancelPinSetup = () => {
    setPinModal(false);
    setPin("");
    setConfirmPin("");
  };
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const handleChangePin = (pin) => setPin(pin);
  const handleChangePinConfirm = (pin) => setConfirmPin(pin);

  const personalDefaultVerifyEmployee = (action) => {
    setLoad(true);
    const data = {
      residentialAddress: residentialAddress,
      residentialState: residentialState,
      residentialCity: residentialCity,
      otherName: otherName,
      title: title,
      position: position,
      residentialPostalCode: residentialPostalCode,
      gender: gender,
      maritalStatus: maritalStatus,
      residentialCountry: country,
      defaultPaymentMethod: defaultPay,
      identities:
        action === "2" ? dynamicIdentityList() : defaultVerificationList,
    };

    axios
      .post(`${config.baseUrl}/v1/kyc-details/verifyPersonalDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getChecklists();
          setLocationInfo({
            city: "",
            longitude: "",
            clearAddress: "",
            lga: "",
            state: "",
            accuracy: "",
            postalcode: "",
          });
          setBusinessPostalCode("");
          setBusinessCity("");
          setBusinessAddress("");
          setBusinessState("");
          setResidentialAddress("");
          setResidentialState("");
          setResidentialCity("");
          defaultVerificationList = [];
          setResidentialPostalCode("");
          setOpenPersonalKyc(false);
          setResidentialAddress("");
          setOpenLookUpKycModal(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const scrollToTop = () => {
    setTimeout(() => {
      modalRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 500); // Adjust the delay as necessary
  };

  const personalDefaultVerify = (action) => {
    setLoad(true);
    const data = {
      residentialAddress: residentialAddress,
      residentialState: residentialState,
      residentialCity: residentialCity,
      otherName: otherName,
      registeredBusiness: radioYes === "Yes" ? true : false,
      title: title,
      position: position,
      residentialPostalCode: residentialPostalCode,
      gender: gender,
      residentialCountry: country,
      defaultPaymentMethod: defaultPay,
      maritalStatus: maritalStatus,
      identities:
        action === "2" ? dynamicIdentityList() : defaultVerificationList,
    };
    axios
      .post(`${config.baseUrl}/v1/kyc-details/verifyPersonalDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setOpenPersonalKyc(false);
          getChecklists()
          setLocationInfo({
            city: "",
            longitude: "",
            clearAddress: "",
            lga: "",
            state: "",
            accuracy: "",
            postalcode: "",
          });
          setBusinessPostalCode("");
          setBusinessCity("");
          setBusinessAddress("");
          setBusinessState("");
          setResidentialAddress("");
          setResidentialState("");
          setResidentialCity("");
          defaultVerificationList = [];
          setResidentialPostalCode("");

          setSteps("business");


          scrollToTop();
          getContactDetails();
          setOpenLookUpKycModal(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          for (let error in err?.response?.data?.errors) {
            Notification("error", "Error", err?.response?.data?.errors[error]);
          }
        }
      });
  };

  const dynamicIdentityList = () => {
    var arr = [];
    for (var i = 0; i < defaultVerificationList?.length; i++) {
      arr?.push({
        identityNumber: defaultVerificationList[i].identityNumber,
        identityType: defaultVerificationList[i]?.identityType,
        kycId: defaultVerificationList[i]?.kycId,
        verifyType: defaultVerificationList[i]?.verifyType,
        verifyValue: confirmationResult.verifyBy === "OTP" ? pin : dobReg,
      });
    }
    return arr;
  };

  const dynamicIdentityListBusiness = () => {
    var arr = [];
    for (var i = 0; i < businessdefaultVerificationList?.length; i++) {
      arr?.push({
        identityNumber: businessdefaultVerificationList[i].identityNumber,
        identityType: businessdefaultVerificationList[i]?.kycType,
        kycId: businessdefaultVerificationList[i]?.id,
        verifyType: businessdefaultVerificationList[i]?.verifyType,
        verifyValue: confirmationResult.verifyType === "OTP" ? pin : dobReg,
      });
    }
    var arrayBiz = arr?.filter((d) => d?.identityType === "CAC");
    return arrayBiz;
  };

  const businessDefaultVerify = (action) => {
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/kyc-details/verifyBusinessDetail`,
        {
          businessAddress: businessAddress,
          businessPostalCode: businessPostal,
          fiscalMonth: fiscalMonth,
          identities:
            action === "2"
              ? dynamicIdentityListBusiness()
              : defaultVerificationList?.filter(
                (d) => d?.identityType === "CAC"
              ),
          industry: industry,
          ownerShip: ownership,
          registerType: registerType,
          size: size,
          supportEmail: supportEmail,
          website: website,
          businessCity: businessCity,
          businessState: businessState,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getChecklists();
          setSteps("business");
          setResidentialAddress("");
          setLocationInfo({});
          setBusinessCity("");
          setBusinessState("");
          setTimeout(() => {
            if (typeMode === 'kyc') {
              setKycModal(true)
              history?.push("/dashboard")
            }
          }, 1200)

          setBusinessPostalCode("");
          setResidentialAddress("");
          setResidentialState("");
          setResidentialCity("");
          defaultVerificationList = [];
          setResidentialPostalCode("");
          getContactDetails();
          setOpenLookUpKycModal(false);
          setOpenPersonalKyc(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          for (let error in err?.response?.data?.errors) {
            Notification("error", "Error", err?.response?.data?.errors[error]);
          }
        }
      });
  };

  const confirmPersonal = (data) => {
    axios
      .post(`${config.baseUrl}/v1/kyc-details/lookUpKycDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSelfie("");
          if (role !== "EMPLOYER") {
            Notification("success", "Success", res.data.message);
          }
          for (var i = 0; i < res.data.result?.length; i++) {
            defaultVerificationList.push({
              kycId: res.data.result[i]?.id,
              identityNumber: res.data.result[i]?.identityNumber,
              identityType: res.data?.result[i]?.kycType,
              verifyType: res.data.result[i].verifyType,
              firstName: res.data.result[i]?.firstName,
              lastName: res.data.result[i].lastName,
              verifyValue: "",
            });
          }
          if (res.data.result?.length > 0) {
            getDefaultVerificationListByNone(res.data.result);
          }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const getDefaultVerificationListByNone = (arr) => {
    var filterTypeVerifyTypeNone = arr?.filter((x) => x?.verifyType === "NONE");
    if (filterTypeVerifyTypeNone?.length > 0) {
      if (steps === "business") {
        businessDefaultVerify("1");
      } else {
        if (kycMode === "employee") {
          personalDefaultVerifyEmployee("1");
        } else personalDefaultVerify("1");
      }
    } else {
      setBusinessDefaultVerificationList(arr);
      setOpenLookUpKycModal(true);
      setConfirmationResult(arr[0]);
    }
  };

  const getUserInfo = () => {
    axios
      .get(`${config.baseUrl}/v1/users`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setUserInfo(res.data.result);
          setFirstName(res.data.result.firstName);
          setLastName(res.data.result.lastName);
          setOthertName(res.data?.result?.otherName);
          setValue("firstNamepersonal", res.data.result.firstName)
          setValue("lastNamepersonal", res.data.result.lastName)

        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const confirmBusiness = (data) => {
    axios
      .post(`${config.baseUrl}/v1/kyc-details/lookUpKycDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          for (var i = 0; i < res.data.result?.length; i++) {
            defaultVerificationList.push({
              kycId: res.data.result[i]?.id,
              identityNumber: res.data.result[i]?.identityNumber,
              identityType: res.data?.result[i]?.kycType,
              verifyType: res.data.result[i].verifyType,
              businessName: res.data.result[i].businessName,
            });
          }
          if (res.data.result?.length > 0) {
            getDefaultVerificationListByNone(res.data.result);
          }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const handleInputChange = (fieldName) => (e) => {
    const inputValue = e.target.value;
    const lettersOnly = inputValue.replace(/[^A-Za-z]/g, "");
    const lettersOnlyandSpace = inputValue.replace(/^(?! )[A-Za-z\s]*$/g, "");
    const numbersOnly = inputValue.match(/\d+/g);

    let withoutSpecials = inputValue.replace(/[^\w\s]/g, "");

    switch (fieldName) {
      case "firstName":
        setFirstName(lettersOnly);
        break;
      case "lastName":
        setLastName(lettersOnly);
        break;
      case "otherName":
        setOthertName(lettersOnly);
        break;
      case "residentialCity":
        setResidentialCity(withoutSpecials);
        break;
      case "residentialState":
        setResidentialState(withoutSpecials);
        break;
      case "businessName":
        setBusinessName(withoutSpecials);
        break;
      case "businessCity":
        setBusinessCity(withoutSpecials);
        break;
      case "businessState":
        setBusinessState(withoutSpecials);
        break;
      case "residentialpostalCode":
        setResidentialPostalCode(withoutSpecials);
      case "businesspostalCode":
        setBusinessPostalCode(withoutSpecials);

      default:
        break;
    }
  };

  const confirmPersonalKyc = () => {
    if (!selfie) {
      Notification("error", "Error", "Selfie capture is required");
      return;
    }
    const data = {
      companyName: null,
      country,
      dateOfBirth: dob,
      firstName: firstName,
      otherName: otherName,
      identities: [
        {
          identityNumber: identity1,
          identityType: identityType[0]?.type,
        },
        {
          identityNumber: identity2,
          identityType: identityType[1]?.type,
        },
      ],
      lastName: lastName,
      state: null,
      type: selectedIdentityType,
    };

    setLoad(true);
    setKycMode("employee");
    confirmPersonal(data);
  };

  console.log(errors)
  const getConfirmation = (typeKyc, e) => {
    if (typeKyc === "personal") {
      if (!selfie) {
        Notification("error", "Error", "Selfie capture is required");
        return;
      }
    }
    const dataPersonal = {
      country,
      dateOfBirth: dob,
      firstName,
      identities: [
        {
          identityNumber: identity1,
          identityType: identityType[0]?.type,
        },
        {
          identityNumber: identity2,
          identityType: identityType[1]?.type,
        },
      ],
      lastName,
      otherName: otherName,
    };
    const dataBusiness = {
      businessName: businessName,
      country: countryBusiness,
      identities: [
        {
          identityNumber: registeredBusiness ? businessIdentity
            ? businessIdentity?.match(/\d+/g)[0]
            : "" : "000000",
          identityType: !registeredBusiness ? "CAC" : selectedBusinessdentityType,
        },
      ],
    };
    defaultVerificationList = [];
    if (typeKyc === "personal") {
      setLoad(true);
      confirmPersonal(dataPersonal);
    } else {
      setLoad(true);
      confirmBusiness(dataBusiness);
    }
  };

  const closeEmployeePersonal = () => {
    setOpenPersonalKyc(false);
  };

  const getChecklists = () => {
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSteps(res.data.result.contactVerified ? "business" : "personal");
          setRegisteredBusiness(res.data.result?.registeredBusiness)
          if (res.data.result.contactVerified) {
            setValue("firstName", "value");
            setValue("utilityDoc", "value");

          }
          setLocationInfo({
            city: "",
            longitude: "",
            clearAddress: "",
            lga: "",
            state: "",
            accuracy: "",
            postalcode: "",
          });
          setBusinessPostalCode("");
          setBusinessCity("");
          setSelfie("");
          setBusinessAddress("");
          setBusinessState("");
          setResidentialAddress("");
          setResidentialState("");
          setResidentialCity("");
          setUserChecklist(res.data.result);
          if (
            res.data.result.contactVerified &&
            res.data.result.businessVerified
          ) {
            setKycModal(false);
          }
          if (role === "EMPLOYEE") {
            if (
              !res.data.result.pinEnabled ||
              !res.data.result.paymentMethodEnabled ||
              !res.data.result.userContactVerified
            ) {
              setEmployeeChecklist(true);
              return;
            }
          }
          if (role === "EMPLOYER") {
            if (
              res.data.result.pinEnabled &&
              res.data.result.billEnabled &&
              res.data.result.employeeOnboard &&
              res.data.result?.contactVerified &&
              res.data.result?.businessVerified
            ) {
              setEmployerChecklist(false);
            } else {
              if (typeMode === "kyc") {
                setEmployerChecklist(false);
                setKycModal(true);
              } else {
                setEmployerChecklist(true);
              }
            }
          }
          if (role === "VENDOR") {
            if (
              !res.data.result.pinEnabled ||
              !res.data.result.paymentMethodEnabled ||
              !(
                res.data.result?.contactVerified &&
                res.data.result?.businessVerified
              )
            ) {
              setVendorChecklist(true);
              return;
            }
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const createPin = () => {
    setPinModal(true);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Pin",
      description: msg,
    });
  };

  const setUpPin = () => {
    const data = {
      confirmPin,
      pin,
    };
    if (data.confirmPin && data.pin) {
      setLoad(true);
      if (data.confirmPin === data.pin) {
        axios
          .post(`${config.baseUrl}/v1/users/set-pin`, data, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setLoad(false);
            if (res.data.status) {
              openNotificationWithIcon("success", res.data.message);
              setTimeout(() => {
                setPinModal(false);
                getChecklists();
              }, 2500);
            } else {
              openNotificationWithIcon("error", res.data.message);
            }
          })
          .catch((err) => {
            setLoad(false);
          });
      } else {
        setLoad(false);
      }
    } else {
      setFill(true);
      setTimeout(() => {
        setFill(false);
      }, 2000);
    }
  };

  const backToPersonalKyc = () => {
    setSteps("personal");
  };

  const handleFileChangeFront = (e) => {
    if (e.target.files[0]) {
      setGovtFront(e.target.files[0]);
      setValue("fileFront", e.target?.value);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "GOVT_ID_CARD_FRONT");
      } else {
        onUploadDocEmployee(e.target.files[0], "GOVT_ID_CARD_FRONT");
      }
    }
    else {
      setValue("fileFront", "")
      setGovtFront(null)
    }

  };

  const handleFileChangeBack = (e) => {
    if (e.target.files[0]) {
      setBackLogo(e.target.files[0]);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "GOVT_ID_CARD_BACK");
      } else {
        onUploadDocEmployee(e.target.files[0], "GOVT_ID_CARD_BACK");
      }
    }
  };

  const handleFileChangeUtility = (e) => {
    if (e.target.files[0]) {
      setutilityDoc(e.target.files[0]);
      setValue("utilityDoc", e.target?.value);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "UTILITY_BILL");
      } else {
        onUploadDocEmployee(e.target.files[0], "UTILITY_BILL");
      }
    } else {
      setutilityDoc(null);
      setValue("utilityDoc", "");
    }
  };

  const handleFileChangeCertificate = (e) => {
    if (e.target.files[0]) {
      setValue("certDoc", e.target?.value);
      setCertDoc(e.target.files[0]);
      onUploadDoc(e.target.files[0], "CERTIFICATE_OF_INCORPORATION");
    } else {
      setCertDoc(null);
      setValue("certDoc", "");
    }
  };

  const onUploadDocEmployee = (typeDoc, type) => {
    const data = new FormData();
    data.append("file", typeDoc);
    if (type === "GOVT_ID_CARD_FRONT") {
      setUploadFront(true);
    } else if (type === "GOVT_ID_CARD_BACK") {
      setUploadBack(true);
    } else if (type === "CERTIFICATE_OF_INCORPORATION") {
      setUploadCert(true);
    } else if (type === "UTILITY_BILL") {
      setUploadUtility(true);
    }
    axios
      .put(`${config.baseUrl}/v1/users/upload-document/${type}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);

        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          clearUploadValidations(type, typeDoc)
        } else {
          Notification("error", "Error", res.data.message);
          reValidateDocumentsEmployee(type)
        }
      })
      .catch((err) => {
        reValidateDocumentsEmployee(type)
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);
      });
  };

  const logoutnow = () => {
    axios
      .get(`${config.baseUrl}/v1/auth/logout`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          sessionStorage.setItem("employer_id", "");
          sessionStorage.setItem("userId", "");
          sessionStorage.setItem("token", "");
          sessionStorage.getItem("employeeID", "");
          sessionStorage.setItem("logOutRole", role);
          sessionStorage.setItem("activeWindowState", locationB + searchName);
          openNotificationWithIcon("success", res.data.message);
          setTimeout(() => {
            Cookies.remove("salarioPayUserInfo");
            history.replace("/login");
          }, 1500);
        } else {
          openNotificationWithIcon(res.message);
        }
      })
      .catch((err) => {
        if (err) {
          Cookies.remove("salarioPayUserInfo");
          history.replace("/login");
        }
      });
  }

  const reValidateDocumentsEmployee = (type) => {
    switch (type) {
      case "GOVT_ID_CARD_FRONT":
        setValue("personalGovtFront", "")
        break;


      case "UTILITY_BILL":
        setValue("personalUtility", "")
        break;

      default:
    }
  }

  const reValidateDocuments = (type) => {
    switch (type) {
      case "GOVT_ID_CARD_FRONT":
        setValue("fileFront", "")
        break;
      case "GOVT_ID_CARD_BACK":
        setValue("fileBack", "")
        break;
      case "CERTIFICATE_OF_INCORPORATION":
        setValue("certDoc", "")
        break;
      case "UTILITY_BILL":
        setValue("utilityDoc", "")
        break;

      default:
    }
  }

  const clearUploadValidations = (type, document) => {
    switch (type) {
      case "GOVT_ID_CARD_FRONT":
        setValue("fileFront", document)
        break;
      case "GOVT_ID_CARD_BACK":
        setValue("fileBack", document)
        break;
      case "CERTIFICATE_OF_INCORPORATION":
        setValue("certDoc", document)
        break;
      case "UTILITY_BILL":
        setValue("utilityDoc", document)
        break;

      default:
        break;
    }
  }

  const onUploadDoc = (typeDoc, type) => {
    const data = new FormData();
    data.append("file", typeDoc);
    if (type === "GOVT_ID_CARD_FRONT") {
      setUploadFront(true);
    } else if (type === "GOVT_ID_CARD_BACK") {
      setUploadBack(true);
    } else if (type === "CERTIFICATE_OF_INCORPORATION") {
      setUploadCert(true);
    } else if (type === "UTILITY_BILL") {
      setUploadUtility(true);
    }
    axios
      .put(`${config.baseUrl}/v1/users/upload-document/${type}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);

        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          clearUploadValidations(type, typeDoc)

        } else {
          Notification("error", "Error", res.data.message);
          reValidateDocuments(type)
        }
      })
      .catch((err) => {
        setLoad(false);
        reValidateDocuments(type)
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);
      });
  };

  const handleChangeNumber = (type, maxLength, event) => {
    const inputValue = event?.target?.value;
    if (isValidNumber(inputValue)) {
      // Truncate the input value if it exceeds the maximum length
      const truncatedValue = inputValue?.slice(0, maxLength);
      switch (type) {
        case "bvn":
          setIdentity1(truncatedValue);
          break;
        case "nin":
          setIdentity2(truncatedValue);
          break;

        default:
          break;
      }
    }
  };

  const isValidNumber = (input) => {
    // Use regex to check if the input value is a valid number
    return /^\d*$/.test(input); // Allow only digits (0-9)
  };

  const setBusinessKycIdentityType = (e) => {
    setSelectedBusinessIdentityType(e.target.value);
    const getIdentityTypeDetails = businessIdentityType.find(
      (identity) => identity.type === e.target.value
    );
    setBusinessIdentityDetails(getIdentityTypeDetails.name);
  };

  const completeInformation = () => {
    setKycModal(true);
  };

  var userRole = sessionStorage.getItem("activeRole");



  return (
    <Layout>
      {userRole && (
        <div>
          {
            {
              EMPLOYER: <Employer />,
              EMPLOYEE: <Employee />,
              ACCOUNTANT: <Employer />,
              HUMAN_RESOURCE: <Employer />,
              SUPER_ADMIN: <SuperAdmin />,
              VENDOR: <Vendor />,
            }[userRole]
          }
          <Modal
            title="Create Pin"
            visible={pinModal}
            okText="Skip"
            footer={null}
            maskClosable={false}
            onCancel={cancelPinSetup}
          >
            <div className="container text-center">
              {" "}
              {load && (
                <p className="text-center">
                  <Spin />
                </p>
              )}{" "}
              {fill && (
                <div className="pb-4">
                  <Alert
                    message="Pin and Confirm Pin are required!"
                    type="error"
                    showIcon
                  />
                </div>
              )}{" "}
              <div className="text-center">
                <div>
                  <label className="text-left pt-4"> Pin </label>{" "}
                </div>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <OtpInput
                    value={pin}
                    onChange={handleChangePin}
                    isInputNum
                    isInputSecure
                    inputStyle={{
                      padding: "15px",
                      border: ".9px solid green",
                      width: "50px",
                    }}
                    numInputs={4}
                    separator={<span>-</span>}
                  />
                </div>
                <div>
                  <label className="text-left pt-4"> Confirm Pin </label>{" "}
                </div>
                <div className="mt-4 d-flex justify-content-center align-items-center">
                  <OtpInput
                    value={confirmPin}
                    onChange={handleChangePinConfirm}
                    isInputNum
                    isInputSecure
                    inputStyle={{
                      padding: "15px",
                      border: ".9px solid green",
                      width: "50px",
                    }}
                    numInputs={4}
                    separator={<span>-</span>}
                  />
                </div>
              </div>{" "}
              <div />
              <br />
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={setUpPin}
                >
                  Submit{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </Modal>
          <Modal
            title=""
            visible={employerChecklist}
            okText="Skip"
            footer={false}
            maskClosable={false}
            width={600}
            onCancel={cancelEmployerSetup}
            onOk={cancelEmployerSetup}
          >
            {role === "EMPLOYER" && (
              <div className="container">
                <h4 className="text-center" style={{ color: "#1FC157" }}>
                  Welcome to SalarioPay!
                </h4>{" "}
                {/* <p style={{ color: "#1FC157" }}>
                  Are you ready to experience the next generation payroll for
                  your bussiness{" "}
                </p>{" "} */}
                <hr />
                <div>
                  <h5 className="text-center pt-3"> Getting Started Steps </h5>{" "}
                  <p className="text-center">
                    {" "}
                    To get started, please ensure you complete all the steps in
                    the checklist below.
                  </p>{" "}
                </div>
                <div className="check-area pt-2">
                  <div className="d-flex justify-content-between align-items-center  mb-3">
                    <Checkbox
                      checked={
                        userChecklists?.contactVerified &&
                          userChecklists?.businessVerified
                          ? true
                          : false
                      }
                    >
                      Verify your Personal & Business Identity{" "}
                    </Checkbox>{" "}
                    <Button
                      onClick={completeInformation}
                      disabled={
                        userChecklists?.contactVerified &&
                          userChecklists?.businessVerified
                          ? true
                          : false
                      }
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Verify{" "}
                    </Button>{" "}
                  </div>
                  <div className="d-flex justify-content-between align-items-center  mb-3">
                    <Checkbox
                      checked={
                        userChecklists.pinEnabled
                          ? userChecklists.pinEnabled
                          : ""
                      }
                    >
                      Set Your Transaction Pin{" "}
                    </Checkbox>{" "}
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                      onClick={() => {
                        if (userChecklists?.kycApproved) {
                          createPin();
                        } else {
                          Notification(
                            "error",
                            "Error",
                            "PIN setup is unavailable until your personal and business identities have been verified."
                          );
                        }
                      }}
                    >
                      Set Pin{" "}
                    </Button>{" "}
                  </div>{" "}
                  <div className="d-flex justify-content-between align-items-center  mb-3">
                    <Checkbox
                      checked={
                        userChecklists.billEnabled
                          ? userChecklists.billEnabled
                          : ""
                      }
                    >
                      Add funds to your account to process payroll and benefits{" "}
                    </Checkbox>{" "}
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                      onClick={() => {
                        if (userChecklists?.kycApproved) {
                          history.push("/accounts");
                        } else {
                          Notification(
                            "error",
                            "Error",
                            "Add Fund is unavailable until your personal and business identities have been verified."
                          );
                        }
                      }}
                    >
                      Add{" "}
                    </Button>{" "}
                  </div>{" "}
                  <div className="d-flex justify-content-between align-items-center  mb-3">
                    <Checkbox checked={userChecklists.employeeOnboard}>
                      Add employees to generate payroll{" "}
                    </Checkbox>
                    <Button
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                      onClick={() => {
                        if (userChecklists?.kycApproved) {
                          history.push("/employee_onboarding");
                        } else {
                          Notification(
                            "error",
                            "Error",
                            "Payroll generation is unavailable until your personal and business identities have been verified."
                          );
                        }
                      }}
                    >
                      Add{" "}
                    </Button>{" "}
                  </div>{" "}
                  <br />
                  <hr />
                  <div className="d-flex justify-content-end align-items-center">
                    <Button
                      onClick={cancelEmployerSetup}
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "75px",
                      }}
                      size="small"
                    >
                      Skip{" "}
                    </Button>{" "}
                  </div>
                </div>{" "}
              </div>
            )}{" "}
          </Modal>
          <Modal
            title=""
            visible={employeeChecklist}
            okText="Skip"
            maskClosable={false}
            footer={false}
            width={600}

          >
            {role === "EMPLOYEE" && (
              <div className="container">
                <div className="check-area pt-2">
                  {" "}
                  {role === "EMPLOYEE" && (
                    <div className="container">
                      <h4 className="text-center" style={{ color: "#1FC157" }}>
                        Welcome to SalarioPay!
                      </h4>
                      <hr />
                      <div>
                        <h5 className="text-center pt-3">
                          {" "}
                          Getting Started Steps{" "}
                        </h5>{" "}
                        <p className="text-center">
                          Please complete the steps below to get started{" "}
                        </p>{" "}
                      </div>
                      <div className="check-area pt-2">
                        <div className="d-flex justify-content-between align-items-center  mb-3">
                          <Checkbox
                            checked={
                              userChecklists.userContactVerified
                                ? userChecklists.userContactVerified
                                : ""
                            }
                          >
                            Verify Your Identity{" "}
                          </Checkbox>{" "}
                          <Button
                            style={{
                              background: "#1FC157",
                              color: "white",
                              width: "75px",
                            }}
                            size="small"
                            onClick={() => {
                              setOpenPersonalKyc(true);
                            }}
                            disabled={
                              userChecklists.userContactVerified ? true : false
                            }
                          >
                            Verify{" "}
                          </Button>{" "}
                        </div>{" "}
                        <div className="d-flex justify-content-between align-items-center  mb-3">
                          <Checkbox
                            checked={
                              userChecklists.pinEnabled
                                ? userChecklists.pinEnabled
                                : ""
                            }
                          >
                            Set Your Transaction Pin{" "}
                          </Checkbox>{" "}
                          <Button
                            style={{
                              background: "#1FC157",
                              color: "white",
                              width: "75px",
                            }}
                            size="small"
                            onClick={() => {
                              if (userChecklists?.kycApproved) {
                                createPin();
                              } else {
                                Notification(
                                  "error",
                                  "Error",
                                  "Pin setup is unavailable until your identity has been verified."
                                );
                              }
                            }}
                          >
                            Set Pin{" "}
                          </Button>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="d-flex justify-content-between align-items-center  mb-3">
                        <Checkbox
                          checked={
                            userChecklists.paymentMethodEnabled
                              ? userChecklists.paymentMethodEnabled
                              : ""
                          }
                        >
                          Add Payment Method{" "}
                        </Checkbox>{" "}
                        <Link to="/payment_method">
                          <Button
                            style={{
                              background: "#1FC157",
                              color: "white",
                              width: "75px",
                            }}
                            size="small"
                          >
                            Add{" "}
                          </Button>{" "}
                        </Link>{" "}
                      </div>{" "}
                      <br />

                    </div>
                  )}{" "}
                </div>{" "}
                <hr />
                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={() => {
                      setEmployeeChecklist(false)
                    }}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "75px",
                    }}
                    size="small"
                  >
                    Skip{" "}
                  </Button>{" "}
                </div>
              </div>
            )}{" "}
          </Modal>
          <Modal
            title=""
            visible={vendorChecklist}
            okText="Skip"
            maskClosable={false}
            width={600}
            onCancel={cancelVendorSetup}
            onOk={cancelVendorSetup}
          >
            {role === "VENDOR" && (
              <div className="container">
                <div className="check-area pt-2">
                  {" "}
                  {role === "VENDOR" && (
                    <div className="container">
                      <h4 className="text-center" style={{ color: "#1FC157" }}>
                        Welcome to SalarioPay!
                      </h4>{" "}
                      <hr />
                      <div>
                        <h5 className="text-center pt-3">
                          {" "}
                          Getting Started Steps{" "}
                        </h5>{" "}
                        <p className="text-center">
                          Please complete the steps below to get started{" "}
                        </p>{" "}
                      </div>
                      <div className="check-area pt-2">
                        <div className="d-flex justify-content-between align-items-center  mb-3">
                          <Checkbox
                            checked={
                              userChecklists.pinEnabled
                                ? userChecklists.pinEnabled
                                : ""
                            }
                          >
                            Set Your Transaction Pin{" "}
                          </Checkbox>{" "}
                          <Button
                            style={{
                              background: "#1FC157",
                              color: "white",
                              width: "75px",
                            }}
                            size="small"
                            onClick={createPin}
                          >
                            Create{" "}
                          </Button>{" "}
                        </div>{" "}
                        <div className="d-flex justify-content-between align-items-center  mb-3">
                          <Checkbox
                            checked={
                              userChecklists.paymentMethodEnabled
                                ? userChecklists.paymentMethodEnabled
                                : ""
                            }
                          >
                            Add Payment Method{" "}
                          </Checkbox>{" "}
                          <Link to="/payment_method">
                            <Button
                              style={{
                                background: "#1FC157",
                                color: "white",
                                width: "75px",
                              }}
                              size="small"
                            >
                              Add{" "}
                            </Button>{" "}
                          </Link>{" "}
                        </div>
                        <div className="d-flex justify-content-between align-items-center  mb-3">
                          <Checkbox
                            checked={
                              userChecklists?.contactVerified &&
                                userChecklists?.businessVerified
                                ? true
                                : ""
                            }
                          >
                            Update your profile{" "}
                          </Checkbox>{" "}
                          <Link to="/profile">
                            <Button
                              style={{
                                background: "#1FC157",
                                color: "white",
                                width: "75px",
                              }}
                              size="small"
                            >
                              Update{" "}
                            </Button>{" "}
                          </Link>{" "}
                        </div>
                      </div>{" "}
                    </div>
                  )}{" "}
                </div>{" "}
              </div>
            )}{" "}
          </Modal>{" "}
          <Modal
            title={"Personal & Business Identity Verification"}
            visible={kycModal}
            footer={false}
            width={1000}
            onCancel={cancelSetKyc}
            maskClosable={false}
          >
            <div ref={modalRef}>
              <Alert
                description={
                  "Please note that you need your NIN (National Identification Number), BVN (Bank Verification Number), and CAC (RC Number) to set up a business bank account and complete payroll and payment transactions"
                }
                type="success"
                showIcon
              />
              <br />
              {steps === "personal" && (
                <div>
                  <div className="font-weight-bold d-flex justify-content-center align-items-center pb-3">
                    <div className="d-flex align-items-center">
                      <span
                        type="submit"
                        className="font-weight-bold xx-btn"
                        style={{
                          cursor: "pointer",
                          marginRight: "1rem",
                        }}
                      >
                        <b>Step 1/2 </b>
                      </span>

                      <div className="">Personal Information</div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Title <span className="text-danger">*</span>
                        </label>

                        <div>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setTitle(e.target.value)}
                            {...register("mytitle", {
                              required: "Title is required",
                              onChange: (e) => setTitle(e.target.value),
                              value: title,
                            })}
                          >
                            <option>Select</option>
                            <option>Dr</option>
                            <option>Miss</option>
                            <option>Mr</option>
                            <option>Mrs</option>
                            <option>Ms</option>
                            <option>Prof</option>
                            <option>Rev</option>
                          </select>
                          {errors.mytitle && (
                            <span className="validate-error">
                              {errors.mytitle.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Position <span className="text-danger">*</span>
                        </label>
                        <div>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setPosition(e.target.value)}
                            {...register("myposition", {
                              required: "Position is required",
                              onChange: (e) => setPosition(e.target.value),
                              value: position,
                            })}
                          >
                            <option>Select</option>
                            <option>Bursar</option>
                            <option>Chief Administrative Officer</option>
                            <option>Chief Executive Officer</option>
                            <option>Chief Financial Officer</option>
                            <option>Chief Information Officer</option>
                            <option>Chief Operating Officer</option>
                            <option> Chief Risk Officer</option>
                            <option> Chief Technology Officer</option>
                            <option> Chief of Staff Director</option>
                            <option> General Manager</option>
                            <option> Managing Director</option>
                            <option> President</option>
                            <option> Registrar</option>
                            <option> Secretary</option>
                            <option> Vice Chancellor</option>
                            <option> Vice President</option>
                          </select>
                          {errors.myposition && (
                            <span className="validate-error">
                              {errors.myposition.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name <span className="text-danger ">* </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="firstName"
                          value={firstName}
                          id="exampleInputFirstName"
                          aria-describedby="firstNameHelp"
                          {...register("firstName", {
                            required: "First Name is required!",
                            value: firstName,
                            maxLength: {
                              value: 30,
                              message: `First name cannnot be more than 30 characters`,
                            },
                          })}
                          onChange={handleInputChange("firstName")}
                          maxLength="30"
                        />
                        {errors.firstName && (
                          <span className="validate-error">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={lastName}
                          id="exampleInputEmail1"
                          {...register("lname", {
                            required: "Last Name is required",
                            onChange: handleInputChange("lastName"),
                            value: lastName,
                          })}
                          aria-describedby="emailHelp"
                        />
                        {errors.lname && (
                          <span className="validate-error">
                            {errors.lname.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Other Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={otherName}
                          {...register("otherName", {
                            required: false,
                            onChange: handleInputChange("otherName"),
                            value: otherName,
                          })}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of Birth <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="dob"
                          control={control}
                          {...register("dob", {
                            required: "Date of birth is required!",
                          })}
                          render={({ field }) => (
                            <DatePicker
                              onChange={onChange}
                              style={{
                                height: "3rem",
                                width: "100%",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                        />
                        {errors.dob && (
                          <span className="validate-error">
                            {errors.dob.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Gender <span className="text-danger">*</span>
                        </label>

                        <div>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setGender(e.target.value)}
                            value={gender}
                            {...register("gender", {
                              required: "Gender is required",
                              onChange: (e) => setGender(e.target?.value),

                              value: gender,
                            })}
                          >
                            <option selected>Select</option>
                            {genderData.map((martial) => {
                              return (
                                <option value={martial.value}>
                                  {martial.value}
                                </option>
                              );
                            })}
                          </select>
                          {errors.gender && (
                            <span className="validate-error">
                              {errors.gender.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Marital Status <span className="text-danger">*</span>
                        </label>

                        <div>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            value={maritalStatus}
                            {...register("maritalStatus", {
                              required: "Marital Status is required",
                              onChange: (e) =>
                                setMaritalStatus(e.target?.value),

                              value: gender,
                            })}
                          >
                            <option selected>Select</option>
                            {maritalData.map((martial) => {
                              return (
                                <option value={martial.value}>
                                  {martial.value}
                                </option>
                              );
                            })}
                          </select>
                          {errors.maritalStatus && (
                            <span className="validate-error">
                              {errors.maritalStatus.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="" class="">
                            Residential Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            style={{ color: "#1fc157", cursor: "pointer" }}
                            onClick={() => {
                              setResidentialManaulAddress(
                                !residentialAddressManual
                              );
                              setLocationInfo({});
                              setBusinessCity("");
                              setBusinessState("");
                              setBusinessPostalCode("");
                              setResidentialAddress("");
                              setResidentialPostalCode("");
                              setValue("address", "");
                              setResidentialState("");
                              setResidentialCity("");
                            }}
                          >
                            {residentialAddressManual ? (
                              <div
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  borderRadius: "7px",
                                  padding: "2px 8px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Enter Address Automatically
                              </div>
                            ) : (
                              <div
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  borderRadius: "7px",
                                  padding: "2px 8px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Enter Address Manually
                              </div>
                            )}
                          </div>
                        </div>

                        {residentialAddressManual ? (
                          <>
                            <input
                              type="text"
                              class="form-control mt-2"
                              value={residentialAddress}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              {...register("residentialAddress", {
                                required: residentialAddressManual
                                  ? "Residential Address is required"
                                  : false,
                                onChange: (e) =>
                                  setResidentialAddress(e.target?.value),
                                value: residentialAddress,
                              })}
                              onChange={(e) =>
                                setResidentialAddress(e.target.value)
                              }
                            />
                            {errors.residentialAddress && (
                              <span className="validate-error">
                                {errors.residentialAddress.message}
                              </span>
                            )}
                          </>
                        ) : (
                          <div className="pt-2">
                            <Location
                              setAddress={setResidentialAddress}
                              setCity={setResidentialCity}
                              setState={setResidentialState}
                              setPostal={setResidentialPostalCode}
                              setLocationInfo={setLocationInfo}
                              register={register}
                              control={control}
                              errors={errors}
                              watch={watch}
                              registerVal={undefined}
                              setValue={setValue}
                              reset={reset}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential City{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled={locationInfo?.city ? true : false}
                          value={residentialCity}
                          {...register("residentialCity", {
                            required: !locationInfo?.city
                              ? "Residential City is required"
                              : false,
                            onChange: handleInputChange("residentialCity"),

                            value: residentialCity,
                          })}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.residentialCity && (
                          <span className="validate-error">
                            {errors.residentialCity.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential Postal Code{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={residentialPostalCode}
                          disabled={
                            locationInfo?.postalcode?.length > 0 ? true : false
                          }
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          {...register("residentialPostalCode", {
                            required: locationInfo?.postalcode
                              ? false
                              : "Residential Postal Code is required",

                            onChange: handleInputChange(
                              "residentialpostalCode"
                            ),

                            value: residentialPostalCode,
                          })}
                        />
                        {errors.residentialPostalCode && (
                          <span className="validate-error">
                            {errors.residentialPostalCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential State{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled={locationInfo?.state ? true : false}
                          value={residentialState}
                          id="exampleInputEmail1"
                          {...register("residentialState", {
                            required: !locationInfo?.state
                              ? "Residential State is required"
                              : false,
                            onChange: handleInputChange("residentialState"),

                            value: residentialState,
                          })}
                          aria-describedby="emailHelp"
                        />
                        {errors.residentialState && (
                          <span className="validate-error">
                            {errors.residentialState.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential Country{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setCountry(e.target.value)}
                          {...register("residentialCountry", {
                            required: "Residential Country is required",
                            onChange: (e) => setCountry(e.target?.value),
                            value: country,
                          })}
                        >
                          <option selected>Select</option>
                          {countryData.length > 0 ? (
                            countryData.map((country) => {
                              return (
                                <option value={country.code}>
                                  {country.name}
                                </option>
                              );
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                        {errors.residentialCountry && (
                          <span className="validate-error">
                            {errors.residentialCountry.message}
                          </span>
                        )}
                      </div>
                    </div>

                    {identityType?.length > 0 && (
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {identityType[0]?.name}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            value={identity1}
                            {...register("identity1Personal1", {
                              required: "Identity is required",
                              onChange: handleChangeNumber.bind(
                                this,
                                "bvn",
                                11
                              ),

                              value: identity1,
                            })}
                          />
                          {errors.identity1Personal1 && (
                            <span className="validate-error">
                              {errors.identity1Personal1.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {identityType?.length > 0 && (
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {identityType[1]?.name}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            value={identity2}
                            {...register("identity1Personal2", {
                              required: "Identity is required",
                              onChange: handleChangeNumber.bind(
                                this,
                                "nin",
                                11
                              ),

                              value: identity2,
                            })}
                          />
                          {errors.identity1Personal2 && (
                            <span className="validate-error">
                              {errors.identity1Personal2.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formFileSma" class="form-label">
                          Upload Govt ID Front (Driver License or International Passport Only ){" "}
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="dobPersonal"
                          control={control}
                          {...register("fileFront", {
                            required: "GOVT ID front upload is required",
                          })}
                          render={({ field }) => (
                            <input
                              class="form-control form-control-sm"
                              id="formFileSmae"
                              type="file"
                              onChange={handleFileChangeFront}
                            />
                          )}
                        />

                        {errors.fileFront && (
                          <span className="validate-error">
                            {errors.fileFront.message}
                          </span>
                        )}

                        <ThreeDots
                          visible={loadUploadFront}
                          height="80"
                          width="80"
                          color="#4fa94d"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formFileSmb" class="form-label">
                          Upload Govt ID Back (Only for Driver License) - Optional
                        </label>
                        <input
                          class="form-control form-control-sm"
                          id="formFileSmb"
                          type="file"
                          onChange={handleFileChangeBack}
                        />

                        <ThreeDots
                          visible={loadUploadBack}
                          height="80"
                          width="80"
                          color="#4fa94d"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formFileSmb" class="form-label">
                          Upload Utility Bill (Electricity Bill, Waste Bill,
                          Bank Statement) <span className="text-danger">*</span>
                        </label>
                        <Controller
                          control={control}
                          {...register("utilityDoc", {
                            required: "Utility Document Upload is required",
                          })}
                          render={({ field }) => (
                            <input
                              class="form-control form-control-sm"
                              id="formFileSmb"
                              type="file"
                              onChange={handleFileChangeUtility}
                            />
                          )}
                        />

                        {errors.utilityDoc && (
                          <span className="validate-error">
                            {errors.utilityDoc.message}
                          </span>
                        )}
                        <ThreeDots
                          visible={loadUploadUtility}
                          height="80"
                          width="80"
                          color="#4fa94d"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <label for="formFileSmb" class="form-label">
                        Selfie <span className="text-danger">*</span>
                      </label>
                      <div
                        style={{
                          border: ".1px solid #E5E6EF",
                          cursor: "pointer",
                          background: "white",
                          borderRadius: "10px",
                        }}
                        className="p-2"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            onClick={setOpenSelfieCapture}
                            className="px-3"
                            style={{
                              border: "1px solid #E9ECEF",
                              background: "#E9ECEF",
                              width: "fit-content",
                            }}
                          >
                            {selfie ? "Seflie Caputred" : "Take Selfie"}
                          </div>

                          <div>
                            {selfie && (
                              <div
                                className="pt-2"
                                onClick={() => {
                                  setOpenSelfieCapture(true);
                                  setSelfie("");
                                }}
                              >
                                <u>Retake Selfie</u>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {loadUploadSelfie && (
                        <ThreeDots
                          visible={loadUploadSelfie}
                          height="50"
                          width="50"
                          color="#4fa94d"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      )}
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="formFileSmb" class="form-label">
                          Is your business registered with a Certificate of
                          Incorporation (CAC)?
                        </label>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            justifyContent: "",
                            alignItems: "center",
                          }}
                        >
                          <div className="" style={{ marginRight: "1rem" }}>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                checked={radioYes === "Yes" ? true : false}
                                type="radio"
                                name="radioRegBusinessYes"
                                value="Yes"
                                id="radioRegBusinessYes"
                                onChange={(e) => {
                                  if (e.target?.checked) {
                                    setRadioYes(e.target?.value);
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="radioRegBusinessYes"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          <div className="">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                checked={radioYes === "No" ? true : false}
                                type="radio"
                                name="radioRegBusinessNo"
                                id="radioRegBusinessNo"
                                value="No"
                                onChange={(e) => {
                                  if (e.target?.checked) {
                                    setRadioYes(e.target?.value);
                                  }
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="radioRegBusinessNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-info m-b-xs"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={handleSubmit(
                        getConfirmation.bind(this, "personal")
                      )}
                    >
                      {load ? (
                        <ThreeDots
                          visible={load}
                          height="20"
                          width="50"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : "Next"}
                    </button>
                  </div>
                </div>
              )}
              {steps === "business" && (
                <div>
                  <div className="font-weight-bold d-flex justify-content-center align-items-center pb-3">
                    <div className="d-flex align-items-center">
                      <span
                        type="submit"
                        className="font-weight-bold xx-btn"
                        style={{
                          cursor: "pointer",
                          marginRight: "1rem",
                        }}
                      >
                        <b>Step 2/2 </b>
                      </span>

                      <div className="">Business Information</div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Country{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          {...register("businessCountry", {
                            required: "Business Country is required",
                            value: countryBusiness,
                            onChange: getBusinessKyc,
                          })}
                        >
                          <option selected>Select</option>
                          {countryData?.length > 0 ? (
                            countryData.map((country) => {
                              return (
                                <option value={country.code}>
                                  {country.name}
                                </option>
                              );
                            })
                          ) : (
                            <option></option>
                          )}
                        </select>
                        {errors.businessCountry && (
                          <span className="validate-error">
                            {errors.businessCountry.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {registeredBusiness ?
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Identity Type <span className="text-danger">*</span>
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={selectedBusinessdentityType}
                            onChange={getBusinessKyc}
                            {...register("businessIdentityType", {
                              required: "Business Identity Type is required",
                              value: selectedBusinessdentityType,
                              onChange: setBusinessKycIdentityType,
                            })}
                          >
                            <option selected>Select</option>
                            {businessIdentityType?.length > 0 ? (
                              businessIdentityType?.map((identity) => {
                                return (
                                  <option value={identity?.type}>
                                    {identity?.name}
                                  </option>
                                );
                              })
                            ) : (
                              <option></option>
                            )}
                          </select>
                          {errors.businessIdentityType && (
                            <span className="validate-error">
                              {errors.businessIdentityType.message}
                            </span>
                          )}
                        </div>
                      </div> :

                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Identity Type
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={"CAC"}
                            disabled

                          />
                        </div>
                      </div>}

                    {businessIdentityDetails && registeredBusiness && (
                      <div class="col-sm-12 col-md-12">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {businessIdentityDetails}{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={businessIdentity}
                            {...register("businessIdentity", {
                              required: "Business Identity is required",
                              onChange: (e) =>
                                setBusinessIdentity(e.target?.value),
                            })}
                            maxLength={9}
                          />
                          {errors.businessIdentity && (
                            <span className="validate-error">
                              {errors.businessIdentity.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={businessName}
                          {...register("businessName", {
                            required: "Business Name is required",
                            onChange: handleInputChange("businessName"),
                          })}
                          id="exampleInputEmail1"
                          maxLength={60}
                          aria-describedby="emailHelp"
                        />
                        {errors.businessName && (
                          <span className="validate-error">
                            {errors.businessName.message}
                          </span>
                        )}
                        {registeredBusiness ?
                          <div style={{ color: "#1FC157", fontSize: '12px' }}>Kindly enter the business name exactly as it appears on your certificate of incorporation.</div> :
                          <div style={{ color: "#1FC157", fontSize: '12px' }}>
                            Kindly enter your preferred business name
                          </div>}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <label for="" class="">
                            Business Address{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div
                            style={{ color: "#1fc157", cursor: "pointer" }}
                            onClick={() => {
                              setBusinesssManaulAddress(
                                !buusinesslAddressManual
                              );
                              setLocationInfo({});
                              setBusinessCity("");
                              setBusinessState("");
                              setValue("address", "");
                              setBusinessAddress("");
                              setBusinessPostalCode("");
                              setResidentialAddress("");
                              setResidentialState("");
                              setResidentialPostalCode("");
                              setResidentialCity("");
                            }}
                          >
                            {buusinesslAddressManual ? (
                              <div
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  borderRadius: "7px",
                                  padding: "2px 8px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Enter Address Automatically
                              </div>
                            ) : (
                              <div
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  borderRadius: "7px",
                                  padding: "2px 8px",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Enter Address Manually
                              </div>
                            )}
                          </div>
                        </div>
                        {buusinesslAddressManual ? (
                          <>
                            <input
                              type="text"
                              class="form-control mt-2"
                              value={businessAddress}
                              {...register("businessAddress", {
                                required: "Business Address is required",
                              })}
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={(e) =>
                                setBusinessAddress(e.target.value)
                              }
                            />
                            {errors.businessAddress && (
                              <span className="validate-error">
                                {errors.businessAddress.message}
                              </span>
                            )}
                          </>
                        ) : (
                          <div className="pt-2">
                            <Location
                              setAddress={setBusinessAddress}
                              setCity={setBusinessCity}
                              setState={setBusinessState}
                              setPostal={setBusinessPostalCode}
                              setLocationInfo={setLocationInfo}
                              register={register}
                              control={control}
                              errors={errors}
                              watch={watch}
                              reset={reset}
                              setValue={setValue}
                              registerVal={undefined}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business City <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          disabled={locationInfo?.city ? true : false}
                          class="form-control"
                          {...register("businessCity", {
                            required: locationInfo?.city
                              ? false
                              : "Business City is required",
                            onChange: handleInputChange("businessCity"),
                          })}
                          maxLength={30}
                          value={businessCity}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.businessCity && (
                          <span className="validate-error">
                            {errors.businessCity.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business State <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          {...register("businessState", {
                            required: locationInfo?.state
                              ? false
                              : "Business State is required",
                            onChange: handleInputChange("businessState"),
                          })}
                          disabled={locationInfo?.state ? true : false}
                          value={businessState}
                          id="exampleInputEmail1"
                          maxLength={30}
                          aria-describedby="emailHelp"
                        />
                        {errors.businessState && (
                          <span className="validate-error">
                            {errors.businessState.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Postal Code{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled={
                            locationInfo?.postalcode?.length > 0 ? true : false
                          }
                          {...register("businessPostal", {
                            required: locationInfo?.postalcode
                              ? false
                              : "Business Postal Code is required",
                            onChange: handleInputChange("businesspostalCode"),
                          })}
                          value={businessPostal}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {errors.businessPostal && (
                          <span className="validate-error">
                            {errors.businessPostal.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Industry <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setIndustry(e.target.value)}
                          {...register("industry", {
                            required: "Business Industry is required",
                            onChange: (e) => setIndustry(e.target?.value),
                            value: industry,
                          })}
                        >
                          <option selected>Select</option>
                          <option>Agriculture & Food</option>
                          <option>Manufacturing & Production</option>
                          <option>Retail & E-commerce</option>
                          <option> Financial Services</option>
                          <option>Technology & Software</option>
                          <option>Healthcare & Pharmaceuticalstality</option>
                          <option> Education & Training </option>
                          <option> Real Estate & Construction</option>
                          <option> Transportation & Logistics</option>
                          <option> Hospitality & Tourism</option>
                          <option> Consumer Goods & Personal Care</option>
                        </select>
                        {errors.industry && (
                          <span className="validate-error">
                            {errors.industry.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Size <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setSize(e.target.value)}
                          {...register("size", {
                            required: "Business Size is required",
                            onChange: (e) => setSize(e.target?.value),
                            value: size,
                          })}
                        >
                          <option selected>Select</option>
                          <option>1 - 30 employees</option>
                          <option>31 - 100 employee</option>
                          <option>101 - 300 employee</option>
                          <option> 300 - 1000 employee</option>
                        </select>
                        {errors.size && (
                          <span className="validate-error">
                            {errors.size.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div>
                        <label for="exampleInputEmail1" class="form-label">
                          Website <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={website}
                          {...register("website", {
                            required: "Business Wesbite is required",
                            onChange: (e) => setWebsite(e.target?.value),
                            value: website,
                          })}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={(e) => setWebsite(e.target.value)}
                        />
                        {errors.website && (
                          <span className="validate-error">
                            {errors.website.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Support Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={supportEmail}
                          id="exampleInputEmail1"
                          {...register("support", {
                            required: "Business Support Email is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                              message: "Invalid Email Format",
                            },
                          })}
                          aria-describedby="emailHelp"
                          onChange={(e) => setSupportEmail(e.target.value)}
                        />
                        {errors.support && (
                          <span className="validate-error">
                            {errors.support.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Register Type <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setRegisterType(e.target.value)}
                          {...register("registerType", {
                            required:
                              "Business Registration Type Email is required",
                            onChange: (e) => setRegisterType(e.target?.value),
                            value: registerType,
                          })}
                        >
                          <option selected>Select</option>
                          <option>Incorporated Trustees</option>
                          <option>Incorporated Company</option>
                          <option> Non-Incorperated Company</option>

                          <option>Business Name Registration</option>
                          <option> Free Zone Entity</option>
                          <option> Government Entity</option>
                          <option> Private Entity</option>
                          <option> Cooperative Society</option>
                        </select>
                        {errors.registerType && (
                          <span className="validate-error">
                            {errors.registerType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Ownership <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setOwnership(e.target.value)}
                          {...register("ownership", {
                            required:
                              "Business Ownership Type Email is required",
                            onChange: (e) => setOwnership(e.target?.value),
                            value: ownership,
                          })}
                          value={ownership}
                        >
                          <option selected>Select</option>
                          <option>Privately Held(2017 - 2020)</option>
                          <option>Public (2017 - 2020)</option>
                          <option>
                            {" "}
                            Privately Held(December 2020 to date)
                          </option>
                          <option> Public (December 2020 to date)</option>
                          <option> Privately Held (Pre 2017)</option>
                          <option> Public (Pre 2017)</option>
                        </select>
                        {errors.ownership && (
                          <span className="validate-error">
                            {errors.ownership.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {registeredBusiness &&
                      <div class="col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label for="formFileSma" class="form-label">
                            Upload Certificate of Incorporation{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <Controller
                            name="dobPersonal"
                            control={control}
                            {...register("certDoc", {
                              required:
                                "Business Certificate of Incorportation Upload is required",
                            })}
                            render={({ field }) => (
                              <input
                                class="form-control form-control-sm"
                                id="formFileSma"
                                type="file"
                                onChange={handleFileChangeCertificate}
                              />
                            )}
                          />
                          {errors.certDoc && (
                            <span className="validate-error">
                              {errors.certDoc.message}
                            </span>
                          )}

                          <ThreeDots
                            visible={loadUploadCert}
                            height="80"
                            width="80"
                            color="#4fa94d"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      </div>}

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Fiscal Month <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={fiscalMonth}
                          {...register("fiscalMonth", {
                            required: "Business Fiscal Month is required",
                            value: fiscalMonth,
                          })}
                          onChange={(e) => setFiscalMonth(e.target.value)}
                        >
                          <option selected>Select Month</option>
                          {config.fiscalMonths.map((month) => {
                            return <option>{month}</option>;
                          })}
                        </select>
                        {errors.fiscalMonth && (
                          <span className="validate-error">
                            {errors.fiscalMonth.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center ">

                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={handleSubmit(
                          getConfirmation.bind(this, "business")
                        )}
                      >
                        {load ? (
                          <ThreeDots
                            visible={load}
                            height="20"
                            width="50"
                            color="#ffffff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            title={false}
            visible={lookUpKycModal}
            footer={false}
            maskClosable={false}
            onCancel={closeLookupModal}
            centered={false}
          >
            <div className="">
              {load && (
                <div class="text-center">
                  <Spin />
                  <p className="text-info font-weight-bold">
                    Verification in progress
                  </p>
                </div>
              )}
              {confirmationResult?.verifyType === "NONE" ||
                (confirmationResult?.verifyType === "OTP" && (
                  <div className="">
                    {steps === "personal" ? (
                      <div className="">
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={confirmationResult.firstName}
                            disabled
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            value={confirmationResult.lastName}
                            disabled
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                          />
                        </div>
                      </div>
                    ) : (
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={confirmationResult.companyName}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    )}

                    {confirmationResult?.verifyType === "OTP" && (
                      <div>
                        <label>Otp</label>
                        <OtpInput
                          value={pin}
                          onChange={handleChangePin}
                          isInputNum
                          isInputSecure
                          inputStyle={{
                            padding: "15px",
                            border: ".9px solid green",
                            width: "50px",
                          }}
                          numInputs={5}
                          separator={<span>-</span>}
                        />
                      </div>
                    )}
                  </div>
                ))}

              {confirmationResult?.verifyType === "DATE_OF_REGISTRATION" && (
                <div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={confirmationResult.businessName}
                      disabled
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="col-sm-12 col-md-12">
                    <div>
                      <label for="exampleInputEmail1" class="form-label">
                        Business Date of Registration{" "}
                      </label>
                      <DatePicker
                        onChange={onChangeDateOfReg}
                        style={{ height: "3rem", width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <br />
              <div className="d-flex justify-content-end">
                {confirmationResult?.verifyType !== "NONE" && (
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={
                      steps === "business"
                        ? businessDefaultVerify.bind(this, "2")
                        : personalDefaultVerify.bind(this, "2")
                    }
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </Modal>
          <Modal
            title={"Identity Verification"}
            visible={openPersonalKyc}
            footer={false}
            width={850}
            maskClosable={false}
            onCancel={closeEmployeePersonal}
          >
            <form>
              {/* {load && (
                <div class="text-center">
                  <Spin />
                  <p className="text-info font-weight-bold">
                    Verification in progress
                  </p>
                </div>
              )} */}
              <Alert
                description={
                  "Please remember that your NIN (National Identification Number) and BVN (Bank Verification Number) are required to set up a personal bank account and make payment transactions"
                }
                type="success"
                showIcon
              />
              <br />

              <div className="row">
                <div className="col-md-6 col-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      {...register("firstNamepersonal", {
                        required: "First Name is required",
                        onChange: (e) => setFirstName(e.target.value),
                        value: firstName,
                      })}
                      value={firstName}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    {errors.firstNamepersonal && (
                      <span className="validate-error">
                        {errors.firstNamepersonal.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={lastName}
                      {...register("lastNamepersonal", {
                        required: "Last Name is required",
                        onChange: (e) => setLastName(e.target.value),
                        value: lastName,
                      })}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                    {errors.lastNamepersonal && (
                      <span className="validate-error">
                        {errors.lastNamepersonal.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Country <span className="text-danger">*</span>
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      {...register("country", {
                        required: "Country is required",
                        onChange: (e) => setCountry(e.target.value),
                        value: country,
                      })}
                    >
                      <option selected>Select</option>
                      {countryData?.length > 0 ? (
                        countryData?.map((country) => {
                          return (
                            <option value={country.code}>{country.name}</option>
                          );
                        })
                      ) : (
                        <option></option>
                      )}
                    </select>
                    {errors.country && (
                      <span className="validate-error">
                        {errors.country.message}
                      </span>
                    )}
                  </div>
                </div>

                {identityType?.length > 0 && (
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {identityType[0]?.name}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        {...register("identity1", {
                          required: identityType[0]?.name + " is required",
                          onChange: (e) => setIdentity1(e.target.value),
                          value: identity1,
                        })}
                        class="form-control"
                        value={identity1}
                      />
                      {errors.identity1 && (
                        <span className="validate-error">
                          {errors.identity1.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {identityType?.length > 0 && (
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {identityType[1]?.name}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        {...register("identity2", {
                          required: identityType[1]?.name + " is required",
                          onChange: (e) => setIdentity2(e.target.value),
                          value: identity2,
                        })}
                        value={identity2}
                      />
                      {errors.identity2 && (
                        <span className="validate-error">
                          {errors.identity2.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {/* <div class="col-sm-12 col-md-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Identity Type
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={setPersonalKycIdentityType}
                      value={selectedIdentityType}
                    >
                      <option selected>Select</option>
                      {identityType ? (
                        identityType.map((identity) => {
                          return (
                            <option value={identity.type}>
                              {identity.name}
                            </option>
                          );
                        })
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>
                {identityDetails && (
                  <div class="col-sm-12 col-md-12">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {identityDetails}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={personalIdentity}
                        onChange={(e) => setPersonalIdentity(e.target.value)}
                      />
                    </div>
                  </div>
                )} */}
                <div className="col-md-6 col-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      {...register("titlePersonal", {
                        required: "Title is required",
                        onChange: (e) => setTitle(e.target.value),
                        value: title,
                      })}
                    >
                      <option selected>Select</option>
                      <option>Mr.</option>
                      <option>Mrs.</option>
                      <option>Ms.</option>
                      <option>Miss</option>
                      <option>Dr.</option>
                      <option>Prof</option>
                      <option>Rev.</option>
                      <option>Sir</option>
                    </select>
                    {errors.titlePersonal && (
                      <span className="validate-error">
                        {errors.titlePersonal.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="dobPersonal"
                      control={control}
                      {...register("dobPersonal", {
                        required: "Date of birth is required!",
                      })}
                      render={({ field }) => (
                        <DatePicker
                          onChange={onChange}
                          style={{
                            height: "3rem",
                            width: "100%",
                            borderRadius: "10px",
                          }}
                        />
                      )}
                    />
                    {errors.dobPersonal && (
                      <span className="validate-error">
                        {errors.dobPersonal.message}
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Gender <span className="text-danger">*</span>
                    </label>

                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        {...register("gendePersonal", {
                          required: "Gender is required",
                          onChange: (e) => setGender(e.target.value),
                          value: gender,
                        })}
                      >
                        <option selected>Select</option>
                        {genderData.map((martial) => {
                          return (
                            <option value={martial.value}>
                              {martial.value}
                            </option>
                          );
                        })}
                      </select>
                      {errors.gendePersonal && (
                        <span className="validate-error">
                          {errors.gendePersonal.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Marital Status <span className="text-danger">*</span>
                    </label>

                    <div>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        {...register("maritalPersonal", {
                          required: "Marital Status is required",
                          onChange: (e) => setMaritalStatus(e.target.value),
                          value: maritalStatus,
                        })}
                      >
                        <option selected>Select</option>
                        {maritalData.map((martial) => {
                          return (
                            <option value={martial.value}>
                              {martial.value}
                            </option>
                          );
                        })}
                      </select>
                      {errors.maritalPersonal && (
                        <span className="validate-error">
                          {errors.maritalPersonal.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label for="" class="">
                        Residential Address{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div
                        style={{ color: "#1fc157", cursor: "pointer" }}
                        onClick={() => {
                          setResidentialManaulAddress(
                            !residentialAddressManual
                          );
                          setLocationInfo({});
                          setBusinessCity("");
                          setBusinessState("");
                          setBusinessPostalCode("");
                          setResidentialPostalCode("");
                          setResidentialAddress("");
                          setResidentialState("");
                          setResidentialCity("");
                        }}
                      >
                        {residentialAddressManual ? (
                          <div
                            style={{
                              background: "#1FC157",
                              color: "white",
                              borderRadius: "7px",
                              whiteSpace: "nowrap",
                              padding: "2px 8px",
                              cursor: "pointer",
                            }}
                          >
                            Enter Address Automatically
                          </div>
                        ) : (
                          <div
                            style={{
                              background: "#1FC157",
                              color: "white",
                              borderRadius: "7px",
                              padding: "2px 8px",
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Enter Address Manually
                          </div>
                        )}
                      </div>
                    </div>
                    {residentialAddressManual ? (
                      <>
                        <input
                          type="text"
                          class="form-control mt-2"
                          value={residentialAddress}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          {...register("personalAddress", {
                            required: residentialAddressManual
                              ? "Residential Address is required"
                              : false,
                            onChange: (e) =>
                              setResidentialAddress(e.target.value),
                            value: residentialAddress,
                          })}
                          onChange={(e) =>
                            setResidentialAddress(e.target.value)
                          }
                        />
                        {errors.personalAddress && (
                          <span className="validate-error">
                            {errors.personalAddress.message}
                          </span>
                        )}
                      </>
                    ) : (
                      <div className="pt-2">
                        <Location
                          setAddress={setResidentialAddress}
                          setCity={setResidentialCity}
                          setState={setResidentialState}
                          setPostal={setResidentialPostalCode}
                          setLocationInfo={setLocationInfo}
                          register={register}
                          control={control}
                          errors={errors}
                          watch={watch}
                          reset={reset}
                          setValue={setValue}
                          registerVal={undefined}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential City <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={locationInfo?.city ? true : false}
                      type="text"
                      class="form-control"
                      value={residentialCity}
                      {...register("personalCity", {
                        required: locationInfo?.city
                          ? false
                          : "Residential City is required",
                        onChange: (e) => setResidentialCity(e.target.value),
                        value: residentialCity,
                      })}
                      id="exampleInputEmail1"
                      maxLength={30}
                      aria-describedby="emailHelp"
                      onChange={(e) => setResidentialCity(e.target.value)}
                    />
                    {errors.personalCity && (
                      <span className="validate-error">
                        {errors.personalCity.message}
                      </span>
                    )}
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential Postal Code{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={locationInfo?.postalcode ? true : false}
                      value={residentialPostalCode}
                      id="exampleInputEmail1"
                      {...register("personalPostalCode", {
                        required: locationInfo?.postalcode
                          ? false
                          : "Residential Postal Code is required",
                        onChange: (e) =>
                          setResidentialPostalCode(e.target.value),
                        value: residentialPostalCode,
                      })}
                      aria-describedby="emailHelp"
                      onChange={(e) => setResidentialPostalCode(e.target.value)}
                    />
                    {errors.personalPostalCode && (
                      <span className="validate-error">
                        {errors.personalPostalCode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential State <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      disabled={locationInfo?.state ? true : false}
                      class="form-control"
                      value={residentialState}
                      id="exampleInputEmail1"
                      {...register("personalState", {
                        required: locationInfo?.state
                          ? false
                          : "Residential State is required",
                        onChange: (e) => setResidentialState(e.target.value),
                        value: residentialState,
                      })}
                      aria-describedby="emailHelp"
                      maxLength={30}
                      onChange={(e) => setResidentialState(e.target.value)}
                    />
                    {errors.personalState && (
                      <span className="validate-error">
                        {errors.personalState.message}
                      </span>
                    )}
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSma" class="form-label">
                      Upload Govt ID Front (Driver License or International Passport Only){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSmsa"
                      type="file"
                      {...register("personalGovtFront", {
                        required: "Govt ID front upload is required",
                      })}
                      onChange={handleFileChangeFront}
                    />
                    {errors.personalGovtFront && (
                      <span className="validate-error">
                        {errors.personalGovtFront.message}
                      </span>
                    )}

                    <ThreeDots
                      visible={loadUploadFront}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSmb" class="form-label">
                      Upload Govt ID Back (Only for Driver License) - Optional
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSmb"
                      type="file"
                      onChange={handleFileChangeBack}
                    />

                    <ThreeDots
                      visible={loadUploadBack}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="">
                    <label for="formFileSmb" class="form-label">
                      Upload Utility Bill (Electricity Bill, Waste Bill ){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSmb"
                      {...register("personalUtility", {
                        required: "Utility Bill upload is required",
                      })}
                      type="file"
                      onChange={handleFileChangeUtility}
                    />
                    {errors.personalUtility && (
                      <span className="validate-error">
                        {errors.personalUtility.message}
                      </span>
                    )}
                    <ThreeDots
                      visible={loadUploadUtility}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <label for="formFileSmb" class="form-label">
                    Capture Selfie <span className="text-danger">*</span>
                  </label>
                  <div
                    style={{
                      border: ".1px solid #E5E6EF",
                      cursor: "pointer",
                      background: "white",
                      borderRadius: "10px",
                    }}
                    className="p-2"
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        onClick={setOpenSelfieCapture}
                        className="px-3"
                        style={{
                          border: "1px solid #E9ECEF",
                          background: "#E9ECEF",
                          width: "fit-content",
                        }}
                      >
                        {selfie ? "Seflie Caputred" : "Take Selfie"}
                      </div>

                      <div>
                        {selfie && (
                          <div
                            className="pt-2"
                            onClick={() => {
                              setOpenSelfieCapture(true);
                              setSelfie("");
                            }}
                          >
                            <u>Retake Selfie</u>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {loadUploadSelfie && (
                    <ThreeDots
                      visible={loadUploadSelfie}
                      height="50"
                      width="50"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                </div>
                <div class="col-sm-12 col-md-12">
                  <div className="mb-3" style={{ marginTop: "-1rem" }}>
                    <label for="formFileSmb" class="form-label text-white">
                      Select Account Option
                    </label>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        onChange={defaultChange}
                        checked={defaultPay}
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Once your KYC is approved, a personal bank account will
                        be created for you. Would you like to set this personal
                        bank account as your default payment method for
                        receiving payments?
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleSubmit(confirmPersonalKyc)}
                >
                  {load ? (
                    <ThreeDots
                      visible={load}
                      height="20"
                      width="50"
                      color="#ffffff"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </Modal>
          <Modal
            title={" Capture Selfie"}
            visible={openSelfieCapture}
            footer={false}
            width={600}
            maskClosable={false}
            onCancel={() => {
              setOpenSelfieCapture(false);
            }}
          >
            <div>
              <SelfieCapture
                onCapture={setSelfie}
                setOpenSelfieCapture={setOpenSelfieCapture}
                uploadSelfie={uploadSelfie}
                convertToBlob={convertToBlob}
              />
            </div>
          </Modal>
        </div>
      )}
    </Layout>
  );
}
