import React, { useState, useEffect, useRef } from "react";
import Layout from "../../containers/Layout";
import wallet from "../../img/wallet.jpg";
import config from "../../config";
import { useHttp } from "../../hooks/useHttps";
import axios from "axios";
import imgBackground from "../../img/reciept.jpeg";
import { useMono } from "react-mono-js";
import successicon from "../../img/send-chek.svg";
import { useHistory } from "react-router-dom";
import { NumberFormatter } from "../../formatter/index";
import {
  Modal,
  Spin,
  Alert,
  Tabs,
  Select,
  notification,
  Pagination,
  DatePicker,
  Switch,
} from "antd";
import BillingPayment from "./paymentbilling";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import html2canvas from 'html2canvas';
import OtpInput from "react-otp-input";
import { useForm, Controller } from "react-hook-form";
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const sevenDaysAgo = new Date(currDay - 7 * days);
const plus1day = new Date(currDay + 1 * days);
export default function Accounts(props) {
  const [page, setPage] = useState(1);
  const [loadBal, setLoadBal] = useState(false);
  const [bankTransfer, setBankTrasnfer] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState({});
  const [openSuccessSend, settOpenSuccessSend] = useState(false)
  const [addWalletModal, setOpenAddWallet] = useState(false);
  const [receieptInfo, setRecieiptInfo] = useState({})
  const [showReceipt, setShowReceipt] = useState(false); // Toggle receipt visibility
  const [openSuccessmsg, setOpenSuccessmsg] = useState("");
  const [showBeneficiary, setShowBeneficiary] = useState(false);
  const [existingCards, setExistingCards] = useState([]);
  const [existingDebits, setExistingDebits] = useState([]);
  const [beneficiariesList, setBeneficiaryList] = useState([]);
  const [pinModal, setPinModal] = useState(false);
  const [onCheck, setOnCheck] = useState(false);
  const [sendFee, setSendFee] = useState("");
  const [openAddMoney, setOpenAddMoney] = useState(false);
  const [openSendMoney, setOpenSendMoney] = useState(false);
  const history = useHistory();

  const [billMethod, setBillMethod] = useState("");
  const [transactionType, setTranstype] = useState("");

  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  const onChange = (date, dateString) => {
    setDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [pin, setPin] = useState("");
  const [loadAcc, setLoadAcc] = useState(false);
  const [paymentActionMode, setPaymentTypeAction] = useState("");
  const [accountBnkName, setAccountName] = useState("");
  const [openSendMoneyBt, setOpenSendMoneyBt] = useState(false);
  const [openSendMoneyMm, setOpenSendMoneyMm] = useState(false);
  const [accountSend, setAccountSend] = useState("");
  const [countryLists, setCountriesList] = useState([]);
  const [mobileMonies, setMobileMoniesLists] = useState([]);
  const [countrySend, setCountrySend] = useState("");
  const [currencySend, setCurrencySend] = useState("");
  const [currencySendLists, setCurrencySendLists] = useState([]);
  const [transactionList, setTransaction] = useState([]);
  const [loadTrans, setLoadTrans] = useState(false);
  const [bankSend, setBankSend] = useState([]);
  const [banksLists, setBanksLists] = useState([]);
  const [addWalletBox, setAddWalletBox] = useState(false);
  const [amountSend, setAmountSend] = useState("");
  const [narration, setNarration] = useState("");
  const [fundModal, setFundModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [totalItems, setTotalItems] = useState("");
  const [debitPuublicKeyRef, setDebitPublickeyRef] = useState(
    config.mono_public_key
  );
  const [employerWallets, setEmployerWallets] = useState([]);
  const [bankListssEARCH, setBnkListSearch] = useState([]);
  const [bankInformation, setBankInforrmation] = useState({});
  const [loadWalletrCurrencies, walletCurrenciesList] = useHttp(
    `${config.baseUrl}/v1/wallets/users/currencies`,
    []
  );

  const acc = watch("acc");

  const getbeneficiaries = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/wallets/beneficiaries`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const beneList = res.data.result;
          setBeneficiaryList(beneList);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const getContactDetails = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/users/${sessionStorage.getItem("employeeID")}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const contactInformation = res.data.result;
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  function handleChange(currency) {
    const walletCurrencyBalance = employerWallets.find(
      (wallet) => wallet.currency === currency
    );
    setWalletInformations([
      {
        balance: walletCurrencyBalance.balance,
        currency: walletCurrencyBalance.currency,
      },
    ]);
    setBankInforrmation([
      {
        accountName: walletCurrencyBalance.accountName,
        accountNumber: walletCurrencyBalance.accountNumber,
        bankName: walletCurrencyBalance.bankName,
        currency: walletCurrencyBalance.currency,
      },
    ]);
  }

  //new walet
  const [bvn, setBvn] = useState("");
  const [country, setCountry] = useState("");
  const [loadCountryProps, setLoadCountryProps] = useState(false);
  const [currency, setCurrency] = useState("");
  const [currencyDatas, setCurrencyData] = useState([]);
  //wallets
  const [walletInformation, setWalletInformations] = useState([]);

  //type
  const [depositType, setDepositType] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);

  const [load, setLoad] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [cardId, setCardId] = useState("");

  //fee charges
  const [loadFee, setLoadFee] = useState(false);
  const [feeCharge, setFeeCharge] = useState("");
  const [loadingFee, setLoadingFee] = useState(true);

  //Direct Debit
  const [directDebitModal, setDirectDebitModal] = useState(false);

  const directDebitMethod = (type) => {
    setDirectDebitModal(true);
    setDepositType(type);
  };
  //verification

  const [verifyStatus, setVerifyStatus] = useState(false);
  const [successMsgVerify, setSuccessMsgVerify] = useState("");
  const [errorMsgVerify, setErrorMsgVerify] = useState("");
  const [errorVerify, setErrorVerify] = useState(false);
  const [successVerify, setSuccessVerify] = useState(false);
  const [serverErrorVerify, setServerErrorVerify] = useState(false);

  //mono implmentation

  const cancelStatus = () => {
    setVerifyStatus(false);
    setTimeout(() => {
      props.history.push("/accounts");
    }, 800);
  };

  const fetchAccFee = () => {
    if (amountSend) {
      setLoadAcc(true);
      axios
        .get(
          `${config.baseUrl}/v1/wallets/transfer/fee?walletId=${accountSend}&transferType=BANK_ACCOUNT&bankCode=${bankSend}&amount=${amountSend}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadAcc(false);
          if (res.data.status) {
            setSendFee(res.data.result);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadAcc(false);
          if (err) {
          }
        });
    }
  };

  const fetchAccountMoney = (e) => {
    e.preventDefault();
    if (acc) {
      setLoadAcc(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bankSend,
            accountNumber: e.target.value,
            countryCode: "NGN",
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadAcc(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  };

  const getInstitutionName = (code) => {
    var findBank = banksLists?.find((c) => c.bankCode === code);
    return findBank?.bankName;
  };

  const fetchAccount = (e) => {
    e.preventDefault();
    if (acc) {
      setLoadAcc(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bankSend,
            accountNumber: acc,
            countryCode: countrySend,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadAcc(false);
          if (res.data.status) {
            setAccountName(res.data.result.accountName);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
          }
        });
    }
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const closeDirectDebit = () => setDirectDebitModal(false);

  const verifyBillingFlutterwave = () => {
    const token = sessionStorage.getItem("token");
    var urlParams = new URLSearchParams(window.location.search);
    const transRef = urlParams.get("tx_ref");
    const trans_id = urlParams.get("transaction_id");
    axios
      .get(
        `${config.baseUrl}/v1/billing-methods/verify-card/${trans_id}/${transRef}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setVerifyStatus(true);
          setSuccessMsgVerify(res.data.message);
          setSuccessVerify(true);
          setErrorVerify(false);
        } else {
          setErrorMsgVerify(res.data.message);
          setVerifyStatus(true);
          setSuccessVerify(false);
          setErrorVerify(true);
        }
      })
      .catch((err) => {
        setServerErrorVerify(true);
      });
  };

  const cancelPinSetup = () => {
    setPinModal(false);
    setPin("");
  };

  const handleBvnChange = (event) => {
    const limit = 12;
    setBvn(event.target.value.slice(0, limit));
  };
  const handleChangePin = (pin) => setPin(pin);

  const getFeeCharge = () => {
    if (amount) {
      setLoad(true);
      setLoadingFee(true);
      const token = sessionStorage.getItem("token");
      axios
        .get(
          `${config.baseUrl}/v1/billing-methods/card/fee/${amount}/${walletInformation[0].currency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setLoadFee(true);
            setLoadingFee(false);
            setFeeCharge(res.data.result);
          } else {
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  };

  const getCountries = () => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCountriesList(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const onCurrencyChange = (e) => {
    setCurrencySend(e.target.value);
  };

  const getTransactions = () => {
    const token = sessionStorage.getItem("token");
    setLoadTrans(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/transactions/users/paged?userId=${sessionStorage.getItem(
          "userId"
        )}&size=50&page=0&endDate=${endDate}&startDate=${date}&transactionType=${transactionType}&billingMethod=${billMethod}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoadTrans(false);
        setTotalItems(res.data.totalPages * 10);
        setTransaction(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
        setLoadTrans(false);
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `userId=${sessionStorage.getItem(
      "userId"
    )}&size=${pageSize}&page=${
      page - 1
    }&endDate=${endDate}&startDate=${date}&transactionType=${transactionType}&billingMethod=${billMethod}`;
    getPaged(queryString);
  };

  useEffect(() => {
    if ((date && endDate) || billMethod || transactionType) getTransactions();
  }, [date, endDate, billMethod, transactionType]);

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/transactions/users/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadTrans(false);
        setTotalItems(res.data.totalPages * 10);
        setTransaction(res.data.result);
      })
      .catch((err) => {
        setLoad(false);
        setLoadTrans(false);
      });
  };

  const getExistingCards = () => {
    axios
      .get(`${config.baseUrl}/v1/billing-methods/user/CARD`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setExistingCards(res.data?.result);
      })
      .catch((err) => {
        setLoad(false);
        setLoadTrans(false);
      });
  };

  useEffect(() => {
    getExistingDirectDebits();
    getExistingCards();
  }, []);

  const getExistingDirectDebits = () => {
    axios
      .get(`${config.baseUrl}/v1/billing-methods/user/DIRECT_DEBIT`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setExistingDebits(res.data?.result);
      })
      .catch((err) => {
        setLoad(false);
        setLoadTrans(false);
      });
  };

  const getFeeChargeDirectDebit = () => {
    if (amount) {
      setLoad(true);
      setLoadingFee(true);
      const token = sessionStorage.getItem("token");
      axios
        .get(
          `${config.baseUrl}/v1/billing-methods/direct-debit/fee/${amount}/${walletInformation[0].currency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setLoadFee(true);
            setLoadingFee(false);
            setDebitPublickeyRef(res.data.result.publicKey);
            setFeeCharge(res.data.result.merchantFee);
          } else {
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    }
  };

  const changeBeneficiaryList = () => {
    setShowBeneficiary(true);
  };

  const verifyBillingMono = () => {
    const token = sessionStorage.getItem("token");
    var urlParams = new URLSearchParams(window.location.search);
    const txRef = urlParams.get("reference");

    axios
      .get(
        `${config.baseUrl}/v1/billing-methods/verify-direct-debit/${txRef}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setVerifyStatus(true);
          setSuccessMsgVerify(res.data.message);
          setSuccessVerify(true);
          setErrorVerify(false);
        } else {
          setErrorMsgVerify(res.data.message);
          setVerifyStatus(true);
          setSuccessVerify(false);
          setErrorVerify(true);
        }
      })
      .catch((err) => {
        setServerErrorVerify(true);
      });
  };

  const addCard = (type) => {
    setAddWalletBox(true);
    setDepositType(type);
  };

  function callback(key) {}

  useEffect(() => {
    getContactDetails();
    getTransactions();
    getbeneficiaries();
    getCountries();
    getWalletInformations();
    var urlParams = new URLSearchParams(window.location.search);
    const processorType = urlParams.get("type");
    if (processorType === "2") {
      verifyBillingMono();
    } else if (processorType === "1") {
      verifyBillingFlutterwave();
    }
  }, []);

  const openFundWallet = () => {
    setFundModal(true);
    setAddWalletBox(false);
    setLoadFee(false);
  };

  const closeFund = () => {
    setFundModal(false);
    setAmount("");
    setLoadFee(false);
  };

  
  const contentRef = useRef(); // Create a reference to the div you want to convert to PDF

  const downloadPDF = async () => {
    // Temporarily show the receipt
    setShowReceipt(true);

    // Wait for the DOM to render the receipt
    await new Promise((resolve) => setTimeout(resolve, 0));

    const element = contentRef.current; // Get the receipt content
    html2canvas(element, {
      scale: 3, // Higher scale for better quality
      useCORS: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Download as an image
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "transaction-receipt.png";
      link.click();

      // Alternatively, download as a PDF
      // const pdf = new jsPDF("p", "mm", "a4");
      // const imgWidth = 210;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      // pdf.save("receipt.pdf");

      // Hide the receipt again after download
      setShowReceipt(false);
    });
  };

  const fetchCurrencies = (country, currency) => {
    getbanks(country);
    setCountry(country);
    getMobileMonies(country);
    setLoadCountryProps(true);
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadCountryProps(false);
        if (res.data.status) {
          setCurrencySendLists(res.data.result);
          setCurrencyData(res.data.result);
          setCurrencySend(currency);
        }
      })
      .catch((err) => {
        setLoadCountryProps(false);
        if (err) {
        }
      });
  };

  const getCurrenciesByCountry = (e) => {
    setCountrySend(e.target.value);
    getbanks(e.target.value);
    setCountry(e.target?.value);
    getMobileMonies(e.target.value);
    var countryCode = e.target.value;
    setLoadCountryProps(true);
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${countryCode}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadCountryProps(false);
        if (res.data.status) {
          setCurrencySendLists(res.data.result);
          setCurrencyData(res.data.result);
        }
      })
      .catch((err) => {
        setLoadCountryProps(false);
        if (err) {
        }
      });
  };

  const getbanks = (country) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setBanksLists(res.data.result);
          setBnkListSearch(
            res.data?.result?.map((d) => ({
              value: d?.bankCode,
              label: d?.bankName,
            }))
          );
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getMobileMonies = (country) => {
    axios
      .get(
        `${config.baseUrl}/v1/financial-institution/mobile-monies/${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setMobileMoniesLists(res.data.result);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closeBankTrasnfer = () => {
    setBankTrasnfer(false);
  };

  const getWalletInformations = () => {
    setLoadBal(true);
    axios
      .get(`${config.baseUrl}/v1/wallets/users`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadBal(false);
        if (res.data.status) {
          const walletInfo = res.data.result;
          setEmployerWallets(walletInfo);
          setWalletInformations(walletInfo);
          setBankInforrmation(walletInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoadBal(false);
        }
      });
  };

  const closeAddWallet = () => {
    setAddWalletBox(false);
  };

  const cardDelete = (id) => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/billing-methods/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          window.reload();
          setTimeout(() => {
            setSuccess(false);
          }, 2500);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const closeAddmoney = () => {
    setOpenAddMoney(false);
  };
  const closeSendMoney = () => {
    setOpenSendMoney(false);
  };

  const closeSendMoneyBt = () => {
    setOpenSendMoneyBt(false);
    setAccountSend("");
    setBankSend("");
    setCurrencySend("");
    setAmountSend("");
    setCountrySend("");
    setNarration("");
    setValue("acc", null);
    setAccountName("");
  };

  const closeSendMoneyMm = () => {
    setOpenSendMoneyMm(false);
    setAccountSend("");
    setBankSend("");
    setCurrencySend("");
    setCountrySend("");
    setNarration("");
    setAmount(0);
    setAccountName("");
    setValue("acc", null);
  };

  const addCardInfo = (e) => {
    e.preventDefault();
    const data = {
      amount,
      // employerId: sessionStorage.getItem("employeeID"),
      // processor: "FLUTTERWAVE",
      currency: walletInformation[0].currency,
    };
    if (data.amount) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/billing-methods/user/add-card`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            window.location.href = `${res.data.result}`;
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setAddWalletBox(false);
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    }
  };

  const fundExistingCard = (e) => {
    e.preventDefault();
    const data = {
      amount,
      currency: walletInformation[0].currency,
    };
    if (data.amount) {
      setLoad(true);
      axios
        .put(
          `${config.baseUrl}/v1/billing-methods/user/charge-card/${cardId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setAmount("");
            getWalletInformations();
            setAddWalletBox(false);
            Notification("success", "Success", res.data.message);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    }
  };

  const addNewDirectDebitCard = (e) => {
    e.preventDefault();
    const data = {
      amount,
      // processor: "MONO",
      // authId: directDebitCode,
      currency: walletInformation[0].currency,
      // customerId: "",
    };
    if (data.amount) {
      setLoad(true);
      axios
        .post(
          `${config.baseUrl}/v1/billing-methods/user/add-direct-debit`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            window.location.href = `${res.data.result}`;
            // window.location.href = `/mono-link`
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setAddWalletBox(false);
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    }
  };

  const onSearch = (value) => {};

  const fundExistingDirectDebit = (e) => {
    e.preventDefault();
    const data = {
      amount,
      employerId: sessionStorage.getItem("employeeID"),
      processor: "FLUTTERWAVE",
      authId: "",
      customerId: "",
      currency: walletInformation[0].currency,
    };
    if (data.amount) {
      setLoad(true);
      axios
        .put(
          `${config.baseUrl}/v1/billing-methods/user/charge-account/${cardId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setAmount("");
            getWalletInformations();
            setAddWalletBox(false);
            window.location.href = `${res.data.result.data.link}`;
            Notification("success", "Success", res.data.message);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    }
  };

  const postPin = (type, e) => {
    e.preventDefault();
    setPaymentTypeAction(type);
    switch (type) {
      case "Bank-Transfer":
        const dataBank = {
          amount: amountSend,
          countryCode: countrySend,
          currencyCode: currencySend,
          customerIdentifier: "string",
          customerName: accountBnkName,
          institutionCode: bankSend,
          institutionName: getInstitutionName(bankSend),
          narration: narration,
          pin: "string",
          platform: "string",
          walletId: accountSend,
        };
        if (
          dataBank.amount &&
          dataBank.countryCode &&
          dataBank.currencyCode &&
          dataBank.customerName &&
          dataBank.institutionCode &&
          dataBank?.narration &&
          dataBank?.walletId
        ) {
          setPinModal(true);
        } else {
          Notification("error", "Error", "Fields are all required!");
        }

        break;
      case "Mobile-money":
        const dataMoney = {
          amount: 0,
          countryCode: "string",
          currencyCode: "string",
          customerIdentifier: "string",
          customerName: "string",
          institutionCode: "string",
          institutionName: "string",
          narration: "string",
          pin: "string",
          platform: "string",
          walletId: "string",
        };
        if (
          dataMoney.amount &&
          dataMoney.countryCode &&
          dataMoney.currencyCode &&
          dataMoney.customerName &&
          dataMoney.institutionCode &&
          dataMoney?.narration &&
          dataMoney?.walletId
        ) {
          setPinModal(true);
        } else {
          Notification("error", "Error", "Fields are all required!");
        }

        break;
      default:
        return;
    }
  };

  const onPostPin = (d) => {
    setCountrySend(d?.country);
    fetchCurrencies(d?.country, d?.currency);
    setShowBeneficiary(false);
    setOpenAddMoney(false);
    setShowBeneficiary(false);
    setOpenSendMoneyBt(true);
    setBeneficiaryData(d);
    setValue("acc", d?.identifier);
    setAccountName(d?.customerName);
    setBankSend(d?.institutionName);
  };

  const continueMobileMoney = (e) => {
    e.preventDefault();
    setLoad(true);
    setOpenSendMoneyBt(false);
    const data = {
      amount: amountSend,
      countryCode: countrySend,
      currencyCode: currencySend,
      customerIdentifier: "string",
      customerName: accountBnkName,
      institutionCode: bankSend,
      institutionName: "string",
      narration: narration,
      pin: pin,
      platform: "WEB",
      walletId: accountSend,
    };
    setLoadAcc(true);
    axios
      .post(`${config.baseUrl}/v1/wallets/transfer/mobile-money`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setPinModal(false);
          Notification("success", "Success", res.data.message);
          closeSendMoneyBt();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);

        if (err) {
          setLoadAcc(false);
        }
      });
  };

  const onChangeBeneficiary = (val) => {
    setOnCheck(val);
  };

  const getBankName = (id) => {
    var bank = banksLists?.find((x) => x.bankCode === id);
    if (bank?.bankName) {
      return bank?.bankName;
    } else {
      var bankCheck = banksLists?.find((x) => x.bankName === id);
      return bankCheck?.bankName;
    }
  };

  const getBankCode = (id) => {
    var bank = banksLists?.find((x) => x.bankCode === id);
    if (bank?.bankCode) {
      return bank?.bankCode;
    } else {
      var bankCheck = banksLists?.find((x) => x.bankName === id);
      return bankCheck?.bankCode;
    }
  };

  const continueSendBankTransfer = (e) => {
    e.preventDefault();
    setLoad(true);
    console.log(bankSend);
    console.log(banksLists);
    setOpenSendMoneyBt(false);
    const data = {
      amount: amountSend,
      countryCode: countrySend,
      currencyCode: currencySend,
      customerIdentifier: acc,
      customerName: accountBnkName,
      institutionCode: getBankCode(bankSend),
      institutionName: getBankName(bankSend),
      narration: narration,
      pin: pin,
      platform: "WEB",
      saveBeneficiary: onCheck,
      walletId: accountSend,
    };
    setLoadAcc(true);
    axios
      .post(`${config.baseUrl}/v1/wallets/transfer/bank-account`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setPinModal(false);
          setOpenSuccessmsg(res.data.message)
          settOpenSuccessSend(true)
          setRecieiptInfo(res.data.result)
          getTransactions();
          getWalletInformations();
          closeSendMoneyBt();
          window.location?.reload(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);

        if (err) {
          setLoadAcc(false);
        }
      });
  };

  const addNewWallet = () => {
    const data = {
      bvn: bvn,
      country: country,
      currency: currency,
    };
    if (data.country && data.currency) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/wallets/employee`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            setBvn("");
            setCurrency("");
            setCountry("");
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setOpenAddWallet(false);
              getWalletInformations();
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    }
  };

  const selectExistingCard = (cardID, e) => {
    e.preventDefault();
    setFundModal(true);
    setCardId(cardID);
    setLoadFee(false);
    setDirectDebitModal(false);
    setAddWalletBox(false);
  };

  const showDeleteConfirm = (id, e) => {
    e.preventDefault();
    confirm({
      title: `Are you sure delete this Card ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cardDelete(id);
      },
      onCancel() {},
    });
  };
  const bankTransferFunc = (e) => {
    e.preventDefault();
    if (walletInformation?.length > 0) {
      setBankTrasnfer(true);
    } else {
      Notification(
        "error",
        "Error",
        "Bank account details not available, Kindly complete KYC"
      );
    }
  };

  const openAddWallet = () => {
    setOpenAddWallet(true);
  };

  const cancelAddWallet = () => {
    setOpenAddWallet(false);
    setBvn("");
    setCurrency("");
    setCountry("");
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <Layout>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <b>ACCOUNT BALANCE</b>{" "}
                  {walletInformation?.length > 0 && (
                    <Select
                      defaultValue={walletInformation[0]?.currency}
                      style={{ width: 80 }}
                      onChange={handleChange}
                      value={walletInformation[0]?.currency}
                    >
                      {walletCurrenciesList?.map((walletCurrency) => {
                        return (
                          <Option
                            key={walletCurrency?.id}
                            value={walletCurrency?.currency}
                          >
                            {walletCurrency?.currency}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
                {/* <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={openAddWallet}
                >
                  + Add Account
                </button> */}
              </div>
            </div>
            <div class="card-body">
              <div className="text-center py-3">
                {loadBal && <Spin />}

                {!loadBal && walletInformation.length > 0 && (
                  <h2>
                    {walletInformation.length > 0
                      ? walletInformation[0].currency
                      : ""}{" "}
                    {walletInformation[0]?.balance !== null
                      ? walletInformation[0]?.balance
                          ?.toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : "Balance Not Available"}
                  </h2>
                )}

                {!loadBal && walletInformation.length === 0 && (
                  <div>Balance not Available </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div class="card ">
            <div class="card-body mb-0 pb-0">
              <div className="container">
                <div class="row">
                  <div
                    class="col-sm-12 col-md-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (walletInformation?.length > 0) {
                        setBankTrasnfer(true)
                      } else {
                        Notification(
                          "error",
                          "Error",
                          "Add money is unavailable until your identity has been verified."
                        );
                      }
                    }}
                  >
                    <div class="card folder">
                      <div
                        class="card-body"
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="folder-icon">
                          <img
                            src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/airtime.png"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="folder-info">
                          <a href="#" className="h5 pt-3">
                            Add Money
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4"
                    onClick={() => {
                      if (walletInformation?.length > 0) {
                        setOpenSendMoney(true);
                      } else {
                        Notification(
                          "error",
                          "Error",
                          "Send money is unavailable until your identity has been verified."
                        );
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <div class="card folder">
                      <div
                        class="card-body"
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="folder-icon">
                          <img
                            src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/databundle.png"
                            alt=""
                            width="40"
                          />
                        </div>

                        <div class="folder-info">
                          <div className="h5 pt-3">Send Money</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (walletInformation?.length > 0) {
                        history.push("/payments");
                      } else {
                        Notification(
                          "error",
                          "Error",
                          "Bill Payment is unavailable until your identity has been verified."
                        );
                      }
                    }}
                  >
                    <div class="card folder">
                      <div
                        class="card-body"
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "20px",
                        }}
                      >
                        <div class="folder-icon">
                          <img
                            src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png"
                            alt=""
                            width="40"
                          />
                        </div>

                        <div class="folder-info">
                          <a href="#" className="h5 pt-3">
                            Bill Payment
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div class="card ">
            <div class="card-header font-weight-bold">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {" "}
                  <div>TRANSACTIONS </div>
                </div>
                <div className="px-3">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="row">
                      <div className="col-md-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={billMethod}
                          style={{ paddingRight: "2rem" }}
                          onChange={(e) => setBillMethod(e.target.value)}
                        >
                          <option selected>Select Channel</option>
                          <option value="WALLET">WALLET</option>
                          <option value="BANK_TRANSFER">BANK TRANSFER</option>
                          <option value="CARD_PAYMENT">CARD PAYMENT</option>
                          <option value="DIRECT_DEBIT">DIRECT DEBIT</option>
                          <option value="PAYDAY_LOAN">PAYDAY LOAN</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={transactionType}
                          style={{ marginRight: "2rem" }}
                          onChange={(e) => setTranstype(e.target.value)}
                        >
                          <option selected>Select Transaction Type</option>
                          <option>CREDIT</option>
                          <option>DEBIT</option>
                        </select>
                      </div>
                      <div className="col-md-4">
                        <RangePicker
                          style={{ height: "3rem", borderRadius: "10px" }}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body mb-0 pb-0">
              {loadTrans && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
              {!loadTrans && (
                <div>
                  {transactionList.length > 0 && (
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"> Name</th>
                            <th scope="col">Account Number</th>
                            <th scope="col"> Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Fee</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                            <th scope="col"> </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionList.length > 0 && (
                            <>
                              {transactionList.map((data) => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        whiteSpace:
                                          data?.destAcctName?.length > 30
                                            ? "wrap"
                                            : "no-wrap",
                                      }}
                                    >
                                      {data.type === "CREDIT"
                                        ? data?.srcAcctName?.replace(
                                            "INT'LMISHTRANZA /",
                                            ""
                                          )
                                        : data.destAcctName?.replace(
                                            "INT'LMISHTRANZA /",
                                            ""
                                          )}
                                    </td>
                                    <td>
                                      {data.type === "CREDIT"
                                        ? data?.srcAcctNumber
                                        : data.destAcctNumber}
                                    </td>
                                    <td>{data.type}</td>
                                    <td>
                                      {data.destCurrency}{" "}
                                      {data.amount
                                        ? Intl.NumberFormat("en-US").format(
                                            data.amount
                                          )
                                        : ""}
                                    </td>

                                    <td>
                                      {data.destCurrency}{" "}
                                      {data.processorFee
                                        ? Intl.NumberFormat("en-US").format(
                                            data.processorFee
                                          )
                                        : 0}
                                    </td>
                                    <td>
                                      <span
                                        className={
                                          {
                                            SUCCESS: "badge bg-success",
                                            FAILED: "badge bg-danger",
                                            NEW: "badge bg-info",
                                            PENDING: "badge bg-warning",
                                            PROCESS: "badge bg-warning",
                                            TIMEOUT: "badge bg-danger",
                                          }[data.status]
                                        }
                                      >
                                        {data.status}
                                      </span>
                                    </td>
                                    <td>
                                      {data.createdAt
                                        ? moment(data.createdAt).format(
                                            "DD-MMM-YYYY h:mm A"
                                          )
                                        : ""}
                                    </td>

                                    <td>
                                    <i className="fa fa-download" style={{ color: '#1FC157', cursor: 'pointer' }} onClick={() => {
                                        console.log(data)
                                        setRecieiptInfo(data)
                                        downloadPDF()
                                      }}>

                                      </i>
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                      <div className="d-flex  justify-content-center align-items-center pt-5 pb-5">
                        <Pagination
                          current={page}
                          total={totalItems}
                          defaultPageSize={50}
                          onChange={pagination}
                          itemRender={itemRender}
                        />{" "}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {transactionList?.length === 0 && !loadTrans && (
                <p className="pt-5 text-center pb-5">No Transactions</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Send Money"}
        visible={openSendMoney}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeFund}
      ></Modal>
      <Modal
        title={"Fund Account"}
        visible={fundModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeFund}
      >
        <div className="container">
          <form>
            {load && (
              <p className="text-center">
                <Spin />
              </p>
            )}

            {error && (
              <div className="pb-3">
                <Alert message={errorMsg} type="error" showIcon />
              </div>
            )}

            {success && (
              <div className="pb-3">
                <Alert message={successMsg} type="success" showIcon />
              </div>
            )}

            {alertError && (
              <div className="pb-3">
                <Alert
                  message="Amount to be funded is required!"
                  type="error"
                  showIcon
                />
              </div>
            )}
            {serverError && (
              <p className="text-center text-danger font-weight-bold">
                Failed to connect to SalarioPay
              </p>
            )}

            <div>
              <div>
                {walletInformation?.length > 0 && (
                  <div>
                    <label>Currency</label>
                    <Select
                      defaultValue={walletInformation[0]?.currency}
                      style={{ width: "100%" }}
                      size="large"
                      placeholder={"Currency"}
                      onChange={handleChange}
                      value={walletInformation[0]?.currency}
                    >
                      {walletCurrenciesList?.map((walletCurrency) => {
                        return (
                          <Option
                            key={walletCurrency?.id}
                            value={walletCurrency?.currency}
                          >
                            {walletCurrency?.currency}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                )}
              </div>
              <br />

              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    onBlur={
                      depositType === "DIRECT DEBIT"
                        ? getFeeChargeDirectDebit
                        : getFeeCharge
                    }
                  />
                  <label for="floatingInput">Amount</label>
                </div>
              </div>
              {loadFee && (
                <div className="mb-3">
                  <p>
                    Processing Fee: {walletInformation[0].currency} {feeCharge}
                  </p>
                </div>
              )}

              {depositType === "DEBIT CARD" && (
                <div className="d-grid">
                  <button
                    type="submit"
                    disabled={loadingFee}
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={cardId ? fundExistingCard : addCardInfo}
                  >
                    Submit
                  </button>
                </div>
              )}
              {depositType === "DIRECT DEBIT" && (
                <div className="d-grid">
                  <button
                    type="submit"
                    disabled={loadingFee}
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={
                      cardId ? fundExistingDirectDebit : addNewDirectDebitCard
                    }
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        title={"Debit Card"}
        visible={addWalletBox}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAddWallet}
      >
        <div className="container">
          <>
            <div className="container">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Add Card" key="1" style={{ color: "green" }}>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="pt-5 pb-5">
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={openFundWallet}
                      >
                        + Add New Card
                      </button>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Select Existing Card" key="2">
                  <div className="container">
                    {existingCards.length > 0 ? (
                      <div className="pt-4 pb-4">
                        {existingCards.map((cards) => {
                          if (cards.type === "CARD")
                            return (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={selectExistingCard.bind(
                                  this,
                                  cards.id
                                )}
                              >
                                <div class="post">
                                  <div class="post-header">
                                    <img src={wallet} alt="" />
                                    <div class="post-info">
                                      <span class="post-author">
                                        {cards.name}
                                      </span>
                                      <span class="post-date">
                                        {cards.cardFirst6Digits}*********
                                        {cards.cardLast4Digits}
                                      </span>
                                      <br />

                                      <span class="post-date">
                                        {cards.cardIssuer}
                                      </span>
                                      <br />
                                      <span class="post-date">
                                        {cards.currency}
                                      </span>

                                      <b
                                        style={{
                                          color:
                                            cards.level === "PRIMARY"
                                              ? "green"
                                              : "orange",
                                        }}
                                      >
                                        {cards.level}
                                      </b>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            );
                        })}
                      </div>
                    ) : (
                      <p className="py-5 text-center">No Existing Cards</p>
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </>
        </div>
      </Modal>
      <Modal
        title={"Direct Debit"}
        visible={directDebitModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeDirectDebit}
      >
        <div className="container">
          <>
            <div className="container">
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane
                  tab="Link Bank Account"
                  key="1"
                  style={{ color: "green" }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="pt-5 pb-5">
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={() => {
                          setFundModal(true);
                        }}
                        // onClick={() =>
                        //   handleMono({
                        //     onClose: () => null,
                        //     onSuccess: (response) => {
                        //       setDirectDebitCode(response.code);
                        //       setFundModal(true);
                        //       setLoadFee(false);
                        //       setDirectDebitModal(false);
                        //     },
                        //   })
                        // }
                      >
                        + Add Direct Debit
                      </button>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Existing Bank Accounts" key="2">
                  <div className="container">
                    {existingDebits.length > 0 ? (
                      <div className="pt-4 pb-4">
                        {existingDebits.map((acc) => {
                          if (acc.type === "DIRECT_DEBIT")
                            return (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={selectExistingCard.bind(this, acc.id)}
                              >
                                <div class="post">
                                  <div class="post-header">
                                    <img src={wallet} alt="" />
                                    <div class="post-info">
                                      <span class="post-author">
                                        {acc.accountName}
                                      </span>

                                      <br />

                                      <span class="post-date">
                                        {acc.bankName + "-" + acc.accountNumber}
                                      </span>
                                      <br />

                                      <span class="post-date">
                                        {acc.currency}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            );
                        })}
                      </div>
                    ) : (
                      <p className="py-5 text-center">No Bank Account</p>
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </>
        </div>
      </Modal>
      <Modal
        title={""}
        visible={openSuccessSend}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={() => {
          settOpenSuccessSend(false)
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <br />
            <div className="text-center">
              <img src={successicon} width="40" />
            </div>
            <br />
            <div>{openSuccessmsg}</div>
            <br />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <button
            type="submit"
            onClick={() => settOpenSuccessSend(false)}
            className="btn btn-info m-b-xs"
            style={{ background: "#1FC157", color: "white" }}
          >
            Back
          </button>
          <button
            type="submit"
            onClick={downloadPDF}
            className="btn btn-info m-b-xs"
            style={{ background: "#1FC157", color: "white" }}
          >
            Download Reciept
          </button>
        </div>
      </Modal>
      {showReceipt &&
        <div ref={contentRef} style={{
          position: "relative",
          top: "-9999px", // Completely off-screen

        }}>
          <div className="mt-3 position-relative" id="receipt">
            <div className="pos-class-main">
              <div style={{ fontFamily: "'Plus Jakarta Sans', sans-serif'" }}>
                <div className="w-100" >
                  <div style={{ textAlign: "center", color: receieptInfo?.type === 'CREDIT' ? '#1FC157' : '#D10000', fontSize: '26px', fontWeight: '800' }}>&#8358;{receieptInfo?.amount?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}</div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      fontWeight: "bold",
                      color: '#1E2028'
                    }}
                  >
                    FUND TRANSFER
                  </div>
                  <div style={{ textAlign: "center" }}> {receieptInfo?.createdAt
                    ? moment(receieptInfo.createdAt).format(
                      "DD-MMM-YYYY h:mm A"
                    )
                    : ""}</div>
                </div>
                <div className="pt-4">
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Reciever Name</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.destAcctName}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Reciever Account</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.destAcctNumber}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Sender Name</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.srcAcctName}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Transaction Reference </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.merchantReference}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Narration </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      {receieptInfo?.narration}
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Fee </div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                    >
                      &#8358;{receieptInfo?.processorFee}
                    </div>
                  </div>
                  {receieptInfo?.purpose === "BILL_PAYMENT" &&
                    <div>
                      {receieptInfo?.billPayment.category === "ELECTRICITY" &&
                        <div>
                          <div
                            style={{
                              border: "1px solid #F5F6FA",
                              background: "#F5F6FA",
                              borderRadius: "7px",
                            }}
                            className="mb-2 p-2"
                          >
                            <div style={{ fontSize: '12px', color: '#444444' }}>Token </div>
                            <div
                              className="font-weight-bold b"
                              style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                            >
                              {receieptInfo?.billPayment?.token}
                            </div>
                          </div>
                          <div
                            style={{
                              border: "1px solid #F5F6FA",
                              background: "#F5F6FA",
                              borderRadius: "7px",
                            }}
                            className="mb-2 p-2"
                          >
                            <div style={{ fontSize: '12px', color: '#444444' }}>Address </div>
                            <div
                              className="font-weight-bold b"
                              style={{ fontWeight: "bold", color: "#000000", fontSize: '14px' }}
                            >
                              {receieptInfo?.billPayment?.address}
                            </div>
                          </div>
                        </div>}
                    </div>}

                  <div
                    style={{
                      border: "1px solid #F5F6FA",
                      background: "#F5F6FA",
                      borderRadius: "7px",
                    }}
                    className="mb-2 p-2"
                  >
                    <div style={{ fontSize: '12px', color: '#444444' }}>Status</div>
                    <div
                      className="font-weight-bold b"
                      style={{ fontWeight: "bold", color: "#1FC157", fontSize: '14px' }}
                    >
                      SUCCESS
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <img src={imgBackground} className="" />
            </div>
          </div>
        </div>}
      <Modal
        title={"Account Details"}
        visible={bankTransfer}
        okText="Submit"
        width={700}
        footer={false}
        maskClosable={false}
        onCancel={closeBankTrasnfer}
      >
        <div className="container">
          <div className="">
            <Alert
              description={
                " A Stamp Duty of ₦5.00 - ₦100.00 will be charged for transactions received through bank transfer"

              }
              type="success"
              showIcon
            />
            <br />
          </div>
          {bankInformation?.length > 0 && (
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Currency</p>
                {/* <p>{walletInformation.currency}</p> */}
                <Select
                  defaultValue={bankInformation[0]?.currency}
                  style={{ width: 80 }}
                  value={bankInformation[0]?.currency}
                  onChange={handleChange}
                >
                  {walletCurrenciesList?.map((walletCurrency) => {
                    return (
                      <Option
                        key={walletCurrency?.id}
                        value={walletCurrency?.currency}
                      >
                        {walletCurrency?.currency}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <br />
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>Account Name</p>
                  <p>{bankInformation[0].accountName}</p>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Account Number</p>
                  <p>{bankInformation[0].accountNumber}</p>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <p>Bank Name</p>
                  <p>{bankInformation[0].bankName}</p>
                </div>
                <br />
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        title={"Send Money via Mobile Money"}
        visible={openSendMoneyMm}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={600}
        onCancel={closeSendMoneyMm}
      >
        <div className="container">
          <form onSubmit={handleSubmit(addNewWallet)}>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={accountSend}
                  onChange={(e) => {
                    setAccountSend(e.target.value);
                  }}
                >
                  <option selected>Select Account</option>
                  {walletInformation.map((wallet) => {
                    return (
                      <option value={wallet.id}>
                        {wallet.currency} {wallet?.balance}
                      </option>
                    );
                  })}
                  <label for="floatingInput">Account</label>
                  {beneficiariesList?.length > 0 && (
                    <div className="d-flex justify-content-end mt-2">
                      <div
                        className="beneficiary-btn-send-money"
                        style={{
                          cursor: "pointer",
                          fontWeight: "lighter",
                        }}
                        onClick={changeBeneficiaryList}
                      >
                        <b>Select Beneficiary </b>
                      </div>
                    </div>
                  )}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={countrySend}
                      onChange={getCurrenciesByCountry}
                    >
                      <option selected>Select Beneficiary Country</option>
                      {countryLists?.map((con) => {
                        return <option value={con.code}>{con.name}</option>;
                      })}
                      <label for="floatingInput">Country</label>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={currencySend}
                      onChange={onCurrencyChange}
                    >
                      <option selected>Select Beneficiary Curreny</option>
                      {currencySendLists.map((currency) => {
                        return (
                          <option value={currency.currencyCode}>
                            {currency.currencyName}
                          </option>
                        );
                      })}
                      <label for="floatingInput">Currency</label>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={bankSend}
                      onChange={(e) => setBankSend(e.target.value)}
                    >
                      <option selected>Select Network Provider</option>
                      {mobileMonies.map((mobile) => {
                        return (
                          <option value={mobile.code}>{mobile.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      name="acc"
                      value={acc}
                      maxLength={10}
                      className="form-control"
                      {...register("acc", {
                        required: "mobile number is required!",
                        onBlur: fetchAccountMoney,

                        value: acc,
                      })}
                    />
                    <label for="floatingInput">Mobile Number</label>
                    {errors.acc && (
                      <span className="validate-error">
                        {errors.acc.message}
                      </span>
                    )}
                  </div>
                  <div
                    className=""
                    style={{ textTransform: "uppercase", color: "#1fc157" }}
                  >
                    {accountBnkName}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      value={amountSend}
                      className="form-control"
                      onChange={(e) => setAmountSend(e.target.value)}
                    />
                    <label for="floatingInput">Amount</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      value={narration}
                      className="form-control"
                      onChange={(e) => setNarration(e.target.value)}
                    />
                    <label for="floatingInput">Narration</label>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="submit"
                className="btn btn-info m-b-xs"
                style={{ background: "#1FC157", color: "white" }}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        title={"Send Money via Bank Transfer"}
        visible={openSendMoneyBt}
        okText="Submit"
        footer={false}
        maskClosable={false}
        width={700}
        onCancel={closeSendMoneyBt}
      >
        <div className="text-center">
          {loadAcc && (
            <div className="text-center">
              <Spin />
            </div>
          )}

          {loadCountryProps && (
            <div className="text-center">
              <Spin />
            </div>
          )}
        </div>
        <br />
        <div className="container">
          <form onSubmit={handleSubmit(addNewWallet)}>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setAccountSend(e.target.value);
                  }}
                >
                  <option selected>Select Account</option>
                  {walletInformation.map((wallet) => {
                    return (
                      <option value={wallet.id}>
                        {wallet.currency} {wallet?.balance}
                      </option>
                    );
                  })}
                </select>
                {walletInformation?.length === 0 &&
                  <Alert
                    description={
                      "Balance Not Avaialable"
                    }
                    type="error"
                  />}
                <label for="floatingInput">Account</label>
                {beneficiariesList?.length > 0 && (
                  <div className="d-flex justify-content-end mt-2">
                    <div
                      className="beneficiary-btn-send-money"
                      style={{
                        cursor: "pointer",
                        fontWeight: "lighter",
                      }}
                      onClick={changeBeneficiaryList}
                    >
                      <b>Select Beneficiary </b>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={getCurrenciesByCountry}
                      value={countrySend}
                    >
                      <option selected>Select Country</option>
                      {countryLists?.map((con) => {
                        return <option value={con.code}>{con.name}</option>;
                      })}
                    </select>
                    <label for="floatingInput">Country</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={currencySend}
                      onChange={(e) => setCurrencySend(e.target.value)}
                    >
                      <option selected>Select Currency</option>
                      {currencySendLists.map((currency) => {
                        return (
                          <option value={currency.currencyCode}>
                            {currency.currencyName}
                          </option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Currency</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="mb-3 w-100">
                  <div className="form-floating">
                    <Select
                      value={bankSend}
                      style={{
                        borderRadius: "0px !important",
                        padding: "25px 11px !important",
                      }}
                      onChange={(val) => {
                        setBankSend(val);
                      }}
                      size="large"
                      className="w-100 antzzz-ant-select"
                      placeholder="Bank"
                      width="100%"
                      options={bankListssEARCH}
                      showSearch
                      optionFilterProp="children"
                      onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      name="acc"
                      value={acc}
                      maxLength={10}
                      className="form-control"
                      {...register("acc", {
                        required: "account number is required!",
                        onChange: (e) => {
                          if (e.target?.value?.length === 10) {
                            fetchAccount();
                          }
                        },
                        maxLength: {
                          value: 10,
                          message: "account number must be 10 digits",
                        },
                        minLength: {
                          value: 10,
                          message: "account number must be 10 digits",
                        },
                        onChange: handleBvnChange,
                        value: acc,
                      })}
                    />
                    <label for="floatingInput">Account Number</label>
                    {errors.acc && (
                      <span className="validate-error">
                        {errors.acc.message}
                      </span>
                    )}
                  </div>
                  <div style={{ textTransform: "uppercase", color: "#1FC157" }}>
                    {accountBnkName}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-3">
                <div class="">
                  <div className="form-floating">
                    <Controller
                      name="annualGrossery"
                      control={control}
                      style={{ height: "50px" }}
                      {...register("amount", {
                        required: "Amount is required!",
                        onChange: (e) => setAmountSend(e.target.value),
                        onBlur: fetchAccFee,
                        value: amountSend,
                      })}
                      render={({ field }) => (
                        <NumberFormatter
                          {...field}
                          label=""
                          name="amount"
                          error={errors.amount?.message}
                          prefix={"₦"}
                        />
                      )}
                    />
                    <label for="floatingInput">Amount</label>
                  </div>

                  {errors.amount && (
                    <span className="validate-error">
                      {errors.amouunt.message}
                    </span>
                  )}
                </div>
                {sendFee && (
                  <div style={{ textTransform: "uppercase", color: "#1FC157" }}>
                    Fee: {sendFee?.currency} {sendFee?.merchantFee}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      value={narration}
                      className="form-control"
                      onChange={(e) => setNarration(e.target.value)}
                    />
                    <label for="floatingInput">Narration</label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  {" "}
                  <label for="floatingInput">Save as Beneficiary</label>
                </div>
                <Switch
                  checked={onCheck}
                  onChange={onChangeBeneficiary}
                  size={"small"}
                />
              </div>
            </div>

            <br />
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="submit"
                onClick={postPin.bind(this, "Bank-Transfer")}
                className="btn btn-info m-b-xs"
                style={{ background: "#1FC157", color: "white" }}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        title={"Add Money"}
        visible={openAddMoney}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAddmoney}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div className="border-payo1" onClick={bankTransferFunc}>
              <div className="d-flex align-items-center align-items-center">
                <div>
                  <img
                    src={
                      "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payment_method.png"
                    }
                    alt=""
                    style={{ paddingRight: "1rem" }}
                    width="34"
                    className="home"
                  />{" "}
                </div>
                <div style={{ color: "#000000" }}>Bank Transfer</div>
              </div>
            </div>
            <div
              className="border-payo1"
              onClick={addCard.bind(this, "DEBIT CARD")}
            >
              <div className="d-flex align-items-center align-items-center">
                <div>
                  <img
                    src={
                      "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png"
                    }
                    alt=""
                    style={{ paddingRight: "1rem" }}
                    width="34"
                    className="home"
                  />{" "}
                </div>
                <div style={{ color: "#000000" }}>Debit Card</div>
              </div>
            </div>
            <div
              className="border-payo1"
              onClick={directDebitMethod.bind(this, "DIRECT DEBIT")}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src={
                      "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/databundle.png"
                    }
                    alt=""
                    style={{ paddingRight: "1rem" }}
                    width="34"
                    className="home"
                  />{" "}
                </div>
                <div style={{ color: "#000000" }}>Direct Debit</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={"Send Money"}
        visible={openSendMoney}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeSendMoney}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <div
              className="border-payo1"
              onClick={() => {
                setOpenSendMoneyBt(true);
                setOpenSendMoney(false);
              }}
            >
              <div className="d-flex align-items-center align-items-center">
                <div>
                  <img
                    src={
                      "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payment_method.png"
                    }
                    alt=""
                    style={{ paddingRight: "1rem" }}
                    width="34"
                    className="home"
                  />{" "}
                </div>
                <div style={{ color: "#000000" }}>Bank Transfer</div>
              </div>
            </div>

            <div
              className="border-payo1"
              onClick={() => {
                Notification("success", "Success", "Coming soon");
              }}
            >
              <div className="d-flex align-items-center align-items-center">
                <div>
                  <img
                    src={
                      "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payment_method.png"
                    }
                    alt=""
                    style={{ paddingRight: "1rem" }}
                    width="34"
                    className="home"
                  />{" "}
                </div>
                <div style={{ color: "#000000" }}>Mobile Money</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Payment Confirmation Status"
        visible={verifyStatus}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={cancelStatus}
      >
        <div className="container">
          <BillingPayment
            success={successVerify}
            error={errorVerify}
            successMsg={successMsgVerify}
            errorMsg={errorMsgVerify}
            serverError={serverErrorVerify}
          />
        </div>
      </Modal>

      <Modal
        title="Add Account"
        visible={addWalletModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={cancelAddWallet}
      >
        {load && (
          <p className="text-center">
            <Spin />
          </p>
        )}

        {error && (
          <div className="pb-3">
            <Alert message={errorMsg} type="error" showIcon />
          </div>
        )}

        {success && (
          <div className="pb-3">
            <Alert message={successMsg} type="success" showIcon />
          </div>
        )}

        {alertError && (
          <div className="pb-3">
            <Alert
              message="Amount to be funded is required!"
              type="error"
              showIcon
            />
          </div>
        )}
        {serverError && (
          <p className="text-center text-danger font-weight-bold">
            Failed to connect to SalarioPay
          </p>
        )}

        <br />
        <form onSubmit={handleSubmit(addNewWallet)}>
          <div className="mb-3">
            <div className="form-floating">
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={getCurrenciesByCountry}
              >
                <option>Select </option>
                {countryLists?.map((country) => {
                  return <option value={country.code}>{country.name}</option>;
                })}
              </select>
              <label for="floatingInput">Country</label>
            </div>
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <select
                class="form-select"
                aria-label="Default select example"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option selected>Select </option>
                {currencyDatas.map((currency) => {
                  return (
                    <option value={currency.currencyCode}>
                      {currency.currencyName}
                    </option>
                  );
                })}
              </select>
              <label for="floatingInput">Currency</label>
            </div>
          </div>
          {country === "NG" && (
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="number"
                  name="bvn"
                  value={bvn}
                  maxLength={12}
                  className="form-control"
                  {...register("bvn", {
                    required: "BVN is required!",
                    maxLength: {
                      value: 11,
                      message: "BVN must be 11 digits",
                    },
                    minLength: {
                      value: 11,
                      message: "BVN must be 11 digits",
                    },
                    onChange: handleBvnChange,
                    value: bvn,
                  })}
                />
                <label for="floatingInput">Bvn</label>
                {errors.bvn && (
                  <span className="validate-error">{errors.bvn.message}</span>
                )}
              </div>
            </div>
          )}

          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              onClick={addNewWallet}
              type="submit"
              className="btn btn-info m-b-xs"
              style={{ background: "#1FC157", color: "white" }}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title="Beneficiaries"
        visible={showBeneficiary}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setShowBeneficiary(false);
        }}
      >
        <div
          className="container"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          {beneficiariesList?.map((d) => {
            return (
              <div>
                <div>
                  <div className="d-flex justify-content-between align-items-top">
                    <div>
                      <div className="font-weight-bold">
                        <b>{d?.customerName}</b>
                      </div>
                      <div>{d?.identifier}</div>
                      <div>{d?.institutionName}</div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={onPostPin.bind(this, d)}
                      >
                        Choose
                      </button>
                    </div>
                  </div>
                  <div className="mt-0 pt-0">
                    {" "}
                    <hr />
                  </div>
                </div>
              </div>
            );
          })}
        </div>{" "}
      </Modal>

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {success && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "#1FC157" }}
            >
              {successMsg}
            </p>
          )}
          {error && (
            <p className="text-center text-danger font-weight-bold">
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          <div className="text-center" style={{ padding: "50px 0px" }}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <OtpInput
                  value={pin}
                  onChange={handleChangePin}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    padding: "15px",
                    border: ".9px solid green",
                    width: "50px",
                    margin: "10px",
                  }}
                  numInputs={4}
                  separator={<span></span>}
                />
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    class="btn btn-primary mt-4 w-100"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={
                      paymentActionMode === "Bank-Transfer"
                        ? continueSendBankTransfer
                        : continueMobileMoney
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
