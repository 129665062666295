import React, { useState, useEffect } from "react";
import config from "../../config";
import moment from "moment";
import axios from "axios";
import { Spin, Select, Modal } from "antd";
import Chart from "./chart";
import { ThreeDots } from "react-loader-spinner";
import EmployeeChart from "./employeechart";
const { Option } = Select;

export default function Dashboard() {
  const [payrollDatas, setPayrolls] = useState([]);
  const [loadPayrollDatas, setPayrollLoadDatas] = useState(true);
  const [noPayrollDatas, setNoPayrollDatas] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currencyType2, setCurrencyType2] = useState("");
  const [employerAccBalance, setEmployerAccBalance] = useState(0);
  const [employerstats, setEmployerStats] = useState([]);
  const [payrollAmtModal, setPayrollAmtModal] = useState(false);
  const [payrollAmt, setPayrollAmt] = useState([]);
  const [loadBal, setLoadBal] = useState(false)
  const [type, setType] = useState(false);
  const [employerWallets, setEmployerWallets] = useState([])

  const getStats = () => {
    setLoadBal(true)
    axios
      .get(
        `${config.baseUrl}/v1/dashboard/employer/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadBal(false)
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setEmployerStats(res.data.result);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        setLoadBal(false)
        if (err) {
        }
      });
  };

  const getWalletInformations = () => {
    axios
      .get(
        `${config.baseUrl}/v1/wallets/employer/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const walletInfo = res.data.result;
          setEmployerAccBalance(walletInfo[0]?.balance)
          setEmployerWallets(walletInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getWalletInformations();
  }, []);

  const getCurrencies = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/wallets/employer/currencies/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setCurrencies(res.data.result);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPayrolls = () => {
    axios
      .get(
        `${config.baseUrl
        }/v1/payrolls/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoPayrollDatas(false);
            setPayrollLoadDatas(false);
            setPayrolls(res.data.result);
          } else {
            setPayrollLoadDatas(false);
            setNoPayrollDatas(true);
          }
        } else {
          setPayrollLoadDatas(false);
          setNoPayrollDatas(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function handleChange(currency) {
    const currencyStatsInfo = currencies.find(
      (wallet) => wallet.currency === currency
    );

    setEmployerStats([
      {
        benefitCount: currencyStatsInfo.benefitCount,
        grossSalary: currencyStatsInfo.grossSalary,
        currency: currencyStatsInfo.currency,
        totalEmployees: currencyStatsInfo.totalEmployees,
        netSalary: currencyStatsInfo.netSalary,
        todayPayments: currencyStatsInfo.todayPayments,
        totalBenefit: currencyStatsInfo.totalBenefit,
        totalCustomers: currencyStatsInfo.totalCustomers,
        totalDeductions: currencyStatsInfo.totalDeductions,
        totalEmployees: currencyStatsInfo.totalEmployees,
        totalPayroll: currencyStatsInfo.totalPayroll,
        totalTransaction: currencyStatsInfo.totalTransaction,
        totalOutflowTransaction: currencyStatsInfo.totalOutflowTransaction,
      },
    ]);
  }

  const closePayrollAmtModal = () => {
    setPayrollAmtModal(false);
    setType(false);
  };

  const onChangeCurrency = (currency) => {
    setCurrencyType2(currency);
  };

  useEffect(() => {
    getPayrolls();
    getCurrencies();
    getStats();
  }, []);
  return (
    <div>
      <div class="card">
        <div class="card-body pt-3">
          <div class="d-flex justify-content-between align-items-center ">
            <div>
              <b>SUMMARY</b>
            </div>
            {currencies.length > 0 && (
              <Select
                defaultValue={currencies[0].currency}
                style={{ width: 100 }}
                onChange={handleChange}
                value={currencies[0].currency}
              >
                {currencies.map((currency) => {
                  return (
                    <Option key={currency.id} value={currency.currency}>
                      {currency.currency}
                    </Option>
                  );
                })}
              </Select>
            )}
          </div>
          <br />
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="card stat-widget">
                <div
                  class="card-body"
                  style={{
                    background: "#1fc157",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <h5 class="card-title text-center text-white">
                    Account Balance
                  </h5>
                  <div
                    className="text-center text-white h2 font-weight-bold"
                    style={{ fontSize: "18px" }}
                  >
                    {loadBal ?
                      <div className="d-flex justify-content-center">
                        <ThreeDots
                          visible={loadBal}
                          height="15"
                          width="35"
                          color="#ffffff"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                      :
                      <div>
                        {employerstats.length > 0 ? employerstats[0].currency : ""}{" "}
                        {employerAccBalance > 0
                          ? employerAccBalance
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                          : "Balance Not Avaialble"}
                      </div>}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card stat-widget">
                <div
                  class="card-body"
                  style={{
                    background: "#1fc157",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <h5 class="card-title text-center text-white">Employees</h5>
                  <div
                    className="text-center text-white h2 font-weight-bold"
                    style={{ fontSize: "18px" }}
                  >
                    {employerstats.length > 0
                      ? employerstats[0].totalEmployees
                      : 0}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card stat-widget">
                <div
                  class="card-body"
                  style={{
                    background: "#1fc157",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <h5 class="card-title text-center text-white">Payroll</h5>
                  <div
                    className="text-center text-white h2 font-weight-bold"
                    style={{ fontSize: "18px" }}
                  >
                    {employerstats.length > 0 ? employerstats[0].currency : ""}{" "}
                    {employerstats.length > 0
                      ? employerstats[0].totalPayroll
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : 0}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-xl-3">
              <div class="card stat-widget">
                <div
                  class="card-body"
                  style={{
                    background: "#1fc157",
                    color: "white",
                    textAlign: "center",
                    borderRadius: "20px",
                  }}
                >
                  <h5 class="card-title text-center text-white">Outflow</h5>
                  <div
                    className="text-center text-white h2 font-weight-bold"
                    style={{ fontSize: "18px" }}
                  >
                    {employerstats.length > 0 ? employerstats[0].currency : ""}{" "}
                    {employerstats.length > 0
                      ? employerstats[0].totalOutflowTransaction
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">Payroll Metrics</h5>
                </div>
                <div>
                  {currencies.length > 0 && (
                    <Select
                      defaultValue={currencies[0].currency}
                      style={{ width: 100 }}
                      onChange={onChangeCurrency}
                      value={
                        currencyType2 ? currencyType2 : currencies[0].currency
                      }
                    >
                      {currencies.map((currency) => {
                        return (
                          <Option key={currency.id} value={currency.currency}>
                            {currency.currency}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
              </div>
              <div id="">
                <Chart
                  currency={
                    currencyType2 ? currencyType2 : employerstats[0]?.currency
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Employee Onboarding Metric </h5>

              <div id="">
                <EmployeeChart />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget pb-5">
            <div class="card-body">
              <h5 class="card-title">Payroll History </h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Total Amount </th>
                      <th scope="col">Total Payable</th>
                      <th scope="col">Total Deduction</th>
                      <th scope="col">Total Fee</th>
                      <th scope="col">Employees</th>
                      <th scope="col">Start Period</th>
                      <th scope="col">End Period</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payrollDatas.length > 0 && (
                      <>
                        {payrollDatas.slice(0, 10).map((data) => {
                          return (
                            <tr>
                              <td>
                                {data.currency}{" "}
                                {Intl.NumberFormat("en-US").format(
                                  data.totalAmount
                                )}
                              </td>
                              <td>
                                {data.currency}{" "}
                                {Intl.NumberFormat("en-US").format(
                                  data.totalPayableAmount
                                )}
                              </td>
                              <td>
                                {data.currency}{" "}
                                {Intl.NumberFormat("en-US").format(
                                  data.totalDeduction
                                )}
                              </td>
                              <td>
                                {data.currency}{" "}
                                {Intl.NumberFormat("en-US").format(
                                  data.totalFee
                                )}
                              </td>
                              <td>{data.totalEmployees}</td>
                              <td>
                                {data.startPeriod
                                  ? data.startPeriod?.slice(0, 10)
                                  : ""}
                              </td>
                              <td>
                                {data.endPeriod
                                  ? data.endPeriod?.slice(0, 10)
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      STARTED: "badge bg-warning",
                                      COMPLETED: "badge bg-success",
                                      SUCCESS: "badge bg-success",
                                      FAILED: "badge bg-danger",
                                      IDLE: "badge bg-info",
                                      PENDING: "badge bg-warning",
                                      PROCESSING: "badge bg-warning",
                                      TIMEOUT: "badge bg-danger",
                                    }[data.status]
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                              <td>
                                {data.endPeriod
                                  ? moment(data.createdAt).format(
                                    "DD-MMM-YYYY h:mm A"
                                  )
                                  : ""}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
                {noPayrollDatas && (
                  <p className="text-center pt-5 pb-5">No Payroll History</p>
                )}
                {loadPayrollDatas && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={type === "amount" ? "Payroll Amounts" : "Payroll Fees "}
        visible={payrollAmtModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closePayrollAmtModal}
      >
        {type === "amount" ? (
          <div>
            {payrollAmt.map((payrollAmt) => {
              return (
                <ul>
                  <li>
                    {payrollAmt.currency} {payrollAmt.amount}
                  </li>
                </ul>
              );
            })}
          </div>
        ) : (
          <div>
            {payrollAmt.map((payrollFee) => {
              return (
                <ul>
                  <li>
                    {payrollFee.currency} {payrollFee.fee}
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </Modal>
    </div>
  );
}
